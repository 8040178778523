import React, { useState } from "react";
import moment from 'moment';
import IntlMessages from "util/IntlMessages";
import { Spin, Icon, Menu, message, Avatar, Popconfirm } from "antd";
import { DeleteOutlined, FileOutlined } from '@ant-design/icons';
import { injectIntl, FormattedDisplayName } from 'react-intl';
import { useEntity } from "../../context";
import Item from './Item';
import Delete from './Delete';
import { BASE_IMG } from 'services';

function Index({ triggeredTime, name, id, deleteItem, getItems,
    mapFirstSemanticName, triggerFirstValue,logic,message,
    mapFirstSemanticUnitSymbol, mapSecondSemanticName,
    triggerSeccondValue, mapSecondSemanticUnitSymbol
}) {
    //hooks
    const { entity } = useEntity();
    //state
    const [openDelete, setOpenDelete] = useState(false);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openItem, setOpenItem] = useState(false);
    //const
    const menu = () => {
        return (
            <Menu>
                <Menu.Item>
                    <Popconfirm
                        title="Are you sure?"
                        okText="Yes"
                        cancelText="No"
                    >
                        <div style={{ width: "100%" }}><DeleteOutlined /> <IntlMessages id="label.delete" /></div>
                    </Popconfirm>

                </Menu.Item>
                <Menu.Item onClick={() => setOpenItem({

                })}>
                    <Icon type="edit" />  <IntlMessages id="label.edit" />
                </Menu.Item>
            </Menu>
        )
    }

    //functions
    const usAssign = () => {
        deleteItem([id]);
    }
    const hasImage = (type) => {
        const imagesTypes = ['png', 'jpg', 'jpeg'];
        if (imagesTypes.filter(el => type.toUpperCase().search(el.toUpperCase()) > -1).length) {
            return "image";
        }
        return "";
    }

    return (<Spin spinning={loading}>
        {openItem && <Item visible={openItem} setVisible={setOpenItem} getItems={getItems} />}
        {openDelete && <Delete visible={openDelete} setVisible={setOpenDelete} getItems={getItems} />}

        <div className="property-card"
            style={{
                borderTop: "4px solid #DD0B0B"
            }}>
            <div className="header-card" onClick={() => setOpen(!open)}>
                <div className="information">
                    <div className="values-data">
                        <div className="value">
                            {moment(triggeredTime).isValid() && moment(triggeredTime).format('MM/DD/YYYY, HH:mm')}
                        </div>
                        <div>
                            <i style={{ fontSize: "17px" }} className="icon icon-alert" />
                        </div>
                    </div>
                    <div className="names" style={{ display: "flex" }}>
                        {name}
                    </div>
                    <div>
                        {mapFirstSemanticName}:&nbsp;{triggerFirstValue}{mapFirstSemanticUnitSymbol}
                    </div>
                    {triggerSeccondValue && <div>
                        {mapSecondSemanticName}:&nbsp;{triggerSeccondValue}{mapSecondSemanticUnitSymbol}
                    </div>}
                </div>
            </div>
                {open && <div className="content-card" style={{ paddingBottom: 0 }}>
                  {logic && <div>
                    <IntlMessages id="logic"/>:<br/>
                    {logic}
                    </div>}
                    {message && <div>
                    <IntlMessages id="message"/>:<br/>
                    {message}
                    </div>}

                </div>}
            </div>
    </Spin>
    )


}


export default injectIntl(Index);