import React, { useEffect, useState } from 'react';
import { Form, Input, Modal, Spin, Button, Row, Col, Divider, message, Select } from 'antd4';
import {Avatar} from 'antd';
import { injectIntl } from 'react-intl'
import { PlusOutlined } from '@ant-design/icons';
import { FacilityService, EntityService, BASE_IMG } from 'services';
import IntlMessages from "util/IntlMessages";


const formItemLayout = {
  wrapperCol: { span: 24 },
  layout: 'vertical'
};
const responsiveMode = {
  xs: { span: 24 },
  sm: { span: 24 },
  md: { span: 24 },
  lg: { span: 24 },
  xl: { span: 24 },
  xxl: { span: 24 },
}
const { Option } = Select;

const servicesEntity = new EntityService();
const FacilitySV = new FacilityService();

function Index({ visible, setVisible, getItems, facilityEntityId, intl, facilityId }) {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([])
  const onFinish = values => {
    setLoading(true);
    values.facilityEntityId= facilityEntityId;

    servicesEntity.assginToUser(values)
      .then(res => {
        if (res.header && res.header.responseCode === 0) {
          getItems();
          setVisible(false);
        }
        // if (res.header.metaResponse !== null) {
        //   message[res.header.responseCode === 0 ? "success" : "warning"](intl.formatMessage({ id: res.header.metaResponse }))

        // }
        setLoading(false)
      })
      .catch(err => setLoading(false))

  }
  const getUsers = () => {
    FacilitySV.getByFacilityId(facilityId)
      .then(res => {
        if (res && res.data) {
          setUsers(res.data)
          // setUsers(res.data.map(el => {
          //   return {
          //     label: el.firstName + " " +
          //       el.lastName, value: el.userId
          //   }
          // }))
        }
      })
  }


  useEffect(() => {
    getUsers();
   
    // eslint-disable-next-line
  }, [])
  return (
    <Modal
      visible={visible}
      title={<IntlMessages id="assign.modal.users" />}
      footer={false}
      onCancel={() => setVisible(false)}
    >
      <Spin spinning={loading}>
        <Form className="new-from" name="add" form={form} {...formItemLayout} onFinish={onFinish}>
          <Row gutter={[5, 0]}>
            <Form.Item name="facilityEntityId" style={{ display: "none" }} />
            <Col {...responsiveMode}>
              <Form.Item
                label={intl.formatMessage({ id: "label.selectUsers" })}
                name="userIds"
              >
                <Select
                  showSearch
                  mode="multiple"
                  style={{ width: "100%", marginTop: "15px" }}
                  placeholder="Select Users"
                  optionLabelProp="label"
                  >
                  {
                    users.map(((user, index) => {
                      return (
                        <Option value={user.userId} key={user.userId} label={user.firstName + " " + user.lastName}>
                          <div>
                            <Avatar src={BASE_IMG + user.avatar} style={{ backgroundColor: "#038fde", float: "left" }}
                              className="gx-size-40 gx-pointer gx-mr-3" alt="">
                                 {user.firstName && user.firstName.charAt(0).toUpperCase()}{user.lastName && user.lastName.charAt(0).toUpperCase()}
                                 </Avatar>

                            <div style={{ float: "left", marginLeft: "5px" }}>
                              <div > {user.firstName + " " + user.lastName}</div>
                              <div style={{ fontSize: "11px", color: "#999999" }}>{user.email}</div>
                            </div>
                          </div>
                        </Option>
                      )
                    }))
                  }
                </Select>
              </Form.Item>
            </Col>
            <Form.Item>
              <Button type="primary" block form="add" key="submit" htmlType="submit">
                {intl.formatMessage({ id: "button.save" })}
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Spin>
    </Modal>
  )
}

export default injectIntl(Index)