import React from 'react';
import { Collapse } from 'antd4';
import IntlMessages from 'util/IntlMessages';
import { useEntity } from '../../context';
import { GrRefresh } from 'react-icons/gr';

import Items from './Items';

const { Panel } = Collapse;

function Index(props) {
    //hooks
    const { getInfo } = useEntity();

    return (
        <div className='entity-panel-content-tab'>
            <div className='refresh-action' onClick={() => getInfo()}>
                <GrRefresh />&nbsp;<IntlMessages id="refresh" />
            </div>
            <div style={{ padding: "12px 16px" }}>
                <Items />
            </div>
        </div>

    )
}


export default Index;