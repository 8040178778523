import React, {useState, useEffect} from 'react';
import DateBasedLine from "./DateBasedLine";
import { Row, Col, Checkbox, Icon, Radio, Select, DatePicker, Popover, Button, Spin, Modal } from 'antd';
import { EndNodeService } from 'services';
import { withRouter } from 'react-router-dom'
import moment from 'moment';
import IntlMessages from "util/IntlMessages";

const EndNodeSV = new EndNodeService();

const { RangePicker } = DatePicker;
const { Option } = Select;

const initialDateCustom = {
    startDate: moment().subtract(30, 'day'),
    endDate: moment()
}
const initialTimeRange = "h6";

function index(props) {

    const { visible, setVisible } = props;
    const [loading, setLoading] = React.useState(false);
    const [items, setItems] = React.useState([]);
    const [timeRange, setTimeRange] = React.useState(initialTimeRange);
    const [timeRangeCustom, setTimeRangeCustom] = React.useState('d30')
    const [granularity, setGranularity] = React.useState('all')
    const [dateCustom, setDateCustom] = React.useState(initialDateCustom);
    const [showOptions, setShowOptions] = React.useState(false);
    const [errDate, setErrDate] = React.useState(false);
    const [disbaledGranularity, setDisbaledGranularity] = React.useState(false);
    const [details, setDetails] = React.useState({});
    const [queryType, setQueryType] = useState("lastvalue");
    const [continues, setContinues] = useState(false);
    const handleDateCustom = (id, value) => {
        setDateCustom({
            ...dateCustom,
            [id]: value
        })
    }
    const nameFilesAmtChart = visible.name + "_" + visible.attributeName;
    React.useEffect(() => {
        if (dateCustom.startDate > dateCustom.endDate) {
            setErrDate(<IntlMessages id="error.setDateInvalid" />)
        } else {
            setErrDate(false);
        }
        let duration = moment.duration(dateCustom.endDate.diff(dateCustom.startDate));
        let diff = duration.asHours();
        if (1 >= diff) {
            setTimeRangeCustom('h1')
            setGranularity('all');
        } else if (6 >= diff && diff > 1) {
            setGranularity('all');
            setTimeRangeCustom('h6')
        } else if (24 >= diff && diff > 6) {
            setGranularity('all');
            setTimeRangeCustom('h24')
        } else if (168 >= diff && diff > 24) {
            setGranularity('all');
            setTimeRangeCustom('d7')
        } else if (720 >= diff && diff > 168) {
            setGranularity(renderGranularity[4].list[0].value);
            setDisbaledGranularity(false);
            setTimeRangeCustom('d30')
        } else if (diff > 720) {
            setGranularity(renderGranularity[5].list[0].value)
            setTimeRangeCustom('y1');
            setDisbaledGranularity(false);
        }
    }, [dateCustom]);

    const getData = () => {

        setLoading(true);
        let endNodeId = visible.nodeId;
        let attributeName = visible.attributeName;
        let bodyString = ``
        let methodDuration = "AmazingGet";
        if (timeRange === "d30" || timeRange === "y1") {
            methodDuration = "AmazingSnapshotGet";
        } else {
            methodDuration = "AmazingGet";
        }
        if (timeRange === 'custom') {
            var a = moment(dateCustom.endDate);
            var b = moment(dateCustom.startDate);
            methodDuration = a.diff(b, 'hours') > 168 ? "AmazingSnapshotGet" : "AmazingGet";
            if (granularity === "all") {
                bodyString = `timeRange=custom&startDate=${moment.utc(dateCustom.startDate).format('YYYY-MM-DD HH:mm')}&endDate=${moment.utc(dateCustom.endDate).format('YYYY-MM-DD HH:mm')}&queryType=lastvalue&isContinuous=${continues}`
            } else {
                bodyString = `timeRange=custom&granularityDuration=${granularity}&startDate=${moment.utc(dateCustom.startDate).format('YYYY-MM-DD HH:mm')}&endDate=${moment.utc(dateCustom.endDate).format('YYYY-MM-DD HH:mm')}&queryType=${queryType}&isContinuous=${continues}`

            }
        } else if (granularity === "all") {
            bodyString = `timeRange=${timeRange}&queryType=lastvalue&isContinuous=${continues}`
        } else {

            bodyString = `timeRange=${timeRange}&granularityDuration=${granularity}&queryType=${queryType}&isContinuous=${continues}`
        }
        EndNodeSV.getAttributeValueAdvance(endNodeId, attributeName, bodyString, methodDuration)
            .then(res => {
                if (res && res.data && res.data.list) {
                    setDetails(res.data)
                    setItems(res.data.list.map((el, index) => {
                        return { date: moment(el.endDate).format('MM/DD/YYYY, HH:mm:ss'), value: parseFloat(el.outputValue) }
                    }))
                    setLoading(false);
                }
            })
            .catch(err => console.log(err, "err"))

    }
    const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
    };

    const renderGranularity = [
        {
            value: 'h1',
            list: [
                {
                    value: '5',
                    lable: <IntlMessages id="amChart.option.timeGranularity.5m" />
                }, {
                    value: '15',
                    lable: <IntlMessages id="amChart.option.timeGranularity.15m" />
                },
                {
                    value: 'all',
                    lable: <IntlMessages id="amChart.option.timeGranularity.all" />
                },
            ]
        },
        {
            value: 'h6',
            list: [
                {
                    value: '5',
                    lable: <IntlMessages id="amChart.option.timeGranularity.5m" />
                }, {
                    value: '15',
                    lable: <IntlMessages id="amChart.option.timeGranularity.15m" />
                }, {
                    value: '60',
                    lable: <IntlMessages id="amChart.option.timeGranularity.1h" />
                },
                {
                    value: 'all',
                    lable: <IntlMessages id="amChart.option.timeGranularity.all" />
                },
            ]
        },
        {
            value: 'h24',
            list: [                
                {
                    value: '15',
                    lable: <IntlMessages id="amChart.option.timeGranularity.15m" />
                }, {
                    value: '60',
                    lable: <IntlMessages id="amChart.option.timeGranularity.1h" />
                },
                {
                    value: '360',
                    lable: <IntlMessages id="amChart.option.timeGranularity.6h" />
                },
                {
                    value: 'all',
                    lable: <IntlMessages id="amChart.option.timeGranularity.all" />
                },
            ]
        },
        {
            value: 'd7',
            list: [
                {
                    value: '60',
                    lable: <IntlMessages id="amChart.option.timeGranularity.1h" />
                },
                {
                    value: '360',
                    lable: <IntlMessages id="amChart.option.timeGranularity.6h" />
                },
                {
                    value: '1440',
                    lable: <IntlMessages id="amChart.option.timeGranularity.1d" />
                },
                {
                    value: 'all',
                    lable: <IntlMessages id="amChart.option.timeGranularity.all" />
                },
            ]
        },
        {
            value: 'd30',
            list: [
                {
                    value: '360',
                    lable: <IntlMessages id="amChart.option.timeGranularity.6h" />
                },
                {
                    value: '1440',
                    lable: <IntlMessages id="amChart.option.timeGranularity.1d" />
                },
                {
                    value: 'all',
                    lable: <IntlMessages id="amChart.option.timeGranularity.all" />
                },
            ]
        },
        {
            value: 'y1',
            list: [
                {
                    value: '1440',
                    lable: <IntlMessages id="amChart.option.timeGranularity.1d" />
                },
                {
                    value: 'all',
                    lable: <IntlMessages id="amChart.option.timeGranularity.all" />
                },
            ]
        }
    ]


    React.useEffect(() => {

        if (timeRange !== 'custom') {
            setDisbaledGranularity(false);

            if (timeRange === 'd30' || timeRange === "y1") {
                let idIndex = renderGranularity.findIndex(el => el.value === timeRange);
                setGranularity(renderGranularity[idIndex].list[0].value)

            } else {
                setGranularity('all')
            }

        } else {
            setDateCustom(initialDateCustom);
            setGranularity(renderGranularity[4].list[1].value)
            setDisbaledGranularity(false);
        }
    }, [timeRange])
    useEffect(()=>{
        if(granularity === "all"){
            setQueryType("lastvalue")
        }
    },[granularity])
    const content = (
        <div style={{ display: "flex" }}>
            <div style={{ borderRight: "2px solid #999999", marginRight: "10px", paddingRight: "10px", width: "200px" }} >
                <div ><IntlMessages id="amChart.option.timeRange" /></div>
                <Radio.Group onChange={(e) => setTimeRange(e.target.value)} defaultValue="h6">
                    <Radio style={radioStyle} value={'h1'}>
                        <IntlMessages id="amChart.option.timeRange.oneHour" />
                    </Radio>
                    <Radio style={radioStyle} value={'h6'}>
                        <IntlMessages id="amChart.option.timeRange.sixHour" />
                    </Radio>
                    <Radio style={radioStyle} value={'h24'}>
                        <IntlMessages id="amChart.option.timeRange.oneDay" />
                    </Radio>
                    <Radio style={radioStyle} value={'d7'}>
                        <IntlMessages id="amChart.option.timeRange.sevenDay" />
                    </Radio>
                    <Radio style={radioStyle} value={'d30'}>
                        <IntlMessages id="amChart.option.timeRange.oneMonth" />
                    </Radio>
                    <Radio style={radioStyle} value={'y1'}>
                        <IntlMessages id="amChart.option.timeRange.oneYear" />
                    </Radio>
                    <Radio style={radioStyle} value={'custom'}>
                        <IntlMessages id="amChart.option.timeRange.custom" />
                    </Radio>
                    {timeRange === 'custom' ?
                        <div>
                            <DatePicker
                                style={{ minWidth: "100px", width: "100%" }}
                                size="small"
                                showTime
                                format="MM/DD/YYYY, HH:mm"
                                placeholder="Start"
                                value={dateCustom.startDate}
                                onChange={(e) => handleDateCustom('startDate', e)}
                            />
                            <br />
                            <DatePicker
                                style={{ minWidth: "100px", width: "100%" }}
                                size="small"
                                showTime
                                format="MM/DD/YYYY, HH:mm"
                                placeholder="End"
                                value={dateCustom.endDate}
                                onChange={(e) => handleDateCustom('endDate', e)}
                            />
                            <div style={{ color: "red", fontSize: "12px", lineHeight: "30px" }}>{errDate}</div>

                        </div>
                        : null}
                </Radio.Group>
            </div>
            <div>
                <div><IntlMessages id="amChart.option.timeGranularity" /></div>
                <Select
                    size="sm"
                    style={{ width: 140 }}
                    defaultValue="m15"
                    disabled={disbaledGranularity}
                    onChange={(e) => setGranularity(e)}
                    value={granularity}
                >
                    {renderGranularity.findIndex(el => el.value === (timeRange === "custom" ? timeRangeCustom : timeRange)) >= 0 && renderGranularity.find(el => el.value === (timeRange === "custom" ? timeRangeCustom : timeRange)).list.map((mp, index) => {
                        return (
                            <Option value={mp.value} key={index}>{mp.lable}</Option>
                        )
                    })}
                </Select>
                <br />
                <br />
                <Select
                    size="sm"
                    style={{ width: 140 }}
                    disabled={granularity === "all"}
                    onChange={(e) => setQueryType(e)}
                    value={queryType}
                >                
                    <Option value={"lastvalue"} key={"lastvalue"}><IntlMessages id="label.lastvalue"/></Option>                    
                    <Option value={"min"} key={"min"}><IntlMessages id="label.min"/></Option>                    
                    <Option value={"max"} key={"max"}><IntlMessages id="label.max"/></Option>                    
                    <Option value={"avg"} key={"avg"}><IntlMessages id="label.average"/></Option>                    

                </Select>
                    <br/>
                    <br/>
                    <Checkbox value={continues} onChange={(e)=>setContinues(e.target.checked)}><IntlMessages id="label.continuous"/></Checkbox>
               
                {/* <div>
                    Show time as
                </div> */}
                {/* <Radio.Group>
                    <Radio style={radioStyle} value={0}>
                        UTC/ GMT
                    </Radio>
                    <Radio style={radioStyle} value={1}>
                        Local time([Time Zone])
                    </Radio>
                </Radio.Group> */}
                <br />
                <div style={{ position: "absolute", bottom: "0", width: "38%" }}>
                    <Button type="primary" block onClick={() => {
                        if (errDate) {

                        } else {
                            getData();
                            setShowOptions(false)
                        }
                    }} ><IntlMessages id="button.done" /></Button>
                </div>

            </div>

        </div>
    );

    React.useEffect(() => {
        getData();

    }, []);
    return (
        <Modal
            visible={visible}
            className="amchart-card"
            title={<div>{visible.deviceName}&nbsp;{">"}&nbsp;{visible.nodeName}&nbsp;{">"}&nbsp;<span title={visible.attributeName}>{visible.aliasName || visible.attributeName}</span><span style={{ fontSize: "12px", color: "#8c8c8c", marginLeft: "5px" }}>{visible.semanticUnitSymbol && `(${visible.semanticUnitSymbol})`}</span></div>}
            style={{ maxWidth: "98%", minWidth: "50%" }}
            onCancel={() => setVisible(false)}
            footer={null}>
            <Row>
                <Col span={24}>
                    <div style={{ padding: "6px 15px 6px 0", height: "25px" }} >
                        <Popover content={content} placement="bottom" visible={showOptions} onVisibleChange={(e) => setShowOptions(e)} title={<IntlMessages id="amChart.options.title" />} trigger="click">
                            <Icon type="setting" style={{ color: "#999", float: "right", fontSize: "20px" }} />
                        </Popover>
                    </div>
                    <Spin spinning={loading}>
                        {items.length > 0 ?
                            <DateBasedLine items={items} nameFilesAmtChart={nameFilesAmtChart} />
                            :
                            !loading &&
                            <div className="no-data-amchart">
                                <IntlMessages id="text.amtChart.noData" />                           <div style={{ fontWeight: 500 }}> {details.specificationLastValue}&nbsp;{details.semanticUnitSymbol}
                                    &nbsp; ({moment(details.specificationLastDateTime).format('MM/DD/YYYY, HH:mm:ss')})
                                </div>
                            </div>}
                    </Spin>
                </Col>
            </Row>
        </Modal>
    )
}

export default index;