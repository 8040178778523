/*eslint-disable no-undef*/

import React, { useEffect, useState } from "react";
import IntlMessages from 'util/IntlMessages';
import { Radio, Spin } from 'antd4';
import { useEntity } from "../../context";
import { GrEdit, GrUpdate } from 'react-icons/gr'
import AddSprite from './AddSprite';
import IconSelect from './Icon';
import ColorSelect from './Color';

function Index() {
    const { entity, changeType } = useEntity();
    //state
    const [item, setItem] = useState();
    const [loading, setLoading] = useState(false);
    const [icon, setIcon] = useState("");
    const [openIcon, setOpenIcon] = useState(false);
    const [color, setColor] = useState("#fff")
    const [openColor, setOpenColor] = useState(false);
    const [openEditCoordinate, setOpenEditCoordinate] = useState(false);
    //functions
    const getIcon = () => {
        entity.service.getIcon(entity.data.code)
            .then(res => {
                setIcon(res.data);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
            })
    }
    const getColor = () => {
        setLoading(true);
        entity.service.getColor(entity.data.code)
            .then(res => {
                setColor(res.data)
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
            })
    }

    //effect
    useEffect(() => {
        if (entity.data && entity.data.code) {
            getColor();
            getIcon();
        };
    }, [entity.data]);
    return (
        <div className="location-tab">
            {openIcon && <IconSelect visible={openIcon} setVisible={setOpenIcon} getItems={getIcon} />}
            {openColor && <ColorSelect visible={openColor} setVisible={setOpenColor} getItems={getColor} />}

            <Spin spinning={loading}>
                <div className="sprite-item">
                    <div className="sprite-item-label">
                        <IntlMessages id="spriteIcon" />:
                    </div>
                    <div title={icon && icon.iconName} className="sprite-item-icon">
                       
                            <img src={icon ? icon.iconStorageLink : ""} width="30px" height="30px" alt="" />

                    </div>&nbsp;
                    <div
                        onClick={() => setOpenIcon({ ...icon, code: entity.data.code })}
                        className="edit-able"><GrEdit /></div>
                </div>
                <div className="sprite-item">
                    <div className="sprite-item-label">

                        <IntlMessages id="spriteColor" />:
                    </div>
                    <div className="sprite-item-color"
                        title={color}
                        style={{ background: color }}>

                    </div>
                    &nbsp;<div
                        onClick={() => setOpenColor({ code: entity.data.code, color: color })}
                        className="edit-able"><GrEdit /></div>
                </div>
            </Spin>

        </div>
    )
}

export default Index;