import React, { useEffect, useState } from 'react';
import {Spin} from 'antd';
import {withRouter} from 'react-router-dom';
import { ProfileService, BASE_URL } from "services";
import axios from 'axios';

const service = new ProfileService();

function AutoLogin (props){

    const [loading, setLoading] = useState(true);
    useEffect(()=>{
        const urlParams = new URLSearchParams(props.location.search)
        localStorage.setItem('token',urlParams.get('token'))
        localStorage.setItem('refreshToken',urlParams.get('refreshToken'))
        axios.get(`${BASE_URL}user/Profile/GetProfile`,{
            headers:{
                "Authorization": "Bearer " + urlParams.get('token')
                }
        })
        .then(res => {
            if(res && res.data.data){
                localStorage.setItem("user", JSON.stringify(res.data.data));
                if(sessionStorage.getItem('BackUrl')){
                    window.location.replace(sessionStorage.getItem('BackUrl'));
                }else{
                    window.location.replace('/');
                }
                
            }
        })
        
    },[])

    return (
        <Spin spinnig={loading}>
            <div>Waiting</div> 
        </Spin>
    )
}

export default withRouter(AutoLogin)