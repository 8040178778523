import React, { useState, useEffect } from 'react'
import { Modal, Button, Form, Row, Col, Input, Spin, message, Select, Descriptions } from 'antd';
import IntlMessages from "util/IntlMessages";
import { FacilityService } from 'services';
import moment from 'moment';

const FormItem = Form.Item;
const { Option } = Select;
const services = new FacilityService();

function Add(props) {

    const { visible, setVisible } = props;
    return (
        <Modal
            title={<IntlMessages id="label.bacnetPorperty" />}
            visible={visible}
            onCancel={() => setVisible(false)}
            footer={false}
        >
            <Descriptions bordered size={"small"} layout={"horzintal"}>
                <Descriptions.Item label={<div style={{ fontWeight: "bold" }}><IntlMessages id="label.objectType" /></div>}>{visible.bacnetObjectTypeAliasName}</Descriptions.Item>
                <Descriptions.Item label={<div style={{ fontWeight: "bold" }}><IntlMessages id="label.attributeAddress" /></div>}>{visible.attributeAddress}</Descriptions.Item>
            </Descriptions>


        </Modal>
    )
}

const AddForm = Form.create()(Add);

export default AddForm;