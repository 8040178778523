import { BASE_URL, HEADERS } from './index';
import axios from 'axios';
import {message} from 'antd';

const api = "user/Profile/";


export default class ProfileService {
    get(){
        return axios.get(`${BASE_URL}${api}GetProfile`,HEADERS)
        .then(res => {
            if(res.data.header.responseCode === 0){
                return res.data;
            }
           
        })
    }
    getSetting(){
        return axios.get(`${BASE_URL}${api}GetSetting`,HEADERS)
        .then(res => {
            if(res.data.header.responseCode === 0){
                return res.data;
            }
           
        })                 
    }
    saveProfile(data){
        return axios.post(`${BASE_URL}${api}SaveProfile`, data, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 2));
    }
    changePassword(data){
        return axios.post(`${BASE_URL}${api}ChangePassword`, data, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 2));
    }
    saveSetting(data){
        return axios.post(`${BASE_URL}${api}SaveSetting`, data, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 2));
    }
    removeProfile(){
        return axios.delete(`${BASE_URL}${api}RemoveAvatar`, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 2));
    }
    logout(){
        return axios.post(`${BASE_URL}logout?refreshToken=${localStorage.getItem('refreshToken')}`, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 2));
    }
    logoutOK(){
        return axios.post(`${BASE_URL}logout`, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 2));
    }

}

