/*eslint-disable no-undef*/

import React, { useEffect, useState } from 'react';
import { Form, Input, Modal, Spin, Button, Row, Col, message, Select, Upload } from 'antd4';
import { injectIntl } from 'react-intl'
import { PlusOutlined } from '@ant-design/icons';
import { EntityService, FacilityEntityInsight, DomainService } from 'services';
import IntlMessages from "util/IntlMessages";
import UploadFile from 'components/UploadFiles';
import { useEntity } from '../../context';

const DomainSV = new DomainService();
const service = new EntityService();

const { Option } = Select;

const formItemLayout = {
    wrapperCol: { span: 24 },
    layout: 'vertical'
};
const responsiveMode = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
    xl: { span: 24 },
    xxl: { span: 24 },
}

function Index({ visible, setVisible, intl, getItems }) {
    //hooks
    const { entity } = useEntity();
    //state
    const [error, setError] = useState(false);

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const onFinish = values => {
        if(visible.id){
                setLoading(true);
                entity.service.editFileAttachment({
                    "id":visible.id,
                    "title": values.title,
                    "description": values.description,
                    "link": "",
                    "accessLevel": values.accessLevel,
                    "type": "file",
                    "facilityEntityCode": entity.data.code,
                    "fileAttachements": visible.attachmentFiles ? visible.attachmentFiles.map(el=>(el.id)):[]
                    
                })
                .then(res=>{
                    
                    if (res.header.metaResponse !== null) {
                        message[res.header.responseCode === 0 ? "success" : "warning"](intl.formatMessage({ id: res.header.metaResponse }))
                    }
                    if (res.header.responseCode === 0) {
                        setVisible(false);
                        getItems();
                    }
                    setLoading(false)
                    
                })
                .catch(err=>{
                    console.log(err, "rr")
                    setLoading(false);
                })
           
        }else{
            if(values.fileAttachements){
                setLoading(true);
                entity.service.addAttachment({
                    "title": values.title,
                    "description": values.description,
                    "link": "",
                    "accessLevel": values.accessLevel,
                    "type": "file",
                    "facilityEntityCode": entity.data.code,
                    "fileAttachements": [
                        values.fileAttachements
                    ]
                })
                .then(res=>{
                    if(res){
                        setVisible(false);
                        getItems();
                    }
                    setLoading(false);
                })
                .catch(err=>{
                    setLoading(false);
                })
            }else{
                setError('Please upload a file!')
            }
        }
       
       
    }




    useEffect(() => {
        if (visible.id) {
            form.setFieldsValue(visible);
        }else if(!entity.data.isManager){
            form.setFieldsValue({
                accessLevel:"managerOccupant"
            });
        }else{
            form.setFieldsValue({
                accessLevel:"managerOnly"
            });
        }
       
    }, [visible])
    return (

        <Modal
            visible={visible}
            title={visible.id ?<IntlMessages id="label.editFileInformation" />:<IntlMessages id="label.addNewFile" />}
            keyboard={false}
            footer={false}
            onCancel={() => setVisible(false)}
        >
            <Spin spinning={loading}>
                <Form className="new-from"
                    name="edit-coordinate"
                    form={form} {...formItemLayout}
                    onFinish={onFinish}>

                    <Form.Item
                        name="title"
                        rules={[{ required: true, message: intl.formatMessage({ id: "form.required" }) }]}

                    >
                        <Input placeholder="Title" />
                    </Form.Item>
                    <Form.Item
                        name="description"
                        // rules={[{ required: true, message: intl.formatMessage({ id: "form.required" }) }]}

                    >
                        <Input placeholder="Description" />
                    </Form.Item>
                    <Form.Item
                        name="accessLevel"
                        rules={[{ required: true, message: intl.formatMessage({ id: "form.required" }) }]}

                    >
                        <Select
                            disabled={!entity.data.isManager}
                            placeholder="Access level">
                            <Option value="Public">
                                <IntlMessages id="public" />
                            </Option>
                            <Option value="ManagerOccupant">
                                <IntlMessages id="managerOccupant" />
                            </Option>
                            <Option value="ManagerOnly">
                                <IntlMessages id="managerOnly" />
                            </Option>
                        </Select>
                    </Form.Item>
                  {visible.id ===undefined &&  <Form.Item
                        name="fileAttachements"
                    >
                        <UploadFile 
                            error={error}
                            onChange={(e) => {setError(false); form.setFieldsValue({ fileAttachements: e })}} />
                    </Form.Item>}
                    <Button
                        onClick={form.submit}
                        type="primary" block>
                        {intl.formatMessage({ id: "button.save" })}
                    </Button>
                </Form>
            </Spin>
        </Modal>

    )
}

export default injectIntl(Index)