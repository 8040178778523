import React, { useState } from "react";
import moment from 'moment';
import IntlMessages from "util/IntlMessages";
import { Spin, Icon, Menu, message, Dropdown, Popconfirm } from "antd";
import { DeleteOutlined, FileOutlined } from '@ant-design/icons';
import { injectIntl, FormattedDisplayName } from 'react-intl';
import { useEntity } from "../../context";
import Item from './Note';

function Index({
    attachmentFiles, userCreatorFirstname, modifyDate, description, id,
    userCreatorLastname, title, userModifierFirstname, userModifierLastname, getItems,
    intl, registerDate, accessLevel, isCreator }) {
    //hooks
    const { entity } = useEntity();
    //state
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openItem, setOpenItem] = useState(false);
    //const
    const menu = () => {
        return (
            <Menu>
                <Menu.Item>
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={(e) => { deleteItem() }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <div style={{ width: "100%" }}><DeleteOutlined /> <IntlMessages id="label.delete" /></div>
                    </Popconfirm>

                </Menu.Item>
                <Menu.Item onClick={() => setOpenItem({
                    id,
                    title,
                    description,
                    accessLevel,
                    attachmentFiles
                })}>
                    <Icon type="edit" />  <IntlMessages id="label.edit" />
                </Menu.Item>
            </Menu>
        )
    }

    //functions
    const deleteItem = () => {
        setLoading(true)
        entity.service.deleteFileAttachment(id)
            .then(res => {
                if (res.header.metaResponse !== null) {
                    message[res.header.responseCode === 0 ? "success" : "warning"](intl.formatMessage({ id: res.header.metaResponse }))
                }
                if (res.header.responseCode === 0) {
                    getItems();
                }
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
            })

    }
    const hasImage = (type) => {
        const imagesTypes = ['png', 'jpg', 'jpeg'];
        if (imagesTypes.filter(el => type.toUpperCase().search(el.toUpperCase()) > -1).length) {
            return "image";
        }
        return "";
    }
    return (<Spin spinning={loading}>
        {openItem && <Item visible={openItem} setVisible={setOpenItem} getItems={getItems} />}
        <div className="file-card"
        style={{
            borderTop:"4px solid #AFCCCE"
        }}>
            <div className="header-card">
                <div className="line-one">
                    <div className="date-time">
                        {moment(registerDate).isValid() && moment(registerDate).format('MM/DD/YYYY, HH:mm')}
                    </div>
                    <div className="type">

                        {attachmentFiles && attachmentFiles.length}&nbsp; Files
                    </div>
                </div>
                <div className="creator">
                    {userCreatorFirstname + " " + userCreatorLastname}
                </div>
                <div className="title">
                    {title}
                </div>
                <div className="file-name">
                    {!open && description}
                </div>
                <div className="acions-icons">
                    {/* <a href={attachmentFiles && attachmentFiles[0] && attachmentFiles[0].cloudStorageLink}
                        target="_blank"
                        className="acions-icons-item"
                    ><IntlMessages id="btn.open" /></a> */}
                   
                    <div className={`acions-icons-item`}
                        onClick={() => setOpen(!open)}
                    ><IntlMessages id="btn.detials" /></div>
                    <div className="divider-more-container">
                        <div className="divider-more" />
                    </div>
                    <div className={`acions-icons-item ${!entity.data.isManager && !isCreator && "disabled"}`}>
                        <Dropdown overlay={menu} trigger={['click']}>
                            <a style={{ color: "unset" }}><IntlMessages id="btn.more" /></a>
                        </Dropdown>
                    </div>
                </div>
            </div>
            {open && <div className="content-card">                
                {userModifierFirstname && <div className="modify-user">
                    <IntlMessages id="updatedBy" />&nbsp;{userModifierFirstname + " " + userModifierLastname}
                </div>}
                {modifyDate &&
                    <div className="date-time"> {moment(modifyDate).isValid() && moment(modifyDate).format('MM/DD/YYYY, HH:mm')}</div>}
                <div className="description">
                    {description}
                </div>
                {attachmentFiles && attachmentFiles.map((fi, index) => {
                    if (hasImage(fi.fileType) === "image") {
                        return (
                            <a href={fi.cloudStorageLink}
                                target="_blank"
                                className="file-loader-uploades" key={index}>
                                <img src={fi.cloudStorageLink} style={{ objectFit: "contain" }} width="50px" height="50px" alt="file" />
                                <div className="name-file" title={fi.originalName}>
                                    {fi.originalName}
                                </div>

                            </a>
                        )
                    }
                    return (
                        <a href={fi.cloudStorageLink}
                            target="_blank"
                            className="file-loader-uploades">
                            <div className="icon-file"><FileOutlined style={{ fontSize: "18px" }} /></div>
                            <div className="name-file" title={fi.originalName}>{fi.originalName}</div>

                        </a>
                    )
                })}
            </div>}
        </div>
    </Spin>
    )


}


export default injectIntl(Index);