import React, { useEffect } from 'react';
import { Tabs } from 'antd4';
import { useEntity } from '../context';
import { withRouter } from "react-router"
import IntlMessages from "util/IntlMessages";
import LiveData from './liveData';
import Visualizations from './Visualizations';
import QrCode from './qrcode';
import Attachment from './attachment';
import Notes from './notes';
import Property from './property';
import Tags from './tags';
import Users from './users';
import Alerts from './alerts';

const { TabPane } = Tabs;

const Index = (props) => {
    //hooks
    const { entity, changeTab } = useEntity();

    //functions
    const callback = (key) => {
        if (entity.type === "page") {
            props.history.push(`/entitypanel/${key}/${entity.data.code}`);
        } else {
            changeTab(key);
        }
    }

    return (
        <Tabs activeKey={entity.selectTab}

            onChange={callback}>
            <TabPane tab={<IntlMessages id="entitypanel.liveData" />} key="livedata">
                <LiveData />
            </TabPane>
            <TabPane tab={<IntlMessages id="entitypanel.properties" />} key="properties">
                {entity.data && <Property />}
            </TabPane>
            <TabPane tab={<IntlMessages id="entitypanel.attachments" />} key="attachments">
                <Attachment />
            </TabPane>
            <TabPane tab={<IntlMessages id="entitypanel.notes" />} key="notes">
                <Notes />
            </TabPane>
            {  localStorage.getItem("user")&& <TabPane tab={<IntlMessages id="entitypanel.users" />} key="users">
                <Users />
            </TabPane>}
            <TabPane tab={<IntlMessages id="entitypanel.visualizations" />} key="visualizations">
                <Visualizations />
            </TabPane>
            <TabPane tab={<IntlMessages id="entitypanel.qrcode" />} key="qrcode">
                {entity.data && <QrCode />}
            </TabPane>
            <TabPane tab={<IntlMessages id="entitypanel.alerts" />} key="alerts">
                {entity.data && <Alerts />}
            </TabPane>
            { localStorage.getItem("user")&&<TabPane tab={<IntlMessages id="entitypanel.tags" />} key="tags">
                {entity.data && <Tags />}
            </TabPane>}
           

        </Tabs>
    );
};

export default withRouter(Index);
