import React from 'react';
import { Modal, Button, Spin, Descriptions } from 'antd';
import { EventService } from 'services';
import IntlMessages from "util/IntlMessages";
import moment from 'moment';

const EventSV = new EventService();

function Event(props) {
    
  const {visiable, setVisible} = props;
    const [item, setItem] = React.useState(false);

    const getEvent = () =>{
      EventSV.getById(visiable.facilityEventId, visiable.facilityId)
      .then(res => {
        if(res && res.data){
            setItem(res.data)
        }
      })
    }
    React.useEffect(()=> {
      getEvent();
    },[])
    return (
        <Modal
          title="Event"
          visible={visiable}
          footer={null}
          onCancel={() => props.setVisiable(false)}
        >
          <Spin spinning={item === false}>
            <Descriptions column={24} bordered size={"small"} >
              <Descriptions.Item span={24} label={<div><IntlMessages id="label.facilityName"/></div>}>{item.facilityName}</Descriptions.Item>
              <Descriptions.Item span={24} label={<div ><IntlMessages id="label.eventName"/></div>}>{item.title}</Descriptions.Item>
              <Descriptions.Item span={24} label={<div><IntlMessages id="label.createdBy"/></div>}>{item.firstName+" "+item.lastName}</Descriptions.Item>
              <Descriptions.Item span={24} label={<div ><IntlMessages id="label.startDate"/></div>}>{item.startDate?moment(item.startDate).format('MM/DD/YYYY, HH:mm'):""}</Descriptions.Item>
              <Descriptions.Item span={24} label={<div ><IntlMessages id="label.endDate"/></div>}>{item.startDate?moment(item.endDate).format('MM/DD/YYYY, HH:mm'):""}</Descriptions.Item>
              <Descriptions.Item span={24} label={<div ><IntlMessages id="label.description"/></div>}>{item.description}</Descriptions.Item>

           </Descriptions>                       
          </Spin>
        </Modal>
    );
}

export default Event;