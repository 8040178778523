import React, { useEffect, useState } from "react";
import IntlMessages from 'util/IntlMessages';
import { PlusOutlined, RiseOutlined, FallOutlined, FilterOutlined, SearchOutlined, DeleteOutlined, BgColorsOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { Button, Row, Col, Input, Checkbox, Spin, Select, Popconfirm, Container } from "antd4";
import { Popover } from 'antd';
import { useEntity } from '../../context';
import Item from './Item';
import { responsiveMode, responsiveModeSort, responsiveModePagination, responsiveModeAddUser, responsiveModeAdd, responsiveModeSearch } from '../../responsiveMode';
import Card from './ItemCard';
import axios from 'util/Api';
import { PropertyService } from 'services';
import { SortFunction, SortFunctionOutKey, fuseSearch } from 'constants/Functions';

const { Option } = Select;

const propertyService = new PropertyService();

function Index() {

    //hook
    const { entity } = useEntity();
    //state
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false)
    const [selectRole, setSelectRole] = useState(["Manager", "Occupant"]);
    const [data, setData] = useState([]);
    const [sort, setSort] = useState("isAscDateTrue");

    const [items, setItems] = useState([]);
    const [openItem, setOpenItem] = useState(false);
    const [strSearch, setStrSearch] = useState("");

    const onChangeAuther = (e) => {
        setSelectRole(e)

    }

    const changeSort = (strSort) => {
        let newItems = [...data];
        switch (strSort) {
            case "isAscDateTrue":
                newItems = newItems.sort((a, b) => (a['triggeredTime'] > b['triggeredTime']) ? 1 : ((b['triggeredTime'] > a['triggeredTime']) ? -1 : 0));
            case "isAscDateFalse":
                newItems = newItems.sort((a, b) => (b['triggeredTime'] > a['triggeredTime']) ? 1 : ((a['triggeredTime'] > b['triggeredTime']) ? -1 : 0));
            case "isAscTitleTrue":
                newItems = newItems.sort((a, b) => (a['name'] > b['name']) ? 1 : ((b['name'] > a['name']) ? -1 : 0));
            case "isAscTitleFalse":
                newItems = newItems.sort((a, b) => (b['name'] > a['name']) ? 1 : ((a['name'] > b['name']) ? -1 : 0));
            default:
                break;
        }

        setDataSearch(newItems, strSearch);
    }
    //dropdown
    //functions
    const setDataSearch = (data, newQuery) => {

        setLoading(true);
        data = fuseSearch(data, ['name', 'message', 'mapFirstSemanticName', 'mapSecondSemanticName'], newQuery);
        setItems(data)
        setLoading(false);
        setStrSearch(newQuery);
    }

    const getItems = () => {
        setLoading(true);

        entity.service.getAlert(entity.data.id)
            .then(res => {
                if (res.data) {
                    setData(res.data);
                    setItems(res.data);
                }
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
            })


    }

    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };
    const deleteItem = (ids) => {
        setLoading(true);
        let body = ids.length > 0 ? {
            "facilityEntityId": entity.data.id,
            "userIds": ids
        } : {
            "facilityEntityId": entity.data.id,
            "userIds": []
        }
        entity.service.deleteUser(body)
            .then(res => {
                // if (res.header.metaResponse !== null) {
                //     message[res.header.responseCode === 0 ? "success" : "warning"](props.intl.formatMessage({ id: res.header.metaResponse }))
                // }
                if (res && res.header && res.header.responseCode === 0) {
                    getItems();
                } else {
                    setLoading(false);
                }


            })
            .catch(err => {
                console.log(err)
                setLoading(false)
            })
    }

    //effect

    useEffect(() => {
        if (entity.data && entity.data.code) {
            getItems({
                "facilityEntityCode": entity.data.code,
                "searchTerm": "",
                "isAscDate": true,
                "size": 10,
                "page": 1
            })
        }
    }, [entity]);
    return (
        <div className="attachment-tabs">
            {openItem && <Item
                facilityEntityId={entity.data.id}
                facilityId={entity.data.facilityId}
                visible={openItem}
                setVisible={setOpenItem}
                getItems={() => getItems()} />}
            <Row gutter={[15, 15]}>



                <Col
                    {...responsiveModeSearch(entity.type)}
                >
                    <div className="input-search">
                        <Input
                            placeholder="Search in alerts"
                            allowClear={true}
                            onChange={(e) => { setDataSearch(data, e.target.value) }}
                            suffix={<SearchOutlined />} />
                    </div>
                </Col>

                <Col {...responsiveModeSort(entity.type)}>
                    <div className="filter-styles">
                        <Select defaultValue={sort}
                            onChange={(e) => changeSort(e)}
                        >
                            <Option value={"isAscDateTrue"}>
                                <RiseOutlined />&nbsp; <IntlMessages id="isAscDate" />
                            </Option>
                            <Option value={"isAscDateFalse"}>
                                <FallOutlined /> &nbsp;<IntlMessages id="isDescDate" />
                            </Option>
                            <Option value={"isAscTitleTrue"}>
                                <RiseOutlined /> &nbsp;<IntlMessages id="isAscName" />
                            </Option>
                            <Option value={"isAscTitleFalse"}>
                                <FallOutlined /> &nbsp;<IntlMessages id="isDescName" />
                            </Option>
                        </Select>
                    </div>


                </Col>
                <Col
                    justifyContent="flex-end"
                    {...responsiveModePagination(entity.type)}
                >
                    <div className="pagination-styles">
                        {items.length} <IntlMessages id="alerts" />
                    </div>

                </Col>
            </Row>

            <Spin spinning={loading}>
                <Row gutter={[15, 15]} wrap={false}>
                    {items && items.map((item, index) => (
                        <Col key={index}  {...responsiveMode(entity.type)}>
                            <Card
                                deleteItem={deleteItem}
                                {...item} getItems={() => getItems()} />
                        </Col>
                    ))}
                </Row>
            </Spin>

        </div>
    )
}

export default Index;