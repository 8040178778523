import React, {Component} from "react";
import {Layout, Popover, Button, Input, Badge} from "antd";
import {Link} from "react-router-dom";
import Notifications from './Notifications';
import CustomScrollbars from "util/CustomScrollbars";
import languageData from "./languageData";
import {switchLanguage, toggleCollapsedSideNav} from "../../appRedux/actions/Setting";
import {getNotification} from '../../appRedux/actions/Notification'
import SearchBox from "components/SearchBox";
import UserInfo from "components/UserInfo";
import AppNotification from "components/AppNotification";
import MailNotification from "components/MailNotification";
import Auxiliary from "util/Auxiliary";
import {DashboardService} from '../../services';

import {NAV_STYLE_DRAWER, NAV_STYLE_FIXED, NAV_STYLE_MINI_SIDEBAR, TAB_SIZE} from "../../constants/ThemeSetting";
import {connect} from "react-redux";

const DashboardSV = new DashboardService();
const {Header} = Layout;
const { TextArea } = Input;
const content = (
  <div>
    <div>
      Intellica: What can I do for you?
    </div>
    <br/>
    <TextArea rows={2} />
    <br/>
    <Button type="primary"  style={{ marginTop:"10px"}} block>Send</Button>
  </div>
); 

class Topbar extends Component {

  state = {
    searchText: '',
    notification:{
      totalCount:0,
      openNotification:false
    }
  };

  languageMenuTest=()=>(
    <ul className="gx-sub-popover">
    {languageData.map(language =>
      <li className="gx-media gx-pointer" key={JSON.stringify(language)} onClick={(e) =>
        this.props.switchLanguage(language)
      }>
        <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`}/>
        <span className="gx-language-text">{language.name}</span>
      </li>
    )}
  </ul>
  )
      
  languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {languageData.map(language =>
          <li className="gx-media gx-pointer" key={JSON.stringify(language)} onClick={(e) =>
            this.props.switchLanguage(language)
          }>
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`}/>
            <span className="gx-language-text">{language.name}</span>
          </li>
        )}
      </ul>
    </CustomScrollbars>);

  updateSearchChatUser = (evt) => {
    this.setState({
      searchText: evt.target.value,
    });
  };

  getDashboard = () => {
    DashboardSV.getDashboard()
    .then(res=>{
      if(res.data){
        this.setState({notification:res.data})
        this.props.getNotification(res.data)
      }
      
    })
    .catch(err=>{
      console.log(err)
    })
  }
  async componentDidMount(){
      this.getDashboard();  
      const intervalNotification = setInterval(()=>{
        this.getDashboard();
    }, 60000)
    return () => clearInterval(intervalNotification);        
  }
  render() {
    const {locale, width, navCollapsed, navStyle, notifications} = this.props;
    return (
      <Auxiliary>
      <Header>
          {navStyle === NAV_STYLE_DRAWER || ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) && width < TAB_SIZE) ?
            <div className="gx-linebar gx-mr-3">
              <i className="gx-icon-btn icon icon-menu"
                 onClick={() => {
                   this.props.toggleCollapsedSideNav(!navCollapsed);
                 }}
              />
            </div> : null}
          <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer">
            <img alt="" src={require("assets/images/w-logo.png")} width="75px"/></Link>
        {/* 
          <SearchBox styleName="gx-d-none gx-d-lg-block gx-lt-icon-search-bar-lg"
                     placeholder="Search in app..."
                     onChange={this.updateSearchChatUser.bind(this)}
                     value={this.state.searchText}/> */}
          <ul className="gx-header-notifications gx-ml-auto">
            {/* <li className="gx-notify gx-notify-search gx-d-inline-block gx-d-lg-none">
              <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={
                <SearchBox styleName="gx-popover-search-bar"
                           placeholder="Search in app..."
                           onChange={this.updateSearchChatUser.bind(this)}
                           value={this.state.searchText}/>
              } trigger="click">
                <span className="gx-pointer gx-d-block"><i className="icon icon-search-new"/></span>
              </Popover>
            </li> */}
            {/* {width >= TAB_SIZE ? null :
              <Auxiliary>
                <li className="gx-notify">
                  <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={<AppNotification/>}
                           trigger="click">
                    <span className="gx-pointer gx-d-block"><i className="icon icon-notification"/></span>
                  </Popover>
                </li>

                <li className="gx-msg">
                  <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight"
                           content={<MailNotification/>} trigger="click">
                  <span className="gx-pointer gx-status-pos gx-d-block">
                    <i className="icon icon-chat-new"/>
                    <span className="gx-status gx-status-rtl gx-small gx-orange"/>
                  </span>
                  </Popover>
                </li>
              </Auxiliary>
            } */}
            
           
            <li className="gx-language">
             
              
             {/* <div title="Intellica">
            
             <Popover content="Hi, I'm Intellica">
                <Popover style={{background:"red"}} content={content} trigger="click">
                <img src={require('../../assets/images/Intellica.png')} width="25vw" alt=""/>
                  </Popover>
                </Popover>
             </div> */}
         </li>
         <li style={{marginTop:"9px", cursor:"pointer"}}>
            <Popover onVisibleChange={()=>this.setState({openNotification:!this.state.openNotification})} visible={this.state.openNotification} overlayClassName="gx-popover-horizantal" placement="bottomRight" content={<Notifications getDashboard ={()=>this.getDashboard()} setOpenNotification = {()=>this.setState({openNotification:false})} items={notifications.items}/>}
                trigger="click">
                <Badge count={notifications.items && notifications.items.totalCount} style={{background:"red", opacity:"0.8"}}>
                  <i class="icon icon-notification" style={{fontSize:"20px"}}></i>
                </Badge>
            </Popover>

            </li>
            <li className="gx-language">
              <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={this.languageMenuTest()}
                       trigger="click">
                <span className="gx-pointer gx-flex-row gx-align-items-center">
                  <i className={`flag flag-24 flag-${locale.icon}`}/>
                  <span className="gx-pl-2 gx-language-name">{locale.name}</span>
                  <i className="icon icon-chevron-down gx-pl-2"/>
                </span>
               
              </Popover>
            </li>
            
           
            {width >= TAB_SIZE ? null :
              <Auxiliary>
                <li className="gx-user-nav"><UserInfo/></li>
              </Auxiliary>
            }
          </ul>
        </Header>
      </Auxiliary>
    );
  }
}

const mapStateToProps = ({settings, notifications}) => {
  const {locale, navStyle, navCollapsed, width} = settings;
  return {locale, navStyle, navCollapsed, width, notifications}
};

export default connect(mapStateToProps, {toggleCollapsedSideNav, switchLanguage, getNotification})(Topbar);
