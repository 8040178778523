import React, { useState, useEffect } from 'react'
import { Modal, Radio, Button, Input, Spin, Row, Col, message, Select, Descriptions } from 'antd';
import IntlMessages from "util/IntlMessages";
import moment from 'moment';
import { Form } from 'antd4';
import { injectIntl } from 'react-intl';
import { CommandService } from 'services';
import RenderIcon from './RenderIcon';

const FormItem = Form.Item;
const { Option } = Select;
const service = new CommandService();

function Index(props) {

    const { visible, setVisible, get } = props;
    const [loading, setLoading] = useState(false);
    const [item, setItem] = useState(false);
    const onFinish = (values) => {
        setLoading(true);
        service.addCommandWriteToAttribute({
            "specificationId": visible,
            "commandValue": values.commandValue
        })
            .then(res => {
                if (res.header && res.header.responseCode === 0) {
                    get();
                    setVisible(false);
                }
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
            })
    }

    const getItem = () => {
        service.GetSpecificationLastAttributeCommand({
            specificationId: visible
        })
            .then(res => {
                if (res.data) {
                    setItem(res.data.result);
                }
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
            })
    }
    useEffect(() => {
        if (visible) {
            getItem();
        }
    }, [visible])
    return (
        <Modal
            title={<IntlMessages id="label.writeToAttribute" />}
            visible={visible}
            onCancel={() => setVisible(false)}
            footer={false}
        >
            <Spin spinning={loading}>
                <Row>
                    <Col span={24}>
                        <span title={item.variableLabel}>{ item.aliasName || item.variableLabel }</span>
                        <br/>{item.endNodeName}&nbsp;({item.bacnetDeviceAddress})
                        <br/><br/><IntlMessages id="label.currentValue" />:&nbsp;{item.specificationLastValue}&nbsp;{item.convertedSemanticUnitSymbol}
                        <br/>{moment(item.specificationLastValueDate).format("MM/DD/YYYY HH:mm:ss")}
                        {item.hasCommand && <><br/><br/><IntlMessages id="label.lastAction" />:&nbsp;<RenderIcon type={item.commandProcessLevel}/>&nbsp;
                        <IntlMessages id="label.setTo" />&nbsp;{item.commandValue}
                       
                       <br/>{moment(item.lastStatusDate).format("MM/DD/YYYY HH:mm:ss")}
                       </>}
                       <br/>
                       <br/>
                        <Form
                            name={"form-change-mode"}
                            onFinish={onFinish}
                            layout="vertical"
                            className="gx-signin-form gx-form-row0"
                        >
                            <Form.Item
                                name="commandValue"
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="form.required" />,
                                    }
                                ]}
                            >
                                <div style={{ display: "flex",marginTop:"10px", alignItems:"center", columnGap:"5px" }}>
                                    <IntlMessages id="label.setTo" />
                                    <Input style={{width:"150px"}} placeholder={"Command value"} />
                                    {item.convertedSemanticUnitName !=="Undefined" && item.convertedSemanticUnitName}
                                </div>
                            </Form.Item>
                            <br/>
                            <FormItem>
                                <Button block type="primary" block className="gx-mb-0" htmlType="submit">
                                    <IntlMessages id="button.apply" />
                                </Button>
                            </FormItem>
                        </Form>
                    </Col>
                </Row>
            </Spin>

        </Modal>
    )
}


export default Index;
