import { BASE_URL, HEADERS } from './index';
import axios from 'axios';
import {message} from 'antd';

const api = "user/Semantic"

export default class SemanticService {
    getAll(){
        return axios.get(`${BASE_URL}${api}`,HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));         
    }
    addUser(data){
        return axios.put(`${BASE_URL}${api}/AddUsers`, data, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    add(data){
        return axios.post(`${BASE_URL}${api}`, data, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    edit(data){
        return axios.put(`${BASE_URL}${api}`, data, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    delete(id){
        return axios.delete(`${BASE_URL}${api}/${id}`, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    getByFacilityId(id){
        return axios.get(`${BASE_URL}${api}/GetFacilitySemantic?facilityId=${id}`, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    getSemanticsByEndNode = (id) =>{
        return axios.get(`${BASE_URL}${api}/GetNodeSemantics?nodeId=${id}`, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    getSemanticsByModelNumber = (id, facilityId) =>{
        return axios.get(`${BASE_URL}${api}/GetModelNumberSemantics?modelNumber=${id}&facilityId=${facilityId}`, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
}

