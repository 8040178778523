import { BASE_URL, HEADERS } from './index';
import axios from 'axios';
import {message} from 'antd';

const api = "user/"

const qs = require('querystring')

export default class FacilityService {
    getAllUsers(name){
        return axios.get(`${BASE_URL}${api}User/Search?name=${name}`,HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));         
    }
    getByFacilityId(id){
        return axios.get(`${BASE_URL}${api}Facility/GetUsers/${id}`,HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));         
    }
    add(data){
        return axios.post(`${BASE_URL}${api}Facility/AddUsers`, data, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    edit(data){
        return axios.put(`${BASE_URL}${api}Facility/UpdateRole`, data, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    delete(facId ,id){
        return axios.delete(`${BASE_URL}${api}Facility/DeleteUser/${facId}/${id}`, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    getTimeZone(){
        return axios.get(`${BASE_URL}user/TimeZone`,HEADERS)
          .then(res => {
              if(res.data.header.responseCode === 0){
                  return res.data
              }
              else{
                  message.warning(res.data.header.message, 4);
              }
          })          
          .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));

    }
}

