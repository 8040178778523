/*eslint-disable no-undef*/

import React, { useEffect, useState } from "react";
import IntlMessages from 'util/IntlMessages';
import { Radio, Spin } from 'antd4';
import { useEntity } from "../../context";
import { GrEdit, GrUpdate } from 'react-icons/gr'
import EditCoordinate from './EditCoordinates';
import EditDirection from "./EditDirection";
import EditErrorLocation from './EditErrorLocation'
import EditErrorDirection from './EditErrorDirection';

function Index() {
    const { entity, changeType } = useEntity();
    //state
    const [item, setItem] = useState();
    const [loading, setLoading] = useState(false);
    const [openEditCoordinate, setOpenEditCoordinate] = useState(false);
    const [openEditDirection, setOpenEditDirectoin] = useState(false);
    const [openErrorLocation, setOpenErrorLocation] = useState(false);
    const [openErrorDirection, setOpenErrorDirection] = useState(false);
    //functions
    const getItem = () => {
        setLoading(true);
        entity.service.getCoordinates(entity.data.code)
            .then(res => {
                let newItem = res.data;
                try {
                    newItem.xyz = JSON.parse(newItem.xyz);
                }
                catch {
                    newItem.xyz = { x: null, y: null, z: null };
                }
                setLoading(false);
                setItem(newItem);

            })
            .catch(err => {
                setLoading(false);
            })
    }
    const updateCoordinate = () => {
        setLoading(true);
        let activeFiles = JSON.parse(sessionStorage.getItem("Active_Files"));

        entity.service.getElement(entity.data.id, 999, 1)
            .then(res => {
                let newObject = res.data.filter(el => activeFiles.indexOf(el.facility3DFileId) > -1).map(el => { return { model_id: el.facility3DFileId, object_id: el.dbId } });
                let newXyz = Twinup3D.getWorldMatrixByNodeIds(newObject, undefined);
                setOpenEditCoordinate({ ...item, xyz: newXyz })
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
            })

    }
    const changeSprite = () => {
        let el = document.getElementById("show-info-dbid");
        el.style.visibility = "hidden";

        document.dispatchEvent(new CustomEvent('Viewer_Loader', {detail:{loading:true}}));
        Twinup3D.monitorClickPoint();
        document.addEventListener('CLICK_POINT', (evt) => changeSpriteAfterClick(evt));
        document.addEventListener('keyup', (event) => {
            if (event.key === "Escape") {
                //do something
                event.preventDefault();
                event.stopPropagation();
                event.stopImmediatePropagation();
                if(el){
                    el.style.visibility = "unset";
                }                
                document.removeEventListener('keyup', ()=>{});
            }
        });

    }
    const changeSpriteAfterClick = async (evt) => {
        
        if (evt && evt._args && evt._args.matrix) {
            setOpenEditCoordinate({ ...item, xyz: evt._args.matrix });
            let el = document.getElementById("show-info-dbid");
            el.style.visibility = "unset";
            Twinup3D.unmonitorClickPoint();
            document.dispatchEvent(new CustomEvent('Viewer_Loader', {detail:{loading:false}}));

        }
    }
    //effect
    useEffect(() => {
        if (entity.data && entity.data.code) getItem();
    }, [entity.data]);
    return (
        <div className="location-tab">
            {openEditCoordinate && <EditCoordinate
                getItem={getItem}
                visible={openEditCoordinate}
                setVisible={setOpenEditCoordinate} />}
            {openEditDirection && <EditDirection
                getItem={getItem}
                visible={openEditDirection}
                setVisible={setOpenEditDirectoin} />}
            {openErrorLocation && <EditErrorLocation
                getItem={getItem}
                visible={openErrorLocation}
                setVisible={setOpenErrorLocation} />}
            {openErrorDirection && <EditErrorDirection
                getItem={getItem}
                visible={openErrorDirection}
                setVisible={setOpenErrorDirection} />}
            <div className="type-entity">
                <IntlMessages id="label.type" />:
                <Radio.Group onChange={(e) => changeType(e.target.value)}
                    value={entity.data && entity.data.locationType} >
                    <Radio value={"Static"}>Static</Radio>
                    <Radio value={"Dynamic"}>Dynamic</Radio>
                </Radio.Group>
            </div>
            <Spin spinning={loading}>
                {entity.type === "forge" && <div className="place-viewer"
                    onClick={changeSprite}>
                    <IntlMessages id="label.placeInTheViewer" />
                </div>}
                <div className="coordinates">
                    {item && item.xyz && <div className="coordinates-item">
                        <div style={{ display: "flex", columnGap:"10px" }}>
                            <IntlMessages id="label.coordinates" /><div
                                onClick={() => setOpenEditCoordinate(item)}
                                className="edit-able"><GrEdit /></div>
                                {entity.type === "forge" &&
                            <div
                                onClick={updateCoordinate} className="edit-able">
                                <GrUpdate />
                            </div>}
                        </div>
                        <div className="part-two">X={item && item.xyz && parseFloat(item.xyz.x).toFixed(2)}</div>
                        <div className="part-two">Y={item && item.xyz && parseFloat(item.xyz.y).toFixed(2)}</div>
                        <div className="part-two">Z={item && item.xyz && parseFloat(item.xyz.z).toFixed(2)}&nbsp;</div>
                        
                    </div>}
                    <div className="coordinates-item">
                        <div style={{ display: "flex", columnGap:"10px"  }}>
                            <IntlMessages id="label.direction" />&nbsp;
                            <div
                                onClick={() => setOpenEditDirectoin(item)}
                                className="edit-able"><GrEdit /></div>
                        </div>
                        <div className="part-two">VX={item && item.dx}</div>
                        <div className="part-two">VY={item && item.dy}</div>
                        <div className="part-two">VZ={item && item.dz}&nbsp;</div>

                    </div>
                    {entity && entity.data && entity.data.locationType === "Dynamic" && <> <div className="coordinates-item-line"
                    >
                        <IntlMessages id="label.coordinatesErrorRadius" />:
                        &nbsp;{item && item.errorLocation}&nbsp;ft
                        <div className="edit-able"
                            onClick={() => setOpenErrorLocation(item)}
                        ><GrEdit /></div>
                    </div>
                        <div className="coordinates-item-line">
                            <IntlMessages id="label.directionAngularError" />:
                            &nbsp;{item && item.errorDiretion}&nbsp;°
                            <div className="edit-able"
                                onClick={() => setOpenErrorDirection(item)}
                            ><GrEdit /></div>
                        </div></>}
                </div>
            </Spin>

        </div>
    )
}

export default Index;