import React, { useState } from 'react';
import { Table, Modal, Button, Row, Col, Divider, Popconfirm, message, Switch } from 'antd4';
import { AlertService } from 'services';
import { Icon } from 'antd';
import { injectIntl } from 'react-intl'
import Add from './Add';
import Edit from './Edit';

const AlertSV = new AlertService();


function Index({ visible, setVisible, intl, name }) {
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [openEdit, setOpenEdit] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState(false);
    const deleteItem = (id) => {
        AlertSV.delete([id])
            .then(res => {
                setItems([])
                getItems();
                if (res && res.header) {
                    if (res.header.metaResponse !== null) {
                        message[res.header.responseCode === 0 ? "success" : "warning"](intl.formatMessage({ id: res.header.metaResponse }))

                    }

                }
            })
            .catch(err => console.log(err))
    }
    const editStatus = (id) => {
        setLoadingStatus(id);
        AlertSV.editStatus(id)
            .then(res => {
                if (res && res.header && res.header.responseCode === 0) {
                    getItems();
                }
            })
            .catch(err => setLoadingStatus(false));
    }

    const getItems = () => {
        setLoading(true);
        AlertSV.getAllInsight(visible.facilityId, visible.insightId)
            .then(res => {
                if (res && res.data) {
                    setItems(res.data)
                }
                setLoading(false)
            })
            .catch(err => setLoading(false))
    }
    const columns = [
        {
            title: intl.formatMessage({ id: "label.name" }),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Logic',
            dataIndex: 'logic',
            key: 'logic',
            render: (text, record) => <span>{record.logicDescription}</span>
        },
        {
            title: 'Active',
            align: "center",
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => <div style={{ display: "flex", justifyContent: "center" }}><Switch
                size="small"
                checked={!record.isDisabled}
                loading={loadingStatus === record.id}
                onChange={() => editStatus(record.id)} /></div>
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <span>
                    <Icon onClick={() => setOpenEdit(record)} type="edit" />
                    <Divider type="vertical" />
                    <Popconfirm
                        title="Are you sure delete this Alert?"
                        onConfirm={() => deleteItem(record.id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Icon type="delete" />
                    </Popconfirm>

                </span>
            ),
        },
    ];
    React.useEffect(() => {
        getItems();
    }, [])
    return (
        <>
            {openEdit &&
                <Edit
                    visible={openEdit}
                    getItems={getItems}
                    id={visible.facilityId}
                    setVisible={setOpenEdit} 
                    name={name} label={visible.label}/>
            }
            {openAdd && <Add name={name} getItems={getItems} label={visible.label} symbol={visible.symbol} id={visible.facilityId} insightId={visible.insightId} visible={openAdd} setVisible={setOpenAdd} />}
            <Modal 
                // title={intl.formatMessage({ id: "label.alerts" })}
                visible={visible}
                width={700}
                footer={false}
                onCancel={() => setVisible(false)}>
                <Button type="primary" onClick={() => setOpenAdd(true)} >{intl.formatMessage({ id: "button.addNewAlert" })} </Button>

                <Table scroll={{ x: "100%" }} columns={columns} dataSource={items} />

            </Modal>
        </>
    )
}

export default injectIntl(Index);