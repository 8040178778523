import Fuse from "fuse.js";

function SortFunction(arr, key) {
    return arr.sort((a, b) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0));
}

function SortFunctionOutKey(arr) {
    return arr.sort((a, b) => (a > b) ? 1 : ((b > a) ? -1 : 0));
}

function timeEntityRender(time) {
    if (time) {
        if (180 >= time) {
            return `${parseInt(time)} minutes`
        } else if (time > 180 && 2880 >= time) {
            return `${Math.floor(time / 60)} hours`
        } else if (time > 2880) {
            return `${Math.floor(time / 1440)} days`
        }
    } else {
        return 0;
    }

}

function renderTypeDevice(type) {
    switch (type) {
        case 'SNH':
            return 'H-series';
        case 'SNU':
            return 'U-series';
        case 'SNS':
            return 'S-series';
        case 'Manual':
            return 'Manual';
        case 'Generic':
            return 'Generic';
        case 'BACnet':
            return 'BACnet';
        default:
            return 'Device-All'
    }
}


function fuseSearch(items, keys, query) {
    const fuseOptions = {
        shouldSort: true,
        includeMatches: true,
        threshold: 0.1,
        location: 0,
        distance: 1000,
        maxPatternLength: 32,
        findAllMatches: true,
        minMatchCharLength: 1,
        keys: keys
    };
    const fuse = new Fuse(items, fuseOptions);
    return query ? fuse.search(query).map(el => { return el.item }) : items;

}

function renderColorCustomeData(dataColors) {

    let conditions = JSON.parse(sessionStorage.getItem('colorsCustome'));
    let total = conditions && conditions.length;
    let newArr = [];
    conditions && dataColors.forEach(el => {
        if (conditions[0].value >= parseFloat(el.value)) {
            newArr.push({ ...el, color: conditions[0].color })
        } else if (total > 1 && parseFloat(el.value) > conditions[0].value && conditions[1].value >= parseFloat(el.value)) {
            newArr.push({ ...el, color: conditions[1].color })
        } else if (total > 2 && parseFloat(el.value) > conditions[1].value && conditions[2].value >= parseFloat(el.value)) {
            newArr.push({ ...el, color: conditions[2].color })
        } else if (total > 3 && parseFloat(el.value) > conditions[2].value && conditions[3].value >= parseFloat(el.value)) {
            newArr.push({ ...el, color: conditions[3].color })
        } else if (total > 4 && parseFloat(el.value) > conditions[3].value && conditions[4].value >= parseFloat(el.value)) {
            newArr.push({ ...el, color: conditions[4].color })
        } else if (total > 5 && parseFloat(el.value) > conditions[4].value && conditions[5].value >= parseFloat(el.value)) {
            newArr.push({ ...el, color: conditions[5].color })
        } else if (total > 6 && parseFloat(el.value) > conditions[5].value && conditions[6].value >= parseFloat(el.value)) {
            newArr.push({ ...el, color: conditions[6].color })
        } else if (total > 7 && parseFloat(el.value) > conditions[6].value && conditions[7].value >= parseFloat(el.value)) {
            newArr.push({ ...el, color: conditions[7].color })
        } else if (total > 8 && parseFloat(el.value) > conditions[7].value && conditions[8].value >= parseFloat(el.value)) {
            newArr.push({ ...el, color: conditions[8].color })
        } else if (total > 9 && parseFloat(el.value) > conditions[8].value && conditions[9].value >= parseFloat(el.value)) {
            newArr.push({ ...el, color: conditions[9].color })
        } else if (parseFloat(el.value) > conditions[total - 1].value) {
            newArr.push({ ...el, color: conditions[total - 1].color })
        }
    }, newArr)
    newArr = newArr.map(el => { return { objects: el.dbIds.map(j => { return { object_id: parseInt(j.dbId), model_id: j.facility3DFileId } }), color: el.color } })
    return newArr;
}

function renderOverView(data, items) {
    let newArr = Array(10).fill({ label: "", value: 0, min: data.min, max: data.max });
    let diff = data.max - data.min;
    if (diff > 1 && items.length > 10) {
        let range = diff / 10;
        newArr = newArr.reduce((all, current, index) => {
            let min = (data.min + (range * index));
            let max = index === 9 ? data.max : (data.min + ((range * index) + range));
            let sum = 0;
            if (index === 9) {
                sum = items.filter(el => el.value >= min && max >= el.value).reduce((total, cur) => { return total + cur.ferequency }, 0);
            } else {
                sum = items.filter(el => el.value >= min && el.value < max).reduce((total, cur) => { return total + cur.ferequency }, 0);
            }
            all.push({ ...current, min: min, max: max, value: sum });
            return all;
        }, [])
        return newArr.map((el => { return { label: `(${el.min.toFixed(1)}, ${el.max.toFixed(1)})`, value: el.value } }));
    } else {
        return items.map(el => { return { label: el.value, value: el.ferequency } });
    }

}

export {
    SortFunction, SortFunctionOutKey, timeEntityRender, renderTypeDevice,
    fuseSearch, renderColorCustomeData,
    renderOverView
}