import React, { createContext, useReducer, ReactNode, useEffect } from 'react'

import { EntityService } from 'services';
import axios from 'util/Api';

const service = new EntityService();


const initialStateContext = {
    data: undefined,
    type: "page",
    loading: false,
    selectTab: "livedata",
    service: service,
    twoDFile: undefined

}

const EntityPanelContext = createContext({ entity: initialStateContext });


const reducer = (state, action) => {
    switch (action.type) {
        case "SET":
            return {
                ...state,
                data: action.value
            };
        case "SET_TAB":
            return {
                ...state,
                selectTab: action.value
            };
        case "SET_TYPE":
            return {
                ...state,
                type: action.value
            };
        case "SET_TWOD_FILE":
            return {
                ...state,
                twoDFile: action.value
            };
        case "LOADING":
            return {
                ...state,
                loading: action.value
            };
        default:
            throw new Error();
    }
};


export const EntityProvider = ({ children, id, type, tab, twoDFile }) => {

    //state
    const [entity, dispatch] = useReducer(reducer, initialStateContext);
    //function
    const changeLoading = (status) => {
        dispatch({ type: "LOADING", value: status })
    }
    const changeTab = (key) => {
        dispatch({ type: "SET_TAB", value: key });
    }
    const getInfo = () => {
        changeLoading(true);
        console.log(localStorage.getItem("user"), "user")
        if (localStorage.getItem("user")) {
            service.getInfo(id)
                .then((res) => {
                    if (res.data) {
                        dispatch({ type: "SET", value: res.data });
                    }
                    changeLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    changeLoading(false);
                })
        } else {
            console.log("without token")
            axios.get('GetInfo', {
                params: {
                    code: id
                }
            })
                .then((res) => {
                    console.log(res.data, "res.data")
                    if (res.data) {
                        dispatch({ type: "SET", value: res.data.data })
                    }
                    changeLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    changeLoading(false);
                })
        }

    }
    const changeType = (locationType) => {
        changeLoading(true);
        service.updateLocationType({
            "facilityEntityCode": entity.data.code,
            "locationType": locationType
        })
            .then(res => {
                getInfo();
            })
            .catch(err => {
                console.log(err);
                changeLoading(false);
            })
    }
    const disableMenu = () => {
    }
    useEffect(() => {
        if (entity.data === undefined && id) {
            getInfo();
        }

    }, [id]);
    useEffect(() => {
        dispatch({ type: "SET_TWOD_FILE", value: twoDFile })
    }, [twoDFile]);
    useEffect(() => {
        dispatch({ type: "SET_TYPE", value: type })
    }, [type]);
    useEffect(() => {
        if (tab) changeTab(tab);

    }, [tab])
    return (
        <EntityPanelContext.Provider value={{
            entity, changeTab, getInfo, changeType
        }}>
            {children}
        </EntityPanelContext.Provider>
    );
}
export const useEntity = () => React.useContext(EntityPanelContext);
