import React from 'react';
import { Collapse } from 'antd4';
import IntlMessages from 'util/IntlMessages';
import Location from './Locations';
import LocationTwoD from './Locations2d';
import { GrRefresh } from 'react-icons/gr';
import { useEntity } from '../../context';
import Sprite from './Sprite';
import Elements from '../elements';
const { Panel } = Collapse;

function Index(props) {
    //hooks
    const { getInfo, entity } = useEntity();

    return (
        <div className='entity-panel-content-tab'>
            <div className='refresh-action' onClick={() => getInfo()}>
                <GrRefresh />&nbsp;<IntlMessages id="refresh" />
            </div>
            <Collapse ghost>
                <Panel header={<IntlMessages id="entityPanel.sprite" />} key="3">
                    <Sprite />
                </Panel>
                <Panel disabled={entity.type === "forge2d"} header={<IntlMessages id="entityPanel.location" />} key="1">
                    <Location />
                </Panel>
                <Panel disabled={entity.type !== "forge2d"} header={<IntlMessages id="entityPanel.locationIn2D" />} key="2">
                    <LocationTwoD />
                </Panel>
               
                <Panel  header={<IntlMessages id="entityPanel.elements" />} key="4">
                    <Elements />
                </Panel>

            </Collapse>
        </div>

    )
}


export default Index;