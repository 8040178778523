import React from 'react';
import { Collapse } from 'antd4';
import IntlMessages from 'util/IntlMessages';
import { useEntity } from '../../context';
import {AiFillPrinter} from 'react-icons/ai';
import QrCode from 'components/Qrcode';
import PrintFile from '../../../FacilityPage/Profile/Entity/EntityTab/Printqr';
import { Typography } from 'antd4';
const { Panel } = Collapse;

const { Paragraph } = Typography;

function Index(props) {
    //hooks
    const {getInfo, entity } = useEntity();
    const printDiv =(item)=> {
        console.log(document.getElementById(`printfile${item.id}`))

        if(document.getElementById("printfile"+item.id)){
            var divContents = document.getElementById("printfile"+item.id).innerHTML;
            console.log(divContents)
            var a = window.open('', '', 'height=500, width=500');
            a.document.write('<html>');
            a.document.write('<body >');
            a.document.write(divContents);
            a.document.write('</body></html>');
            a.document.close();
            a.print();
        }
       
    }
    let url = `https://twinup.ai/entitypanel/livedata/${entity && entity.data && entity.data.code}`;

    return (
        <div className='entity-panel-content-tab'>
            <PrintFile items={[entity.data]} id={`printfile${entity.data.id}`}/>
            <div className='refresh-action' onClick={() => printDiv(entity.data)}>
            <AiFillPrinter/>&nbsp;<IntlMessages id="print" />
            </div>
            <div className='qrcode-content'>
                <div className='name'>
                    {entity.data.name}
                </div>
                <div>
                {entity.data.locationType}
                </div>
                <div className='qrcode'>
                    <QrCode item={entity.data}/>
                </div>
                <div className='logo-site'>
                    <img
                        width="100%"
                        height="100%"
                        alt="logo"  
                        src={require("../../../../assets/images/logo-qr-code.png")}/>
                </div>
                <div style={{width:"80%", margin:"auto",textAlign:"center", cursor:"pointer", textDecoration:"underline", color:"#038fde"}}>
                <Paragraph style={{color:"#038fde"}} ellipsis copyable onClick={()=>window.open(url, '_blank')}>{url}</Paragraph>
            </div>
            </div>
          
        </div>

    )
}


export default Index;