import { BASE_URL, HEADERS } from './index';
import axios from 'axios';
import {message} from 'antd';

const api = "user/Facility";
const apiReport = "user/FacilityReport";

export default class FacilityService {
    getAll(){
        return axios.get(`${BASE_URL}${api}`,HEADERS)
        .then(res => {
            return res.data
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));         
    }   
    getById(id){
        return axios.get(`${BASE_URL}${api}?id=${id}`,HEADERS)
        .then(res => {
          return res.data
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));         
    }
    
    add(data){
        return axios.post(`${BASE_URL}${api}`, data, HEADERS)
        .then(res => {
            return res.data
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    edit(data){
        return axios.put(`${BASE_URL}${api}`, data, HEADERS)
        .then(res => {
            return res.data
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    joinByEmail(facilityId,email){
        return axios.put(`${BASE_URL}${api}/JoinByEmail?facilityId=${facilityId}&emails=${email}`, null, HEADERS)
        .then(res => {
            return res.data
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    disableLink(id){
        return axios.put(`${BASE_URL}${api}/JoinCodeDisable?facilityId=${id}`, HEADERS)
        .then(res => {
            return res.data
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    joinFacility(code){
        return axios.get(`${BASE_URL}${api}/Join?code=${code}`,HEADERS)
        .then(res => {
            return res.data
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));         
    }
    refreshLink(id){
        return axios.put(`${BASE_URL}${api}/JoinCodeRefresh?facilityId=${id}`, HEADERS)
        .then(res => {
            return res.data
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    editDomain(data){
        return axios.put(`${BASE_URL}${api}/UpdateUserDomainExpertise`, data, HEADERS)
        .then(res => {
            return res.data
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    delete(id){
        return axios.delete(`${BASE_URL}${api}/${id}`, HEADERS)
        .then(res => {
            return res.data
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    leave(id){
        return axios.delete(`${BASE_URL}${api}/leave/${id}`, HEADERS)
        .then(res => {
            return res.data
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    getTypes(){
        return axios.get(`${BASE_URL}user/FacilityType`,HEADERS)
          .then(res => {
              if(res.data.header.responseCode === 0){
                  return res.data
              }
              else{
                  message.warning(res.data.header.message, 4);
              }
          })          
          .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));

    }
    getByFacilityId(id){
        return axios.get(`${BASE_URL}${api}/GetUsers/${id}`,HEADERS)
        .then(res => {
            if(res.data.header.responseCode === 0){
                return res.data;   
            }
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));         
    }
    getByFacilityLicense(id){
        return axios.get(`${BASE_URL}${api}/GetFacilityLicense?facilityId=${id}`,HEADERS)
        .then(res => {
            if(res.data.header.responseCode === 0){
                return res.data;   
            }
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));         
    }
    getMessage(pageNumber){
        return axios.get(`${BASE_URL}${api}DeviceMessage?pageSize=10&pageNumber=${pageNumber}`,HEADERS)
          .then(res => {
              if(res.data.header.responseCode === 0){
                  return res.data
              }
              else{
                  message.warning(res.data.header.message, 4);
              }
          })          
          .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));

    }

    //facility report
    getFacilityReport(facilityId, semanticId, date){
        return axios.get(`${BASE_URL}${apiReport}`,{
            HEADERS,
        params:{
            facilityId,
            semanticId,
            date
        }})
          .then(res => {
              if(res.data.header.responseCode === 0){
                  return res.data
              }
              else{
                  message.warning(res.data.header.message, 4);
              }
          })          
          .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    getFacilityReportNew(facilityId, semanticId, date, url){
        return axios.get(`${BASE_URL}${apiReport}/${url}`,{
            HEADERS,
        params:{
            facilityId,
            semanticId,
            date
        }})
          .then(res => {
              if(res.data.header.responseCode === 0){
                  return res.data
              }
              else{
                  message.warning(res.data.header.message, 4);
              }
          })          
          .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    getFacilityReportCurrentSemantic(facilityId, semanticId){
        return axios.get(`${BASE_URL}${apiReport}/GetCurrentSemanticOverview`,{
            HEADERS,
        params:{
            facilityId,
            semanticId
        }})
          .then(res => {
              if(res.data.header.responseCode === 0){
                  return res.data
              }
              else{
                  message.warning(res.data.header.message, 4);
              }
          })          
          .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    getFacilityReportCurrentInsight(facilityId, insightLibraryId){
        return axios.get(`${BASE_URL}${apiReport}/GetCurrentInsightOverview`,{
            HEADERS,
        params:{
            facilityId,
            insightLibraryId
        }})
          .then(res => {
              if(res.data.header.responseCode === 0){
                  return res.data
              }
              else{
                  message.warning(res.data.header.message, 4);
              }
          })          
          .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    getFacilityReportGetCurrentEntityOverview(facilityId, code){
        return axios.get(`${BASE_URL}${apiReport}/GetCurrentEntityOverview`,{
            HEADERS,
        params:{
            facilityId,
            code
        }})
          .then(res => {
              if(res.data.header.responseCode === 0){
                  return res.data
              }
              else{
                  message.warning(res.data.header.message, 4);
              }
          })          
          .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    pinOverviewEntity(data){
        return axios.post(`${BASE_URL}${apiReport}/PinEntityOverviewToDashboard`, data, HEADERS)
        .then(res => {
            return res.data
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    pinOverviewSemantic(data){
        return axios.post(`${BASE_URL}${apiReport}/PinSemanticOverviewToDashboard`, data, HEADERS)
        .then(res => {
            return res.data
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    pinOverviewSemantic(data){
        return axios.post(`${BASE_URL}${apiReport}/PinSemanticOverviewToDashboard`, data, HEADERS)
        .then(res => {
            return res.data
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }

    pinOverviewInsight(data){
        return axios.post(`${BASE_URL}${apiReport}/PinInsightLibraryOverviewToDashboard`, data, HEADERS)
        .then(res => {
            return res.data
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }

    editOverviewEntityInsight(data){
        return axios.put(`${BASE_URL}${apiReport}/EditEntityInsightOverview`, data, HEADERS)
        .then(res => {
            return res.data
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    editOverviewEntitySemantic(data){
        return axios.put(`${BASE_URL}${apiReport}/EditEntitySemanticOverview`, data, HEADERS)
        .then(res => {
            return res.data
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    getEntityGraph(lable, bodyString){
        return axios.get(`${BASE_URL}${apiReport}/AmazingEntityGraphGet?lable=${lable}&${bodyString}`, HEADERS)
        .then(res => {
            return res.data
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }

    getCurrentWeather(id, locale, units){
        return axios.get(`${BASE_URL}${api}/CurrentWeather?facilityId=${id}&lang=${locale}&units=${units}`,HEADERS)
        .then(res => {
          return res.data
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));         
    }
}

