import {
  GET_NOTIFICATION
} from "../../constants/ActionTypes";

export const getNotification = (values) => {
  return (dispatch) => {
    dispatch({type: GET_NOTIFICATION, payload: values});
  }
};

