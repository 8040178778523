import { BASE_URL, HEADERS } from './index';
import axios from 'axios';
import {message} from 'antd';
import { getTwoToneColor } from '@ant-design/icons';

const api = "user/FacilityEntity"

const qs = require('querystring')

export default class Entity {
    getAll(facilityId, searchTerm){
        return axios.get(`${BASE_URL}${api}?facilityId=${facilityId}&searchTerm=${searchTerm}`,HEADERS)
        .then(res => {
                return res.data;})
       
    }
    getLocation2D(params){
        return axios.get(`${BASE_URL}${api}/Location2D`,{
            ...HEADERS,
            params:params
        })
        .then(res => {
                return res.data;})
       
    }
    getList(facilityId){
        return axios.get(`${BASE_URL}${api}/getList?facilityId=${facilityId}`,HEADERS)
        .then(res => {
                return res.data;})
       
    }
    getSpecificationLastValue(code){
        return axios.get(`${BASE_URL}${api}/SpecificationLastValue?code=${code}`,HEADERS)
        .then(res => {
                return res.data;})
       
    }
    getInfo(code){
        return axios.get(`${BASE_URL}${api}/GetInfo?code=${code}`,HEADERS)
        .then(res => {
                return res.data;})
    }
    getById(id, facilityId){
        return axios.get(`${BASE_URL}${api}?facilityId=${facilityId}&id=${id}`,HEADERS)
        .then(res => {
            if(res.data.header.responseCode === 0){
                return res.data;
            }
            else{
                message.warning(res.data.header.message, 4);
            }
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 2));         
    }   
    assginUserToAllEntity(data){
        return axios.put(`${BASE_URL}${api}/AssignUserToAllEntity`,data ,HEADERS)
        .then(res => {
            if(res.data.header.responseCode === 0){
                return res.data;
            }
            else{
                message.warning(res.data.header.message, 4);
            }
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 2));         
    } 
    getAlert(id){
        return axios.get(`${BASE_URL}${api}/EntityTriggeredAlert?facilityEntityId=${id}`,HEADERS)
        .then(res => {
            if(res.data.header.responseCode === 0){
                return res.data;
            }
            else{
                message.warning(res.data.header.message, 4);
            }
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 2));           
    }
    add(data){
        return axios.post(`${BASE_URL}${api}`, data, HEADERS)
        .then(res => {
           return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 2));
    }
    edit(data){
        return axios.put(`${BASE_URL}${api}`, data, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    delete(id){
        return axios.delete(`${BASE_URL}${api}/${id}`, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    getElement(id, size, page){
        return axios.get(`${BASE_URL}${api}/AssignElement?facilityEntityId=${id}&size=${size}&page=${page}`,HEADERS)
        .then(res => {
            if(res.data.header.responseCode === 0){
                return res.data;
            }
            else{
                message.warning(res.data.header.message, 4);
            }
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 2));         
    }    
    deleteUser(body){
        return axios.delete(`${BASE_URL}${api}/AssignUser`,{ data:  body  } ,HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    deleteSpecification(body){
        return axios.delete(`${BASE_URL}${api}/AssignSpecification`,{ data:  body  } ,HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    deleteElement(body){
        return axios.delete(`${BASE_URL}${api}/AssignElement`,{ data:  body  } ,HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    deleteEndNode( body){
        return axios.delete(`${BASE_URL}${api}/AssignDevice`,{ data:  body  } ,HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    assginToDevice(body){
        return axios.put(`${BASE_URL}${api}/AssignDevice`,body,HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));         
    }
    assginToSpecifications(body){
        return axios.put(`${BASE_URL}${api}/AssignSpecification`,body,HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));         
    }
    updateCoordinate(body){
        return axios.put(`${BASE_URL}${api}/UpdateCoordinate`,body,HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));         
    }
    assginToUser(body){
        return axios.put(`${BASE_URL}${api}/AssignUser`,body,HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));         
    }
    assginToElement(body){
        return axios.put(`${BASE_URL}${api}/AssignElement`,body,HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));         
    }
    deleteAllElement( body){
        return axios.delete(`${BASE_URL}${api}/RemoveElementFromAllEntities`,{ data:  body  } ,HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }

    deleteAllElementEntity( id){
        return axios.delete(`${BASE_URL}${api}/UnAssignAllElement?entityId=${id}`,HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    getEntityInsight(id){
        return axios.get(`${BASE_URL}user/FacilityEntityInsight/GetEntityInsight?code=${id}`,HEADERS)
        .then(res => {
            if(res.data.header.responseCode === 0){
                return res.data;
            }
            else{
                message.warning(res.data.header.message, 4);
            }
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 2));           
    }
    
    updateLocationType(body){
        return axios.put(`${BASE_URL}${api}/UpdateLocationType`,body,HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));         
    }
    getCoordinates(code){
        return axios.get(`${BASE_URL}${api}/GetCoordination?code=${code}`,HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));         
    }
    update2dLocation(body){
        return axios.put(`${BASE_URL}${api}/Location2D`,body,HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));         
    }
    getColor(code){
        return axios.get(`${BASE_URL}${api}/Color?code=${code}`,HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4)); 
    }
    updateColor(code, color){
        return axios.put(`${BASE_URL}${api}/Color?newColor=${color}`,[...code],HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));         
    }
    getIcon(code){
        return axios.get(`${BASE_URL}${api}/Icon?code=${code}`,HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4)); 
    }
    updateIcon(code, fileIcon){
        return axios.put(`${BASE_URL}${api}/Icon?fileIconId=${fileIcon}`,[...code],HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));         
    }
    deleteTwoDLocation(params){
        return axios.delete(`${BASE_URL}${api}/Location2D`,{
            ...HEADERS,
            params:{...params}
        })
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));         
    }
    getUsers(code){
        return axios.get(`${BASE_URL}${api}/GetUser?code=${code}`,HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4)); 
    }
    getAttachmet(body){
        return axios.post(`${BASE_URL}user/FacilityEntityAttachment/GetList`, body,HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4)); 
    }
    addAttachment(body){
        return axios.post(`${BASE_URL}user/FacilityEntityAttachment`, body,HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4)); 
    }
    uploadFileAttachment(body, config){
        return axios.post(`${BASE_URL}user/FacilityEntityAttachment/UploadFile`, body,{...HEADERS, ...config})
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4)); 
    }
    deleteFileAttachment(id){
        return axios.delete(`${BASE_URL}user/FacilityEntityAttachment/${id}`,Headers)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4)); 
    }
    editFileAttachment(body){
        return axios.put(`${BASE_URL}user/FacilityEntityAttachment`,body, Headers)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4)); 
    }
    
}

