import { BASE_URL, HEADERS } from './index';
import axios from 'axios';
import {message} from 'antd';

const api = "user/FacilityDevice"

const qs = require('querystring')

export default class DeviceService {
    getAll(id){
        return axios.get(`${BASE_URL}${api}/Getfiltered?facilityId=${id}`,HEADERS)
        .then(res => {
                return res.data;})
       
    }
    getById(id, facilityId){
        return axios.get(`${BASE_URL}${api}/Getfiltered?facilityId=${facilityId}&deviceId=${id}`,HEADERS)
        .then(res => {
            if(res.data.header.responseCode === 0){
                return res.data;
            }
            else{
                message.warning(res.data.header.message, 4);
            }
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 2));         
    }    
    add(data){
        return axios.post(`${BASE_URL}${api}`, data, HEADERS)
        .then(res => {
           return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 2));
    }
    edit(data){
        return axios.put(`${BASE_URL}${api}`, data, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    delete(id){
        return axios.delete(`${BASE_URL}${api}/${id}`, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    getVMQ(id){
        return axios.get(`${BASE_URL}${api}/GetVmqDetail?deviceId=${id}`,HEADERS)
        .then(res => {
            if(res.data.header.responseCode === 0){
                return res.data;
            }
            else{
                message.warning(res.data.header.message, 4);
            }
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 2));         
    }
    VmqRevokePassword(id){
        return axios.put(`${BASE_URL}${api}/VmqRevokePassword?deviceId=${id}`,HEADERS)
        .then(res => {
            if(res.data.header.responseCode === 0){
                return res.data;
            }
            else{
                message.warning(res.data.header.message, 4);
            }
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 2));         
    }

}

