import React, { useState } from "react";
import moment from 'moment';
import { FacilityEntityInsight } from 'services';
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis } from "recharts";
import IntlMessages from "util/IntlMessages";
import { Spin, Icon, Menu, Dropdown, Popconfirm, Divider } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import Alert from '../../../FacilityPage/Profile/Entity/EntityTab/Alert';
import Setting from '../../../FacilityPage/Profile/Entity/EntityTab/Setting';
import { injectIntl } from 'react-intl';
import AmtChart from '../../../FacilityPage/Profile/3DModel/amChartEntity';
import { useEntity } from "../../context";
import AccessLevel from '../../../FacilityPage/Profile/Entity/EntityTab/AccessLevel';
import { RiShieldUserLine } from 'react-icons/ri'

const InsightSV = new FacilityEntityInsight();

function Index({ intl, lastValue, name, lastValueDate, semanticUnitSymbol, semanticUnitName, getItems,
    label, accessLevel, getDomainExpertiseEntityInsightModels,
    semanticName, id }) {
    //hooks
    const { entity } = useEntity();
    //state
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [itemsChart, setItemsChart] = useState(undefined);
    const [openChart, setOpenChart] = useState(false);
    const [openSetting, setOpenSetting] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [openAccessLevel, setOpenAccessLevel] = useState(false);

    //const
    const deleteInsight = () => {
        InsightSV.delete(id)
            .then(res => {
                if (res.header && res.header.responseCode === 0) {
                    getItems();
                }
            })
            .catch(err => console.log(err))
    }
    const menuInsightMore = () => {
        return (
            <Menu>

                <Menu.Item onClick={() => setOpenAlert({
                    facilityId: entity.data.facilityId,
                    insightId: id, label: label, symbol: semanticUnitSymbol
                })}>
                    <i className="icon icon-alert" />
                    &nbsp;{intl.formatMessage({ id: "label.alerts" })}
                </Menu.Item>
                {entity.data.isManager && <Menu.Item onClick={() => setOpenAccessLevel({
                    "id": id,
                    facilityId: entity.data.facilityId,
                    "accessLevel": accessLevel,
                    "domainExpertiseIds": getDomainExpertiseEntityInsightModels
                })}>
                    <RiShieldUserLine />
                    &nbsp;{intl.formatMessage({ id: "label.accessLevel" })}
                </Menu.Item>}
                {entity.data.isManager && <Menu.Item >
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={(e) => { deleteInsight() }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <div style={{ width: "100%" }}><Icon type="delete" key="delete" />&nbsp;<IntlMessages id="more.item.delete" /></div>
                    </Popconfirm>
                </Menu.Item>}
                {entity.data.isManager && <Menu.Item onClick={() => setOpenSetting(id)}>
                    <SettingOutlined />{intl.formatMessage({ id: "label.setting" })}
                </Menu.Item>}



            </Menu>)
    }
    const CustomTooltip = ({ active, payload, label }) => {
        if (active) {
            return (
                <div className="custom-tooltip" style={{ backgroundColor: "rgba(255,255,255,0.7)", border: "1px solid #999999", borderRadius: "5px", padding: "5px", height:"65px" }}>
                    <p className="intro" style={{ color: "#fa8817", opacity: 1 }}>{payload ? payload[0].payload.value : null}</p>
                    <p className="label" style={{ color: "#10316b", opacity: 1 }}>{payload ? new Date(payload[0].payload.dateTime).toLocaleString() : null}</p>
                </div>
            );
        }

        return null;
    };
    //functions

    const getItemsChart = () => {
        setLoading(true);
        console.log("open")
        let bodyString = "timeRange=h6&granularityDuration=15&isContinuous=true&queryType=lastvalue";
        InsightSV.facilityEntityInsightValue(id, bodyString, "AmazingSnapshotGet")
            .then((res) => {
                if (res.data && res.data.list) {
                    setItemsChart(res.data.list.map((newDtaEl) => {
                        return {
                            dateTime: newDtaEl.endDate,
                            value: newDtaEl.outputValue
                        }
                    }))
                }

                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            })

    }

    const openItem = async () => {
        if (entity.data.isFacilityMember) {
            if (!open) {
                await getItemsChart();
            }
            await setOpen(!open);
        }


    }
    return (
        <div className="insight-card">
            {openChart && <AmtChart visible={openChart} setVisible={setOpenChart} />}
            {openAlert && <Alert name={name} visible={openAlert} setVisible={setOpenAlert} />}
            {openSetting && <Setting visible={openSetting} setVisible={setOpenSetting} />}
            {openAccessLevel && <AccessLevel visible={openAccessLevel} setVisible={setOpenAccessLevel} />}

            <div className="header-card" onClick={openItem}>
                <div className="information">
                    <div className="values-data">
                        <div className="value">
                            {lastValue}&nbsp;{semanticUnitSymbol}
                        </div>
                        <div className="date-time">
                            {moment(lastValueDate).isValid() && moment(lastValueDate).format('MM/DD/YYYY, HH:mm')}
                        </div>
                    </div>
                    <div className="names">
                        <div>{name}</div>
                    </div>
                </div>
                <div className="more-actions">

                </div>
            </div>
            {open && <div className="content-card">
                <div className="acions-icons">
                    <div className="acions-icons-item"
                        onClick={() => setOpenChart({
                            insightName: name,
                            name,
                            entityName: entity.data.name,
                            id,
                            semanticUnitSymbol,
                            lastValue

                        })}
                    >
                        <IntlMessages id="btn.chart" />
                    </div>
                    <div className="divider-more-container">
                        <div className="divider-more" />
                    </div>
                    <div className={`acions-icons-item ${!entity.data.isManager && "disabled"}`}>

                        <Dropdown overlay={menuInsightMore} trigger={['click']}>
                            <a style={{ color: "unset" }}><IntlMessages id="btn.more" /></a>
                        </Dropdown>
                    </div>
                </div>
                <Spin spinning={loading}>
                    <div className="last-chart">
                        {itemsChart ? itemsChart.length > 0 ? <ResponsiveContainer width="100%" height={100}>
                            <AreaChart data={itemsChart}
                                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                                <Tooltip content={<CustomTooltip />} />
                                <XAxis dataKey='value' allowDecimals />
                                <defs>
                                    <linearGradient id="color15" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#38AAE5" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#F5FCFD" stopOpacity={0.8} />
                                    </linearGradient>
                                </defs>
                                <Area dataKey='value' strokeWidth={2} stackId="2" stroke='#10316B' fill="url(#color15)"
                                    fillOpacity={1} />
                            </AreaChart>
                        </ResponsiveContainer> :
                            <div className="no-data-in-chart-dashboard" style={{ textAlign: "center" }}>
                                <IntlMessages id="label.dashboard.noData.attribute.cahart" />
                            </div> : <div className="no-data-in-chart-dashboard" />}
                    </div>
                </Spin>
            </div>}
        </div>
    )
}


export default injectIntl(Index);