import React, { useState, useEffect } from 'react';
import {withRouter, Link} from 'react-router-dom';
import IntlMessages from "util/IntlMessages";
import {Spin, Icon, message} from 'antd'; 
import Helmet from 'react-helmet';
import {Account} from '../services';
import { injectIntl  } from 'react-intl'


const service = new Account();

function Index (props){
    const [loading, setLoading] = useState(false);
    const [data, setData]= useState(false);
    
    useEffect(()=>{
        setLoading(false);
        service.checkResetPassword(props.match.params.id, props.match.params.token)
        .then(res=>{
            if(res){
                setData(res.data);
                if(res.header.metaResponse!==null){
                  message[res.header.responseCode===0?"success":"warning"](props.intl.formatMessage({ id: res.header.metaResponse }))
              }
            }
        })
    },[])
    
    return(
        <div className="gx-app-login-wrap">
        <Helmet>
          <title>twinup | Check Reset Password</title>
        </Helmet>
        <div className="gx-app-login-container">
        <Spin spinning ={loading}>

          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
              </div>
              <div className="gx-app-logo-wid">
                <h1><IntlMessages id="app.userAuth.resetPassword"/></h1>
              </div>
              <div className="gx-app-logo">
                <img alt="example" src={require("assets/images/logo.png")}/>
              </div>
            </div>

            <div className="gx-app-login-content">
                {
                    data?
                    <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                        <div><Icon style={{fontSize:"35px"}} type="check-circle" theme="twoTone" twoToneColor="#52c41a" /></div><div><IntlMessages id="app.userAuth.messageResetPassword"/></div> 
                    </div>:
                    <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                        <div><Icon style={{fontSize:"35px"}} type="exclamation-circle" theme="twoTone" twoToneColor="red" /></div><div> <IntlMessages id="app.userAuth.messageResetPassword"/></div>
                    </div>
                }
                <div style={{display:"flex",justifyContent:"center", alignItems:"center", height:"50px"}}>
                <Link to="/signin"><IntlMessages
                  id="app.userAuth.signIn"/></Link>
                </div>
                
            </div>
          
          </div>
        </Spin>
        </div>
      </div>
    )

}

export default injectIntl(withRouter(Index));

