import {GET_NOTIFICATION} from "../../constants/ActionTypes";

const INIT_STATE = {
  items:{}

};

export default (state = INIT_STATE, action) => {
  switch (action.type) {  
    case GET_NOTIFICATION: {
      return {...state, items: action.payload};
    }

    default:
      return state;
  }
}
