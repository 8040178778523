import React from "react";
import {Route, Switch} from "react-router-dom";
import { Popover, Button, Input } from 'antd';

import asyncComponent from "util/asyncComponent";
const { TextArea } = Input;
const content = (
  <div>
    <div>
    Intellica: What can I do for you?
    </div>
    <br/>
    <TextArea rows={2} />
    <br/>
    <Button type="primary"  style={{ marginTop:"10px"}} block>Send</Button>
  </div>
); 
console.log("route loaded run viewer")
const App = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}dashboard`} component={asyncComponent(() => import('./Dashboard'))}/>
      <Route path={`${match.url}profile`} component={asyncComponent(() => import('./Profile'))}/>
      <Route path={`${match.url}facility/:id`} component={asyncComponent(() => import('./FacilityPage'))}/>
      <Route path={`${match.url}viewer/:id`} component={asyncComponent(() => import('./FacilityPage/Profile/3DModel'))}/>
      <Route path={`${match.url}calendar`} component={asyncComponent(() => import('./Calendar'))}/>
      <Route path={`${match.url}ticket`} component={asyncComponent(() => import('./Todo'))}/>
      <Route path={`${match.url}facility`} component={asyncComponent(() => import('./Facility'))}/> 
      <Route path={`${match.url}notifications`} component={asyncComponent(() => import('./Notifications'))}/>   
      <Route path={`${match.url}devices`} component={asyncComponent(() => import('./Devices'))}/>
      <Route path={`${match.url}joinfacility`} component={asyncComponent(() => import('./JoinFacility'))}/>

    </Switch>
    {/* <div title="Intellica">
    <Popover content="Hi, I'm Intellica">
    <Popover style={{background:"red"}} content={content} trigger="click">
      <img onClick={()=>console.log("test")} src={require('../assets/images/Intellica.png')} width="70px" className="fixed-logo" alt=""/>
      </Popover>
    </Popover>
    </div> */}
  </div>
);

export default App;
