import React from "react";
import AmCharts from "@amcharts/amcharts3-react";

const LineChartWithScrollAndZoom = (props) => {
  const config = {
    "type": "serial",
    "theme": "light",
    "marginRight": 80,
    "autoMarginOffset": 20,
    "marginTop": 7,
    "dataProvider": props.items,
    "valueAxes": [{
      "axisAlpha": 0.2,
      "dashLength": 1,
      "position": "left"
    }],
    "mouseWheelZoomEnabled": true,
    "graphs": [{
      "id": "g1",
      "balloonText": "Date:[[date]]<br> Value:[[value]]",
      "bullet": "round",
      "bulletBorderAlpha": 1,
      "bulletColor": "#FFFFFF",
      "hideBulletsCount": 50,
      "title": "red line",
      "valueField": "value",
      "useLineColorForBulletBorder": true,
      // "balloon": {
      //   "drop": true
      // }
    }],
    "chartScrollbar": {
      "autoGridCount": true,
      "graph": "g1",
      "scrollbarHeight": 40
    },
    "chartCursor": {
      "limitToGraph": "g1"
    },
    "categoryField": "date",
    "categoryAxis": {
      "minPeriod": "mm",
      "parseDates": true,
      "axisColor": "#DADADA",
      "dashLength": 1,
      "minorGridEnabled": true
    },
    "export": {
      "enabled": true,
      "fileName": `${props.nameFilesAmtChart}`,
    }
  };

  return (
    <div className="App attribute-amchart">
      <AmCharts.React style={{width: "100%", height: "500px"}} options={config}/>
    </div>
  )
}

export default LineChartWithScrollAndZoom;
