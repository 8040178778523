import React, { useEffect, useState } from 'react';
import { Form, Input, Modal, Spin, Button, Row, Col, Radio, message, Select } from 'antd4';
import { injectIntl } from 'react-intl'
import { PlusOutlined } from '@ant-design/icons';
import { EntityService, EndNodeService, SpecificationService } from 'services';
import IntlMessages from "util/IntlMessages";


const formItemLayout = {
  wrapperCol: { span: 24 },
  layout: 'vertical'
};
const responsiveMode = {
  xs: { span: 24 },
  sm: { span: 24 },
  md: { span: 24 },
  lg: { span: 24 },
  xl: { span: 24 },
  xxl: { span: 24 },
}

const servicesEntity = new EntityService();
const EndNodeSV = new EndNodeService();
const SpecificationSV = new SpecificationService();

function Index({ visible, setVisible, getItems, facilityEntityId, intl, facilityId }) {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [endNodes, setEndNodes] = useState([]);
  const [type, setType]= useState("device");
  const [specifications, setSpecifications] = useState([]);

  const onFinish = values => {
    setLoading(true);
    
    let body = values;
    if(type === "device"){
      if(body.specificationIds){
        delete body.specificationIds;
      }
    }else{
      delete body.nodeIds;
    }
    servicesEntity[type === "device" ? "assginToDevice":"assginToSpecifications"](body)
      .then(res => {
        if (res.header && res.header.responseCode === 0) {
          getItems();
          setVisible(false);
        }
        if (res.header.metaResponse !== null) {
          message[res.header.responseCode === 0 ? "success" : "warning"](intl.formatMessage({ id: res.header.metaResponse }))

        }
        setLoading(false)
      })
      .catch(err => setLoading(false))

  }
  const getSpecification = (id) =>{
    form.setFieldsValue({
      specificationIds:[]
    });
    SpecificationSV.getEndNodeId(id)
      .then(res => {
        if (res && res.data) {
          setSpecifications(res.data.map(el => { return { label: el.aliasName || el.variableLable, value: el.id } }));
         
        }
      })
      .catch(err => {
        
      })
  }
  const getEndNodes = () => {
    setLoading(true);
    EndNodeSV.getEndNodeByFacilityId(facilityId)
      .then(res => {
        if (res && res.data) {
          setEndNodes(res.data.map(el => { return { label: el.name, value: el.id } }).sort((a, b) => a.label.localeCompare(b.label)));
          // if (visible) {
          //   form.setFieldsValue({
          //     facilityEntityId: facilityEntityId,
          //     nodeIds: visible
          //   });
          // }

          // eslint-disable-next-line
        }
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
      })
  }


  useEffect(() => {
    getEndNodes();
    
  }, []);
  useEffect(()=>{
    if(type === "specification"){
      form.setFieldsValue({
        facilityEntityId: facilityEntityId,
        nodeIds: [],
        specificationIds:[]
      });
    }else{
      form.setFieldsValue({
        facilityEntityId: facilityEntityId,
        nodeIds: [],
      });
    }
  },[type])
  return (
    <Modal
      visible={visible}
      title={<IntlMessages id="label.addAttribute" />}

      footer={false}
      onCancel={() => setVisible(false)}
    >
      <Spin spinning={loading}>
        <Form className="new-from" name="add" form={form} {...formItemLayout} onFinish={onFinish}>
          <Row>
            <Form.Item name="facilityEntityId" style={{ display: "none" }} />
              <Radio.Group defaultValue="device" onChange={(e)=> setType(e.target.value)}>
                <Radio.Button value="device">{intl.formatMessage({ id: "label.assignDevices" })}</Radio.Button>
                <Radio.Button value="specification">{intl.formatMessage({ id: "label.assignAttributes" })}</Radio.Button>
              </Radio.Group>
              <div style={{lineHeight:"40px", color:"gray"}}>{type === "device" ? intl.formatMessage({ id: "label.assignToDeviceNote" }):
              intl.formatMessage({ id: "label.assignToAttributes" })}</div>
            <Col {...responsiveMode}>
              <Form.Item
                label={type === "device"?intl.formatMessage({ id: "label.selectDevices" }):intl.formatMessage({ id: "label.selectDevice" })}
                name="nodeIds"
                rules={[{ required: true, message: intl.formatMessage({ id: "form.required" }) }]}

              >
                <Select
                  showSearch
                  options={endNodes}
                  mode={type === "device"?"multiple":"default"}
                  style={{ width: "100%", marginTop: "15px" }}
                  placeholder={type === "device"?"Select devices":"Select device"}
                  optionFilterProp="label"
                  onSelect={(e)=>type === "device"? null : getSpecification(e)}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  } />
              </Form.Item>
            </Col>
            {type === "specification" &&
            <Col {...responsiveMode}>
            <Form.Item
              label={intl.formatMessage({ id: "label.selectAttributes" })}
              name="specificationIds"
              rules={[{ required: true, message: intl.formatMessage({ id: "form.required" }) }]}

            >
              <Select
                showSearch
                disabled={specifications.length === 0}
                options={specifications}
                mode={"multiple"}
                style={{ width: "100%", marginTop: "15px" }}
                placeholder="Select attributes"
                optionFilterProp="label"
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                } />
            </Form.Item>
          </Col>
            }
            <Form.Item>
              <Button type="primary" block form="add" key="submit" htmlType="submit">
                {intl.formatMessage({ id: "button.assign" })}
              </Button></Form.Item>
          </Row>
        </Form>
      </Spin>
    </Modal>
  )
}

export default injectIntl(Index)