import React, { useEffect, useState } from 'react';
import { Modal, Button, Descriptions, Spin } from 'antd';
import { AlertService } from 'services';
import IntlMessages from "util/IntlMessages";
import moment from 'moment';

const AlertSV = new AlertService();

const logicTypeConst = [">", "<", "=", ">", ">"];
function Alert(props) {
  const { visiable, setVisible } = props;
  const [item, setItem] = React.useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    AlertSV.getLog(visiable.facilityAlertLogId)
      .then(res => {
        if (res.data) {
          setItem(res.data)
        }
        setLoading(false);
      })
      .catch(err => setLoading(false))
  }, [])
  return (
    <Modal
      title="Alert"
      visible={visiable}
      footer={null}
      onCancel={() => props.setVisiable(false)}
    >
      <Spin spinning={loading}>
        <Descriptions column={24} bordered size={"small"} >
          <Descriptions.Item span={24} label={<div><IntlMessages id="label.facilityName" /></div>}>{item.facilityName}</Descriptions.Item>
          <Descriptions.Item span={24} label={<div ><IntlMessages id="label.alertName" /></div>}>{item.name}</Descriptions.Item>
          <Descriptions.Item span={24} label={<div><IntlMessages id="label.date" /></div>}>{moment(visiable.registerDate).format('MM/DD/YYYY, HH:mm')}</Descriptions.Item>
          <Descriptions.Item span={24} label={<div ><IntlMessages id="label.logic" /></div>}>{item.logicDescription}</Descriptions.Item>
          <Descriptions.Item span={24} label={<div ><IntlMessages id="label.alertNotification" /></div>}>{item.description}</Descriptions.Item>
          <Descriptions.Item span={24} label={<div ><IntlMessages id="label.triggerFirstValue" /></div>}>{item.triggerFirstValue}</Descriptions.Item>
          <Descriptions.Item span={24} label={<div ><IntlMessages id="label.lastValueFirst" /></div>}>{item.firstValue}</Descriptions.Item>
          {item.triggerSeccondValue !== null &&
            <Descriptions.Item span={24} label={<div ><IntlMessages id="label.triggerSeccondValue" /></div>}>{item.triggerSeccondValue}</Descriptions.Item>}
          {item.seccondValue !== null &&
            <Descriptions.Item span={24} label={<div ><IntlMessages id="label.lastValueSecond" /></div>}>{item.seccondValue}</Descriptions.Item>}
          <Descriptions.Item span={24} label={<div ><IntlMessages id="label.entities" /></div>}>{item.entities && item.entities.map((el)=>{
            return(<div>{el}</div>)
          })}</Descriptions.Item>

        </Descriptions>
      </Spin>
    </Modal>
  );
}

export default Alert;