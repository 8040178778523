import React, { useRef, useState, useEffect } from "react";

import { Progress } from "antd";
import { useEntity } from "../../routes/EntityPanel/context";
import IntlMessages from "util/IntlMessages";
import { UploadOutlined, FileOutlined, DeleteOutlined } from '@ant-design/icons';
import { EntityService, FacilityEntityInsight, DomainService } from 'services';

const service = new EntityService();

const Index = ({ onChange, error, defaultValue }) => {
    //hooks
    const { entity } = useEntity();
    const refFilesPost = useRef();
    //state
    const [filesUpload, setFilesUpload] = useState([]);
    const [progressBar, setProgressBar] = useState(0);
    const [errorLoad, setErrorLoad] = useState(false);
    //function    


    console.log(filesUpload, "filesupload")
    const uploadFile = (e) => {
        if (e.target.files && e.target.files[0]) {
            let fileUpload = e.target.files[0];
            setProgressBar(1)
            const config = {
                onUploadProgress: function (progressEvent) {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    setProgressBar(percentCompleted)
                },
            }
            var formData = new FormData();
            formData.append('FacilityEntityCode', entity.data.code);
            formData.append('file', e.target.files[0]);
            service.uploadFileAttachment(formData, config)
                .then(res => {
                    let newArr = filesUpload;
                    if (res.data) {
                        setProgressBar(100);
                        newArr = newArr.concat([{ id: res.data, data: fileUpload, src: URL.createObjectURL(fileUpload) }]);
                        onChange(newArr)
                        setFilesUpload(newArr);
                    }
                    refFilesPost.current.value = "";
                })
                .catch(err => {
                    console.log(err)
                    setProgressBar(0);
                })
        }

    }
    const hasImage = (type) => {
        const imagesTypes = ['png', 'jpg', 'jpeg'];
        if (imagesTypes.filter(el => type.toUpperCase().search(el.toUpperCase()) > -1).length) {
            return "image";
        }
        return "";
    }
    const deleteFile = (id)=>{
        let newArr = filesUpload.filter(el=>el.id !== id);
        setFilesUpload(newArr);
        onChange(newArr)
    }
 
    
    useEffect(()=>{
        if(defaultValue){
            setFilesUpload(defaultValue)
        }
    },[])
    return (
        <div className="upload-div" >
            <input ref={refFilesPost} type="file" onChange={uploadFile} style={{ display: "none" }} />
            {filesUpload && filesUpload.map((fi, index) => {
                if (hasImage(fi.data.type) === "image") {
                    return (
                        <div className="file-loader-uploades">
                            <img src={fi.src} style={{ objectFit: "contain" }} width="50px" height="50px" alt="file" />
                            <div className="name-file">
                                {fi.data.name}
                            </div>
                            <div className="delete-file" onClick={()=>deleteFile(fi.id)}>
                                <DeleteOutlined/>
                            </div>
                        </div>
                    )
                }
                return (
                    <div className="file-loader-uploades">
                        <div className="icon-file"><FileOutlined style={{ fontSize: "18px" }} /></div>
                        <div className="name-file">{fi.data.name}</div>
                        <div className="delete-file"  onClick={()=>deleteFile(fi.id)}>
                            <DeleteOutlined/>
                        </div>
                    </div>
                )
            })}
            <div className="add-new-file-upload" onClick={() => refFilesPost.current.click()}>
                {(progressBar === 100 || progressBar === 0) ? <div
                    className="upload-one-file">
                    <div >
                        <UploadOutlined style={{ fontSize: "18px" }} />
                    </div>
                    <div >
                        <IntlMessages id="upload" />
                    </div>
                </div> :
                    <Progress
                        width={90}
                        type="circle"
                        percent={progressBar} />}
            </div>

        </div>

    )
}

export default Index;