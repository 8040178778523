
const INSIGHTS = [
    {
        insightType: "ACH",
        label: "ACH-Steady State"
    },
    // {
    //     insightType: "ACHInverted",
    //     label: "Occupancy Limit"
    // }, 
    {
        insightType: "PPDLightClothes",
        label: "PPD"
    },
    {
        insightType: "PPDHeavyClothes",
        label: "PPD"
    },
    {
        insightType: "IdleTime",
        label: "Idle Time"
    },
    {
        insightType: "MaxOccupancy",
        label: "Max Occupancy"
    },
    {
        insightType: "PPDAdvance",
        label: "PPD Advanced"
    },
    {
        insightType: "TemperatureDeviation",
        label: "Temperature Deviation"
    },
    {
        insightType:"ACHDynamicState",
        label:"ACH-Dynamic State "
    }
]

const SEMANTICS_UNITS = "SEMANTICS_UNITS";

export {
    INSIGHTS,
    SEMANTICS_UNITS
}