import React, { useEffect, useState } from "react";
import IntlMessages from 'util/IntlMessages';
import { PlusOutlined, EditOutlined, QrcodeOutlined, EllipsisOutlined, SearchOutlined, DeleteOutlined, BgColorsOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { Button, Row, Col, Input, Checkbox, Spin, Select, Pagination, Container } from "antd4";
import { Popover } from 'antd';
import { useEntity } from '../../context';
import {RiseOutlined, FallOutlined, FilterOutlined } from '@ant-design/icons';
import Item from './File';
import { responsiveMode, responsiveModeAuthor, responsiveModePagination, responsiveModeSort, responsiveModeAdd, responsiveModeSearch } from '../../responsiveMode';
import FileCard from './FileCard';
import axios from 'util/Api';
import {RiEqualizerLine} from 'react-icons/ri';

const { Option } = Select;

function Index() {
    //hook
    const { entity } = useEntity();
    //state
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false)
    const [users, setUsers] = useState(false);
    const [selectUsers, setSelectUsers] = useState([]);
    const [items, setItems] = useState([]);
    const [sort, setSort] = useState("isAscDateTrue");
    const [openItem, setOpenItem] = useState(false);
    const [query, setQuery] = useState({
        "facilityEntityCode": "",
        "userIds": [],
        "type": "file",
        "searchTerm": "",
        "isAscDate": false,
        "size": 10,
        "page": 1
    });
    const [total, setTotal] = useState(0);
    const onChangeAuther = (e) => {
        setSelectUsers(e);
    }
    //dropdown
    //functions
    const getUsers = () => {
        entity.service.getUsers(entity.data.code)
            .then((res) => {
                if (res.data) {
                    setUsers(res.data.map((el) => (
                        {
                            label: (el.firstName + " " + el.lastName), value: el.id
                        }
                    )))
                }
            })
            .catch(err => {

            })


        // /api/user/FacilityEntity/GetUser
    }
    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };
    const getItems = (params) => {
        if (localStorage.getItem("user")) {
            setLoading(true);
            entity.service.getAttachmet({
                ...params
            })
                .then(res => {
                    if (res.data) {
                        console.log(res.data)
                        setItems(res.data.list)
                        setTotal(res.data.total)
                        setQuery({ ...params, page: 1 })
                    }
                    setLoading(false);
                })
                .catch(err => {
                    setLoading(false);
                })
        } else {

            axios.post('GetEntityAttachment', params)
                .then(res => {

                    if (res.data) {
                        setItems(res.data.data.list)
                        setTotal(res.data.data.total)
                        setQuery({ ...params, page: 1 })
                    }
                    setLoading(false);
                })
                .catch(err => {
                    setLoading(false);
                })

        }

    }
    const changeSort = (e) => {
        setSort(e)
        switch (e) {
            case "isAscDateTrue":
                getItems({ ...query, isAscDate: true, isAscTitle: undefined })
                break;
            case "isAscDateFalse":
                getItems({ ...query, isAscDate: false, isAscTitle: undefined })
                break;
            case "isAscTitleTrue":
                getItems({ ...query, isAscDate: undefined, isAscTitle: true })
                break;
            case "isAscTitleFalse":
                getItems({ ...query, isAscDate: undefined, isAscTitle: false })

                break;
            default:
                break;
        }
    }
    //effect

    useEffect(() => {
        if (entity.data && entity.data.code) {
            if (localStorage.getItem("user")) {
                getUsers();
            }
            getItems({
                "facilityEntityCode": entity.data.code,
                "userIds": [],
                "type": "file",
                "searchTerm": "",
                "isAscDate": true,
                "size": 10,
                "page": 1
            })
        }
    }, [entity])
    return (
        <div className="attachment-tabs">
            {openItem && <Item visible={openItem} setVisible={setOpenItem} getItems={() => getItems(query)} />}
            <Row gutter={[15, 5]}>
                {entity.data && (entity.data.isEntityMember || entity.data.isManager) && <Col  {...responsiveModeAdd(entity.type)}>
                    <Button
                        style={{ width: "180px" }}
                        onClick={() => setOpenItem(true)}
                        icon={<PlusOutlined />}
                        type="primary"
                    >
                        &nbsp;<IntlMessages id="addFile" />
                    </Button>
                </Col>}
                <Col
                    {...responsiveModeSearch(entity.type)}
                >
                    <div className="input-search">
                        <Input
                            placeholder="Search in files"
                            allowClear={true}
                            onChange={(e) => { getItems({ ...query, searchTerm: e.target.value }) }}
                            suffix={<SearchOutlined />} />
                    </div>
                </Col>
                {localStorage.getItem("user") && <Col {...responsiveModeAuthor(entity.type)}>
                    <Popover
                        onVisibleChange={handleOpenChange}
                        visible={open}
                        content={<>
                            <div>
                                <Checkbox.Group
                                    // options={users}
                                    defaultValue={selectUsers}
                                    onChange={onChangeAuther}
                                >
                                    <Row gutter={[10,10]}>
                                        {users && users.map((user => (
                                            <Col span={24}>
                                                <Checkbox value={user.value}>{user.label}</Checkbox>

                                            </Col>
                                        )))}
                                    </Row>
                                </Checkbox.Group>
                            </div>
                            <div style={{ display: "flex", justifyContent: "flex-start", columnGap: "10px" }}>
                                <Button type="primary" size="small"
                                    onClick={() => { getItems({ ...query, userIds: selectUsers }); handleOpenChange(false) }}
                                ><IntlMessages id="apply" /></Button>
                                <Button size="small"
                                    onClick={() => handleOpenChange(false)}
                                ><IntlMessages id="close" /></Button>
                            </div>

                        </>} placement="bottom" trigger="click">
                        <Button 
                        // className="center-btn"
                        icon={<FilterOutlined/>}
                        block onClick={() => handleOpenChange(true)}>
                           &nbsp; <IntlMessages id="author" /></Button>
                    </Popover>


                </Col>}
                <Col {...responsiveModeSort(entity.type)}>
                    <div className="filter-styles">
                        <Select defaultValue={sort}
                            onChange={(e) => changeSort(e)}
                        >
                            <Option value={"isAscDateTrue"}>
                            <RiseOutlined/>&nbsp; <IntlMessages id="isAscDate" />                                
                            </Option>
                            <Option value={"isAscDateFalse"}>
                            <FallOutlined/> &nbsp;<IntlMessages id="isDescDate" />
                                
                            </Option>
                            <Option value={"isAscTitleTrue"}>
                            <RiseOutlined/>&nbsp;<IntlMessages id="isAscTitle" />
                            </Option>
                            <Option value={"isAscTitleFalse"}>
                            <FallOutlined/> &nbsp;<IntlMessages id="isDescTitle" />
                            </Option>
                        </Select>
                    </div>


                </Col>
                {/* <Col {...responsiveModeSort(entity.type)}>
                    <div className="filter-styles">
                        <Select defaultValue={query.isAscTitle}
                            onChange={(e) => getItems({ ...query, isAscTitle: e })}
                        >
                            <Option value={true}>
                                <IntlMessages id="isAscTitle" />
                            </Option>
                            <Option value={false}>
                                <IntlMessages id="isDescTitle" />
                            </Option>
                        </Select>
                    </div>


                </Col> */}
                <Col
                justifyContent="flex-end"
                    {...responsiveModePagination(entity.type)}
                >
                    <div className="pagination-styles">
                        {total} Files
                        {total > 5 && <Pagination
                            size="small"
                            onChange={(e) => getItems({ ...query, page: e })}
                            total={total}
                            simple
                            pageSize={5}
                            defaultCurrent={1}
                        />}
                    </div>

                </Col>
            </Row>

            <Spin spinning={loading}>
                <Row gutter={[15, 15]} wrap={false}>
                    {items && items.map((item, index) => (
                        <Col key={index}  {...responsiveMode(entity.type)}>
                            <FileCard {...item} getItems={() => getItems(query)} />
                        </Col>
                    ))}
                </Row>
            </Spin>

        </div>
    )
}

export default Index;