import React from 'react';
import { Modal, Button, Spin, Descriptions } from 'antd';
import { TicketService } from 'services';
import { Link } from 'react-router-dom';
import IntlMessages from "util/IntlMessages";
import moment from 'moment';


const TicketSV = new TicketService();

function Ticket(props) {
    
  const {visiable, setVisible} = props;
    const [item, setItem] = React.useState(false);

    const getEvent = () =>{
      TicketSV.getById(visiable.facilityTicketId)
      .then(res => {
        if(res && res.data){
            setItem(res.data)
        }
      })
    }
    React.useEffect(()=> {
      getEvent();
    },[])
    return (
        <Modal
          title="Ticket"
          visible={visiable}
          footer={[
            <Link to={"/ticket?id="+item.id}>
              <Button>Go to ticket</Button>
            </Link>
          ]}
          onCancel={() => props.setVisiable(false)}
        >
          <Spin spinning={item === false}>
              <Descriptions column={24} bordered size={"small"} >
                <Descriptions.Item span={24} label={<div><IntlMessages id="label.facilityName"/></div>}>{item.facilityName}</Descriptions.Item>
                <Descriptions.Item span={24} label={<div><IntlMessages id="label.ticketName"/></div>}>{item.title}</Descriptions.Item>
                <Descriptions.Item span={24} label={<div><IntlMessages id="label.createdBy"/></div>}>{item.firstName+" "+item.lastName}</Descriptions.Item>
                <Descriptions.Item span={24} label={<div><IntlMessages id="label.dueDate"/></div>}>{item.dueDate?moment(item.dueDate).format('MM/DD/YYYY, HH:mm'):""}</Descriptions.Item>

              </Descriptions>              
          </Spin>
        </Modal>
    );
}

export default Ticket;