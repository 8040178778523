import React from 'react';
import { Collapse } from 'antd4';
import IntlMessages from 'util/IntlMessages';
import Attributes from './Attributes';
import Insights from './Insights';
import { useEntity } from '../../context';
import {GrRefresh} from 'react-icons/gr';

const { Panel } = Collapse;

function Index(props) {
    //hooks
    const {getInfo } = useEntity();

    return (
        <div className='entity-panel-content-tab'>
            <div className='refresh-action' onClick={() => getInfo()}>
            <GrRefresh/>&nbsp;<IntlMessages id="refresh" />
            </div>
            <Collapse defaultActiveKey={['1', '2']} ghost>
                <Panel header={<IntlMessages id="entityPanel.insights" />} key="1">
                    <Insights />
                </Panel>
                <Panel header={<IntlMessages id="entityPanel.attributes" />} key="2">
                    <Attributes />
                </Panel>
            </Collapse>
        </div>

    )
}


export default Index;