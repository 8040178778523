import React, { useRef, useState, useEffect } from "react";

import { Progress } from "antd";
import { useEntity } from "../../routes/EntityPanel/context";
import IntlMessages from "util/IntlMessages";
import { UploadOutlined, FileOutlined, DeleteOutlined } from '@ant-design/icons';
import { EntityService, FacilityEntityInsight, DomainService } from 'services';

const service = new EntityService();

const Index = ({ onChange, error }) => {
    //hooks
    const { entity } = useEntity();
    const refFilesPost = useRef();
    //state
    const [fileRender, setFileRender] = useState(false);
    const [file, setFile] = useState([]);
    const [progressBar, setProgressBar] = useState(0);
    const [errorLoad, setErrorLoad] = useState(false);
    //function    



    const uploadFile = (e) => {
        if (e.target.files && e.target.files[0]) {
            setProgressBar(1)
            const config = {
                onUploadProgress: function (progressEvent) {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    setProgressBar(percentCompleted)
                },
            }
            setFile(e.target.files[0]);
            setFileRender(URL.createObjectURL(e.target.files[0]))
            var formData = new FormData();
            formData.append('FacilityEntityCode', entity.data.code);
            formData.append('file', e.target.files[0]);
            service.uploadFileAttachment(formData, config)
                .then(res => {
                    console.log(res.data, "data")
                    if (res.data) {
                        setProgressBar(100);
                        onChange(res.data)
                    }

                })
                .catch(err => {
                    setProgressBar(0);
                })
        }

    }
    console.log(file.type, "file")
    return (
        <div className="upload-div" onClick={() => refFilesPost.current.click()}>
            <input ref={refFilesPost} type="file" onChange={uploadFile} style={{ display: "none" }} />
            {(progressBar === 100 || progressBar === 0) ? fileRender ?
                (file.type && file.type.search("image") > -1) ?
                    <>
                        <div className="delete-file-one"
                            style={{marginTop:"20px"}}
                            onClick={() => { }}>
                            <IntlMessages id="replaceFile" />
                        </div>
                        <img src={fileRender} style={{ objectFit: "contain", cursor: "pointer" }} width="100%" height="100%" alt="file" />

                    </> :
                    <>
                        <div className="delete-file-one"

                            onClick={() => { }}>
                            <IntlMessages id="replaceFile" />
                        </div>
                        <div style={{ display: 'flex', columnGap: "10px", fontWeight: 500, cursor:"pointer" }}>
                            <FileOutlined style={{ fontSize: "18px" }} />
                            {file.name}
                        </div>
                    </>
                : <div
                    style={{ borderColor: error ? "red" : "" }}
                    className="upload-one-file">
                    <div >
                        <UploadOutlined style={{ fontSize: "18px" }} />
                    </div>
                    <div>
                        <IntlMessages id="upload" />
                    </div>
                </div> :
                <Progress
                    type="circle"
                    percent={progressBar} />}
        </div>

    )
}

export default Index;