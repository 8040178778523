import { BASE_URL, HEADERS } from './index';
import axios from 'axios';
import {message} from 'antd';


export default class Dashboard {
    getDashboard(){
        return axios.get(`${BASE_URL}user/Dashboard/GetNotifications`,HEADERS)
          .then(res => {
            if(res.data.header.responseCode === 0){
                return res.data;
            }
            else{
                message.warning(res.data.header.message, 4);
            }
        }) 
          .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4)); 
      }
      getFileIcons(params){
        return axios.get(`${BASE_URL}user/FileIcon`,{params: params},HEADERS)
        .then(res => {
          return res.data
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));         
    }
    unPin(id){
        return axios.delete(`${BASE_URL}user/Dashboard/UnPinFromDashboard?id=${id}`, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    unPinEntityInsight(id, insightId){
        return axios.delete(`${BASE_URL}user/Dashboard/UnPinEntityInsightGraphFromDashboard?id=${id}&insightId=${insightId}`, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    unPinEntitySemantic(id, semanticId){
        return axios.delete(`${BASE_URL}user/Dashboard/UnPinEntitySemanticGraphFromDashboard?id=${id}&semanticId=${semanticId}`, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    getPins(){
    return axios.get(`${BASE_URL}user/Dashboard/GetPins`,HEADERS)
        .then(res => {
        if(res.data.header.responseCode === 0){
            return res.data;
        }
        else{
            message.warning(res.data.header.message, 4);
        }
    }) 
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4)); 
    }
   getNotifications(size, page, query){
    return axios.get(`${BASE_URL}user/Notification?size=${size}&page=${page}${query?query:""}`,HEADERS)
        .then(res => {
                if(res.data.header.responseCode === 0){
                    return res.data;
                }
                else{
                    message.warning(res.data.header.message, 4);
                }
            }) 
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4)); 
    }
    readNotifications(items, all){
        return axios.put(`${BASE_URL}user/Notification/ReadNotification?ReadAll=${all}`, items, HEADERS)
            .then(res => {
                    if(res.data.header.responseCode === 0){
                        return res.data;
                    }
                    else{
                        message.warning(res.data.header.message, 4);
                    }
                }) 
            .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4)); 
        }
    deleteNotifications(items, all){
        return axios.delete(`${BASE_URL}user/Notification?DeleteAll=${all}`, { data:  items  }, HEADERS)
            .then(res => {
                    if(res.data.header.responseCode === 0){
                        return res.data;
                    }
                    else{
                        message.warning(res.data.header.message, 4);
                    }
                }) 
            .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4)); 
        }
}
