import React from 'react';
import QRCode from 'qrcode.react';


function Index(props) {
    // #183680
    let url = `https://twinup.ai/entitypanel/livedata/${props.item && props.item.code}`;

    return <QRCode
        size={props.size}
        fgColor="#183680"
        value={url} renderAs="svg" />
}

export default Index;