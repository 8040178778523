import React, { useState, useEffect } from 'react';
import {
    Modal, Button, Radio, Steps, Row,
    Col, Form, Input, Icon, Checkbox, message, Select, Spin, Alert
} from 'antd';
import IntlMessages from "util/IntlMessages";
import { EndNodeService, AlertService, SemanticService, DomainService, FacilityEntityInsight, EntityService } from 'services';
import { injectIntl } from 'react-intl';
import { SortFunction } from 'constants/Functions';
import { SEMANTICS_UNITS } from 'util/constants';

const EndNodeSV = new EndNodeService();
const AlertSV = new AlertService();
const SemanticSV = new SemanticService();
const DomainSV = new DomainService();
const InsightSV = new FacilityEntityInsight();
const EntitySV = new EntityService();


const { Option } = Select;
const FormItem = Form.Item;


const { Step } = Steps;
const CheckboxGroup = Checkbox.Group;
const logicTypeConst = ["gT", "lT", "eQ", "cP", "rG"];

const accessLevel = ["all", "managersOnly", "occupantsOnly", "managersAndDomainExperts", "managersAndOccupants"];


function Edit(props) {
    const { visible, setVisible, name, label } = props;
    const [plainOptions, setPlainOptions] = useState([])
    const [loading, setLoading] = useState(false);
    const [insights, setInsights] = useState([]);
    const [semantics, setSemantics] = useState([]);
    const [endNodes, setEndNodes] = useState([]);
    const [domains, setDomains] = useState([]);
    const [firstAttributes, setFirstAttributes] = useState([]);
    const [seccondAttributes, setSeccondAttributes] = useState([]);
    const [alertType, setAlertType] = useState("device")
    const [symbol, setSymbol] = useState(visible.mapFirstSemanticUnitSymbol);

    const [alert, setAlert] = useState({
        "id": visible.id,
        "name": visible.name,
        "description": visible.description,
        "logicType": visible.logicType,
        "isActionHighlightEnabled": visible.isActionHighlightEnabled,
        "isActionEmailEnabled": visible.isActionEmailEnabled,
        "facilityDeviceEndNodeId": visible.facilityDeviceEndNodeId,
        "facilityDeviceEndNodeSeccondId": visible.facilityDeviceEndNodeSeccondId,
        "firstValue": visible.firstValue,
        "insightLibraryId": visible.insightLibraryId,
        "semanticId": visible.semanticId,
        "seccondValue": visible.seccondValue,
        "firstAttribute": visible.firstAttribute,
        "seccondAttribute": visible.seccondAttribute,
        "accessLevel": visible.accessLevel,
        "domainExpertiseIds": visible.experties.map(el => { return el.id })

    })
    useEffect(() => {
        getDomains();
        getSemantics();
        getEndNode();
        getInsights();
        if (visible.alertType === "OnInsight" || visible.alertType === "OnBulkInsight") {
            setAlertType('insight');
        } else if (visible.alertType === "OnSemantic") {
            setAlertType('semantic');
        } else {
            setAlertType('device');
        }
        if (alert.facilityDeviceEndNodeId !== 0) {
            changeDevice('facilityDeviceEndNodeId', alert.facilityDeviceEndNodeId);
        }
        if (alert.facilityDeviceEndNodeSeccondId) {
            changeDevice('facilityDeviceEndNodeSeccondId', alert.facilityDeviceEndNodeSeccondId);
        }
    }, [])
    const getInsights = () => {
        EntitySV.getAll(props.id)
            .then(res => {
                if (res && res.data) {
                    let newArr = [];
                    res.data.forEach(el => {
                        newArr = newArr.concat(el.insights.map(i => { return { value: i.id, label: (el.name + " | " + i.name) } }))
                    }, newArr)
                    setInsights(SortFunction(newArr, 'label'))
                }
            })
    }
    const getSemantics = () => {
        SemanticSV.getByFacilityId(props.id)
            .then(res => {
                if (res && res.data) {
                    setSemantics(res.data)
                }
            })
    }
    const [current, setCurrent] = useState(0);

    const getEndNode = () => {
        EndNodeSV.getEndNodeByFacilityId(props.id)
            .then(res => {
                if (res && res.data) {
                    setEndNodes(res.data)
                }
            })
    }
    const handleSubmitAccess = (e) => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                setCurrent(4);
            }
        })
    }
    const getDomains = () => {
        DomainSV.getAll(props.id)
            .then(res => {
                if (res && res.data) {
                    setDomains(res.data)
                }
            })
    }
    
    const changeDevice = (id, value) => {

        EndNodeSV.getAttributeByEndNode(value)
            .then(res => {
                if (res && res.data) {
                    if (id === "facilityDeviceEndNodeId") {
                        props.form.setFieldsValue({
                            firstAttribute: null,
                        });
                        setFirstAttributes(res.data);
                    } else {
                        props.form.setFieldsValue({
                            seccondAttribute: null,
                        });
                        setSeccondAttributes(res.data);
                    }
                }
            })
        onChangeAlert({ id, value })
    }
    const handleSubmitOne = (e) => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                // setAlertType(values['radio-group']);
                setCurrent(2);
            }
        })
    }
    const handleSubmitTwo = (e) => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                onChangeAlert({ id: "logicType", value: values['radio-group2'] })
                setCurrent(2);
            }
        })
    }
    const handleSubmitThree = (e) => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                setCurrent(3);
            }
        })
    }
    const addItem = (e) => {

        e.preventDefault();
        props.form.validateFields((err, values) => {
            // setLoading(true);
            var body =
            {
                "id": visible.id,
                "name": alert.name,
                "description": alert.description,
                "isActionHighlightEnabled": alert.isActionHighlightEnabled,
                "isActionEmailEnabled": alert.isActionEmailEnabled,
                "firstValue": parseFloat(alert.firstValue),
                "seccondValue": parseFloat(alert.seccondValue),
                "accessLevel": alert.accessLevel,
                "domainExpertiseIds": alert.domainExpertiseIds
            }
            AlertSV[alertType === "insight"? "editInsight" :"edit"](body)
                .then(res => {
                    setLoading(false);
                    if (res.header.metaResponse !== null) {
                        message[res.header.responseCode === 0 ? "success" : "warning"](props.intl.formatMessage({ id: res.header.metaResponse }))

                    }
                    props.setVisible(false);
                    props.getItems();
                })
                .catch(err => setLoading(false));
        })

    }

    const onChangeAlert = (e) => {
        setAlert({
            ...alert,
            [e.id]: e.value
        })
    }
    const renderStep = () => {
        var key = alert.logicType;
        if (alertType === "insight" && (key === "GT" || key === "LT" || key === "EQ" || key === "CP")) {
            return (
                <div>
                    <Form onSubmit={handleSubmitThree}>
                        <Row>
                            <Col span={12}>
                                <FormItem>
                                    {name}&nbsp;|&nbsp;{label}
                                </FormItem>

                                {/* <FormItem>
                           {getFieldDecorator('insightLibraryId', {  
                               initialValue: alert.insightLibraryId,     
                                rules: [{
                                    required:true,
                                    message: <IntlMessages id="form.insightLibraryId.error"/>,
                                    }],                            
                                })(
                                <Select style={{ width: "100%" }} placeholder="Select Insight" onChange={(e)=>onChangeAlert({id:"insightLibraryId", value:e})}>
                                    {
                                        insights.map((insight,index) => {
                                            return(
                                                <Option key={index} value={insight.id}>{insight.name}</Option>
                                            )
                                        })
                                    }
                                </Select>
                                )}
                           </FormItem>                             */}
                                { }
                            </Col>

                            <Col span={4}>
                                <div style={{ textAlign: "center", lineHeight: "50px" }}>
                                    {
                                        alert.logicType === "GT" || alert.logicType === "CP" ?
                                            <Icon type="right" />
                                            :
                                            alert.logicType === "LT" ?
                                                <Icon type="left" />
                                                :
                                                <Icon style={{ transform: "rotate(90deg)" }} type="pause" />
                                    }
                                </div>
                            </Col>
                            {alert.logicType === "CP" ?
                                <Col span={8}>
                                    <FormItem>
                                        {getFieldDecorator('facilityEntityInsightLibrarySeccondId', {
                                            initialValue: visible.facilityEntityInsightLibrarySeccondId,
                                            rules: [{
                                                required: true,
                                                message: <IntlMessages id="form.insightLibraryId.error" />,
                                            }],
                                        })(
                                            <Select disabled style={{ width: "100%" }} placeholder="Select Insight" onChange={(e) => onChangeAlert({ id: "facilityEntityInsightLibrarySeccondId", value: e })}>
                                                {
                                                    insights.map((insight, index) => {
                                                        return (
                                                            <Option key={index} value={insight.value}>{insight.label}</Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        )}
                                    </FormItem>
                                </Col>
                                :
                                <Col span={8}>
                                    <FormItem>
                                        {getFieldDecorator('firstValue', {
                                            initialValue: alert.firstValue.toString(),
                                            rules: [{
                                                required: true,
                                                pattern: "^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$",
                                                message: <IntlMessages id="form.value.error" />,
                                            }],
                                        })(
                                            <Input type="text" placeholder={`Enter Value ${symbol?`in ${symbol}`:""}`} onChange={(e) => onChangeAlert({ id: "firstValue", value: e.target.value })} />
                                        )}
                                    </FormItem>
                                </Col>
                            }

                        </Row>
                        <FormItem>
                            <Button onClick={() => setCurrent(current - 1)} ><IntlMessages id="button.back" /></Button>&nbsp;&nbsp;
                        <Button htmlType="submit" type="primary"><IntlMessages id="button.next" /></Button>
                        </FormItem>
                    </Form>
                </div>
            )
        }
        if (alertType === "insight" && key === "RG") {
            return (
                <div>
                    <Form onSubmit={handleSubmitThree}>
                        <Row>
                            <Col span={6}>
                                <FormItem>
                                    {getFieldDecorator('firstValue', {
                                        initialValue: alert.firstValue.toString(),
                                        rules: [{
                                            required: true,
                                            pattern: "^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$",
                                            message: <IntlMessages id="form.value.error" />,
                                        }],
                                    })(
                                        <Input type="text" placeholder={`Enter Value ${symbol?`in ${symbol}`:""}`} onChange={(e) => onChangeAlert({ id: "firstValue", value: e.target.value })} />
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={2} style={{ textAlign: "center", lineHeight: "50px" }}>
                                <Icon type="right" />
                            </Col>
                            <Col span={6}>
                                <FormItem>
                                    {name}&nbsp;|&nbsp;{label}
                                </FormItem>
                                {/* <FormItem>
                                    {getFieldDecorator('insightLibraryId', {
                                        initialValue: alert.insightLibraryId,
                                        rules: [{
                                            required: true,
                                            message: <IntlMessages id="form.insightLibraryId.error" />,
                                        }],
                                    })(
                                        <Select style={{ width: "100%" }} placeholder="Select Insight" onChange={(e) => onChangeAlert({ id: "insightLibraryId", value: e })}>
                                            {
                                                insights.map((insight, index) => {
                                                    return (
                                                        <Option key={index} value={insight.id}>{insight.name}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    )}
                                </FormItem> */}
                            </Col>
                            <Col span={2} style={{ textAlign: "center", lineHeight: "50px" }}>
                                <Icon type="right" />
                            </Col>
                            <Col span={6}>
                                <FormItem>
                                    {getFieldDecorator('seccondValue', {
                                        initialValue: alert.seccondValue.toString(),
                                        rules: [{
                                            required: true,
                                            pattern: "^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$",
                                            message: <IntlMessages id="form.value.error" />,
                                        }],
                                    })(
                                        <Input type="text" placeholder={`Enter Value ${symbol?`in ${symbol}`:""}`} onChange={(e) => onChangeAlert({ id: "seccondValue", value: e.target.value })} />
                                    )}
                                </FormItem>
                            </Col>

                        </Row>
                        <FormItem>
                            <Button onClick={() => setCurrent(current - 1)} ><IntlMessages id="button.back" /></Button>&nbsp;&nbsp;
                        <Button htmlType="submit" type="primary"><IntlMessages id="button.next" /></Button>
                        </FormItem>
                    </Form>
                </div>
            )
        }
    }

    const renderContent = () => {
        switch (current) {
            case 0:
                return (
                    <Row>
                        <Col span={24}>
                            <Form onSubmit={handleSubmitOne}>
                                <FormItem >
                                    {getFieldDecorator('name', {
                                        initialValue: alert.name,
                                        rules: [{
                                            required: true,
                                            message: <IntlMessages id="alert.newAlert.name" />,
                                        }],
                                    })(
                                        <Input onChange={(e) => onChangeAlert({ id: "name", value: e.target.value })} placeholder="name" />
                                    )}
                                </FormItem>
                                <FormItem >
                                    {getFieldDecorator('description', {
                                        initialValue: alert.description                                        
                                    })(
                                        <Input onChange={(e) => onChangeAlert({ id: "description", value: e.target.value })} placeholder="Notification message (optional)" />
                                    )}
                                </FormItem>
                                {/* <FormItem label={<IntlMessages id="alert.newAlert.alertType" />}>
                                    {getFieldDecorator('radio-group', {
                                        initialValue: alertType,
                                        rules: [{
                                            required: true,
                                            message: <IntlMessages id="alert.newAlert.alertType.input" />,
                                        }],
                                    })(
                                        <Radio.Group disabled id="type" name="type" defaultValue="" >
                                            <Radio value="device"><IntlMessages id="label.alertForDevice" /></Radio>
                                            <br />
                                            <Radio value="semantic"><IntlMessages id="label.alertForSemantic" /></Radio>
                                            <br />
                                            <Radio value="insight"><IntlMessages id="label.alertForInsight" /></Radio>
                                        </Radio.Group>
                                    )}
                                </FormItem> */}
                                <FormItem>
                                    <Button type="primary" htmlType="submit"><IntlMessages id="button.next" /></Button>
                                </FormItem>
                            </Form>

                        </Col>
                    </Row>
                )
            case 2:
                return (
                    <Row>
                        <Col span={24}>
                            <div>
                                {renderStep()}
                            </div>


                        </Col>
                    </Row>
                )
            case 3:
                return (
                    <Row>
                        <Col span={24}>
                            <Form onSubmit={handleSubmitAccess} id="three">
                                <FormItem >
                                    {getFieldDecorator('accessLevel', {
                                        initialValue: alert.accessLevel,
                                        rules: [{
                                            required: true, message: <IntlMessages id="form.addSpecification.accessLevel" />,
                                        }],
                                    })(
                                        <Select
                                            showSearch
                                            style={{ width: "100%" }}
                                            placeholder="Access Level"
                                            optionFilterProp="children"
                                            onChange={(v) => onChangeAlert({ id: "accessLevel", value: v })}
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            <Option value="All"><IntlMessages id="label.all" /></Option>
                                            <Option value="ManagersOnly"><IntlMessages id="label.managerOnly" /></Option>
                                            <Option value="OccupantsOnly"><IntlMessages id="label.occupantsOnly" /></Option>
                                            <Option value="ManagersAndOccupants"><IntlMessages id="label.managersAndOccupants" /></Option>
                                            <Option value="ManagersAndDomainExperts"><IntlMessages id="label.managersAndDomainExperts" /></Option>
                                        </Select>
                                    )}
                                </FormItem>
                                {
                                    alert.accessLevel === "ManagersAndDomainExperts" ?
                                        <FormItem >
                                            {getFieldDecorator('domainExpertiseIds', {
                                                initialValue: alert.domainExpertiseIds,
                                                rules: [{
                                                    required: true, message: <IntlMessages id="form.domainExpertiseIds.error" />,
                                                }],
                                            })(
                                                <Select
                                                    style={{ width: "100%" }}
                                                    placeholder="Domain Expertises"
                                                    optionFilterProp="children"
                                                    onChange={(v) => onChangeAlert({ id: "domainExpertiseIds", value: v })}
                                                    mode="multiple"
                                                >
                                                    {domains.map(domain => {
                                                        return (
                                                            <Option value={domain.id} label={domain.name}>
                                                                {domain.name}
                                                            </Option>
                                                        )
                                                    })


                                                    }
                                                </Select>
                                            )}
                                        </FormItem> : null
                                }
                                <FormItem>
                                    <Button onClick={() => setCurrent(current - 1)} ><IntlMessages id="button.prev" /></Button>&nbsp;&nbsp;
                                <Button htmlType="submit" type="primary"><IntlMessages id="button.next" /></Button>
                                </FormItem>
                            </Form>
                        </Col>
                    </Row>
                )
            case 4:
                return (
                    <Row>
                        <Col span={24}>
                            <Form onSubmit={addItem}>
                                <FormItem label={<IntlMessages id="label.selectAction" />}>
                                    {getFieldDecorator('checkbox-group', {
                                        initialValue: [alert.isActionEmailEnabled ? "isActionEmailEnabled" : null, alert.isActionHighlightEnabled ? "isActionHighlightEnabled" : null],
                                    })(
                                        <Checkbox.Group style={{ width: '100%' }}>
                                            <Checkbox value="isActionEmailEnabled" onChange={(e) => onChangeAlert({ id: "isActionEmailEnabled", value: e.target.checked })}><IntlMessages id="label.email" /></Checkbox>
                                            <Checkbox value="isActionHighlightEnabled" onChange={(e) => onChangeAlert({ id: "isActionHighlightEnabled", value: e.target.checked })}><IntlMessages id="label.highlight" /></Checkbox>
                                            <Checkbox disabled value="" onChange={(e) => onChangeAlert({ id: "", value: e.target.checked })}><IntlMessages id="label.textMessages" /></Checkbox>

                                        </Checkbox.Group>,
                                    )}
                                </FormItem>
                                <FormItem>
                                    <Button onClick={() => setCurrent(current - 1)} ><IntlMessages id="button.back" /></Button>&nbsp;&nbsp;
                                    <Button htmlType="submit" type="primary"><IntlMessages id="button.done" /></Button>
                                </FormItem>
                            </Form>

                        </Col>
                    </Row>
                )
            default:
                break;
        }
    }

    const { getFieldDecorator } = props.form;
    return (
        <Modal
            visible={visible}
            title={<IntlMessages id="alert.newAlert.titleModal" />}
            onCancel={() => setVisible(false)}
            maskClosable={false}
            footer={null}
            width={700}
        > <Spin spinning={loading}>
                <Row gutter={24} type="flex" justify="center">
                    <Col span={22}><>
                        <Steps size="small" current={current}>
                            <Step title={<IntlMessages id="label.type" />} />
                            <Step title={<IntlMessages id="label.logic" />} />
                            <Step title={<IntlMessages id="label.values" />} />
                            <Step title={<IntlMessages id="label.access" />} />
                            <Step title={<IntlMessages id="label.action" />} />
                        </Steps>
                        <br />
                        {
                            renderContent()
                        }
                    </>
                    </Col>
                </Row>
            </Spin>
        </Modal>
    )
}
const AddForm = Form.create()(Edit);
export default injectIntl(AddForm);
