import React from "react";
import {Button, Checkbox, Form, Input, Spin} from "antd";
import {Link} from "react-router-dom";

import {connect} from "react-redux";
import {userSignUp} from "../appRedux/actions/Auth";
import { injectIntl  } from 'react-intl'

import IntlMessages from "util/IntlMessages";
import Helmet from 'react-helmet';
import { Account } from '../services';
import {message} from 'antd';

const service = new Account();
const FormItem = Form.Item;

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        item:{
          email:"",
        },
        loading:false
    };
  }

  onChange=(e)=>{
    var item = this.state.item;
    item[e.target.id] = e.target.value;
    this.setState({item})
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
     if(!err){
       this.setState({loading: true});
       service.forgetPassword(this.state.item)
       .then(res=>{
        this.setState({loading: false});
         if(res){
          if(res.header.metaResponse!==null){
            message[res.header.responseCode===0?"success":"warning"](this.props.intl.formatMessage({ id: res.header.metaResponse }))
        }
          this.props.history.push('/signin')
         }
       })
       .catch(err=> console.log(err.response))
     }
    });
  };

  render() {
    const {getFieldDecorator} = this.props.form;
    return (
      <div className="gx-app-login-wrap">
        <Helmet>
          <title>twinup | Reset Password</title>
        </Helmet>
        <div className="gx-app-login-container">
        <Spin spinning ={this.state.loading}>

          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
                {/* <img src='https://via.placeholder.com/272x395' alt='Neature'/> */}
              </div>
              <div className="gx-app-logo-wid">
                <div style={{fontSize:"24px"}}><IntlMessages id="app.userAuth.resetPassword"/></div>
                {/* <p><IntlMessages id="app.userAuth.bySigning"/></p>
                <p><IntlMessages id="app.userAuth.getAccount"/></p> */}
              </div>
              <div className="gx-app-logo">
                <img alt="example" src={require("assets/images/logo.png")}/>
              </div>
            </div>

            <div className="gx-app-login-content">
              <Form onSubmit={this.handleSubmit} className="gx-signup-form gx-form-row0">
               
               
                <FormItem>
                  {getFieldDecorator('Email', {
                    rules: [{required: true, type:"email", message: <IntlMessages id="form.signup.email.input"/>}],
                  })(
                    <Input placeholder="Email Address" onChange={this.onChange}/>
                  )}
                </FormItem>                 
                <FormItem>
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    <IntlMessages id="app.userAuth.resetPassword"/>
                  </Button>
                  <span><IntlMessages id="app.userAuth.or"/></span> <Link to="/signin"><IntlMessages
                  id="app.userAuth.signIn"/></Link>
                </FormItem>
              </Form>
            </div>
          
          </div>
        </Spin>
        </div>
      </div>
    );
  }
}

const WrappedSignUpForm = Form.create()(ResetPassword);

const mapStateToProps = ({auth}) => {
  const {token} = auth;
  return {token}
};

export default injectIntl(connect(mapStateToProps, {userSignUp})(WrappedSignUpForm));
