import { BASE_URL, HEADERS } from './index';
import axios from 'axios';
import {message} from 'antd';

const api = "user/FacilityDeviceCommand";

export default class CommandService {
    getSetting(params){
        return axios.get(`${BASE_URL}${api}/GetCurrentSetting`,{
            params
        }, HEADERS)
        .then(res => {
            return res.data
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));         
    }
    GetSpecificationLastAttributeCommand(params){
        return axios.get(`${BASE_URL}${api}/GetSpecificationLastAttributeCommand`,{
            params
        }, HEADERS)
        .then(res => {
            return res.data
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));         
    }
    getCommandKeys(params){
        return axios.get(`${BASE_URL}${api}/GetDeviceCommandKeys`,{
            params
        }, HEADERS)
        .then(res => {
            return res.data
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));         
    }   
    getCommand(body){
        return axios.post(`${BASE_URL}${api}/GetCommands`,body, HEADERS)
        .then(res => {
            return res.data
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));         
    }  
    getAttributeCommand(body){
        return axios.post(`${BASE_URL}${api}/GetAttributeCommands`,body, HEADERS)
        .then(res => {
            return res.data
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));         
    }  
  
    addCommand(data){
        return axios.post(`${BASE_URL}${api}/AddCommand`, data, HEADERS)
        .then(res => {
            return res.data
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    addCommandWriteToAttribute(data){
        return axios.post(`${BASE_URL}${api}/AddCommandWriteToAttribute`, data, HEADERS)
        .then(res => {
            return res.data
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    bulkAddCommandWriteToAttribute(data){
        return axios.post(`${BASE_URL}${api}/BulkAddCommandWriteToAttribute`, data, HEADERS)
        .then(res => {
            return res.data
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    edit(data){
        return axios.put(`${BASE_URL}${api}`, data, HEADERS)
        .then(res => {
            return res.data
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
}

