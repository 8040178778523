import React, { useEffect, useState } from 'react';
import { Form, Input, Modal, Spin, Button, Row, Col, InputNumber, Select, Radio, message } from 'antd4';
import { injectIntl } from 'react-intl'
import { EntityGroupService, PropertyService, EndNodeService, FacilityEntityInsight } from 'services';
import { withRouter } from 'react-router-dom';

const formItemLayout = {
    wrapperCol: { span: 24 },
    layout: 'vertical'
};
const responsiveMode = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
    xl: { span: 24 },
    xxl: { span: 24 },
}

const services = new EntityGroupService();
const insightSV = new FacilityEntityInsight();
const endNodeSV = new EndNodeService();
const propertySV = new PropertyService();

function Index({ visible, setVisible, intl, location, getItems }) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [properties, setProperties] = useState([]);
    const [selectSource, setSelectSource] = useState(false);
    const [endNodes, setEndNodes] = useState([]);
    const onFinish = values => {
        setLoading(true);
        insightSV.editInsightRequirement(values)
            .then(res => {
                if (res && res.header.responseCode === 0) {
                    getItems();
                    setVisible(false);
                }
                if (res.header.metaResponse !== null) {
                    message[res.header.responseCode === 0 ? "success" : "warning"](intl.formatMessage({ id: res.header.metaResponse }))
                }
            })
            .catch(err => setLoading(false))
    }

    const getEndNodes = () => {
        setLoading(true);
        endNodeSV.getEndNodeBySemantic(location.pathname.split('/')[2], visible.semanticId, visible.entityId)
            .then(res => {
                if (res && res.data) {
                    setEndNodes(res.data.map(el => {
                        return {
                            label: el.facilityDeviceEndNodeName
                                + " | " + (el.aliasName || el.variableLable), value: el.specificationId
                        }
                    }));
                    
                }
                setLoading(false);
            })
            .catch(err => setLoading(false))
    }
    const getProperty = ()=>{
        setLoading(true);

        propertySV.getFilteredBySemantic({
            SemanticId: visible.semanticId,
            entityId: visible.entityId
        })
        .then((res)=>{
            if (res && res.data) {
                setProperties(res.data.map(el => {
                    return {
                        label: (el.propertyName +" | "+el.propertyValue), 
                            value: el.propertyId
                    }
                }));
            }
            setLoading(false);
        })
    }
    useEffect(() => {
        if (visible.id) {
            setSelectSource(visible.inputType)
            form.setFieldsValue(visible);
        }
        getEndNodes();
        getProperty();
    }, [])
    return (
        <Modal
            title={intl.formatMessage({ id: "label.editSource" })}
            visible={visible}
            footer={false}
            onCancel={() => setVisible(false)}
        >
            <Spin spinning={loading}>
                <Form className="new-from" name="add" form={form} {...formItemLayout} onFinish={onFinish}>
                    <Form.Item style={{ display: "none" }}
                        name="id"
                    ></Form.Item>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="inputType"
                            >
                                <Radio.Group defaultValue={visible.inputType} onChange={(e) => setSelectSource(e.target.value)} buttonStyle="solid">
                                    <Radio.Button value="ManualData">{intl.formatMessage({ id: "label.manual" })}</Radio.Button>
                                    <Radio.Button value="Device">{intl.formatMessage({ id: "label.device" })}</Radio.Button>
                                    <Radio.Button value="Property">{intl.formatMessage({ id: "label.property" })}</Radio.Button>

                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        {selectSource === "ManualData" ?
                            <Col {...responsiveMode}>
                                <Form.Item
                                    name="staticData"
                                >
                                    <Input placeholder="Enter value" />
                                </Form.Item>
                            </Col> :
                            selectSource === "Device"?
                            <>
                                <Col {...responsiveMode}>
                                    <Form.Item
                                        name="specificationId"
                                    >
                                        <Select
                                            placeholder="Select device"
                                            showSearch
                                            optionFilterProp="label"
                                            filterOption={(input, option) =>
                                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            options={endNodes} />
                                    </Form.Item>
                                </Col>
                            </>:
                            <>
                            <Col {...responsiveMode}>
                                <Form.Item
                                    name="propertyId"
                                >
                                    <Select
                                        placeholder="Select property"
                                        showSearch
                                        optionFilterProp="label"
                                        filterOption={(input, option) =>
                                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        options={properties} />
                                </Form.Item>
                            </Col>
                        </>
                        }


                    </Row>
                    <Form.Item>
                        <Button form="add" block type="primary" key="submit" htmlType="submit">
                            {intl.formatMessage({ id: "button.save" })}
                        </Button>
                    </Form.Item>

                </Form>
            </Spin>
        </Modal>
    )
}

export default injectIntl(withRouter(Index))