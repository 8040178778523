import React, { useState, useEffect } from 'react';
import { Table, message, Tag, Tabs , Badge, Spin, Button  } from 'antd';
import {FacilityService, DashboardService} from '../../services';
import {Link} from 'react-router-dom';
import IntlMessages from "util/IntlMessages";
import Alert from './Alert';
import Event from './Event';
import Ticket from './Ticket';
import moment from 'moment';
import {useIntl} from 'react-intl';

const DashboardSV = new DashboardService();
const service = new FacilityService();
const { TabPane } = Tabs;

function Index (props){
    const [moreData, setMoreData] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pageNumber,setPageNumber] = useState(1);
    const [showAlert, setShowAlert] = useState(false);
    const [showEvent, setShowEvent] = useState(false);
    const [showTicket, setShowTicket] = useState(false);
    
    const columns = [
        {
          title: 'Message',
          dataIndex: 'message',
          key: 'message',
        },
        {
          title: 'Date',
          dataIndex: 'date',
          key: 'date',
        },
      ];
      const onRowClick = (record) => {
        props.setOpenNotification();
        readNotificationOne([record.id])
        if(record.facilityTicketId !== 0 && !record.isDeleted){
          setShowTicket(record);
        }else
        if(record.facilityAlertId !== 0 && !record.isDeleted){
          setShowAlert(record);
        }else
        if(record.facilityEventId !== 0 && !record.isDeleted){
          setShowEvent (record);
        }
      }
      const readNotificationOne = (id) => {
        setLoading(true);

        DashboardSV.readNotifications(id, false)
        .then(res => {
          if(res && res.header && res.header.responseCode === 0){
            props.getDashboard();
            setLoading(false)
            if(res.header.metaResponse!==null){
              message[res.header.responseCode===0?"success":"warning"](useIntl().formatMessage({ id: res.header.metaResponse }))
          } 
          }else{
            props.getDashboard();
            setLoading(false);

          }
        })
        .catch(err =>{
          props.getDashboard();

          setLoading(false)})
      }   
      const readNotification = () => {
        setLoading(true);
        var x1 = props.items.alertItems.map((al)=>{return al.id});
        var x2 = props.items.ticketItems.map((al)=>{return al.id});
        var x3 = props.items.eventItems.map((al)=>{return al.id});
        var list = x1.concat(x2).concat(x3)
        DashboardSV.readNotifications(list,false)
        .then(res => {
          if(res && res.header && res.header.responseCode === 0){
            props.getDashboard();
            setLoading(false)

            if(res.header.metaResponse!==null){
              message[res.header.responseCode===0?"success":"warning"](useIntl().formatMessage({ id: res.header.metaResponse }))
          }
          }else{
            props.getDashboard();
            setLoading(false);

          }
        })
        .catch(err =>{
          props.getDashboard();

          setLoading(false)})
      }   
      
    return(
        <div style={{width:"300px"}}>
          {
          showAlert ? 
            <Alert visiable={showAlert} setVisiable={setShowAlert}/> 
          :null
        }
        {
          showEvent ? 
            <Event visiable={showEvent} setVisiable={setShowEvent}/> 
          :null
        }
        {
          showTicket ? 
            <Ticket visiable={showTicket} setVisiable={setShowTicket}/> 
          :null
        }
          <Spin spinning={loading}>
          <Tabs defaultActiveKey="alerts" size="small">
            <TabPane tab={<Badge count={props.items.alertCount} style={{background:"red", opacity:"0.8"}}>{<IntlMessages id="label.alerts"/>}</Badge>} key="alerts">
              {props.items.alertItems.sort((a,b)=>new Date(b.date) - new Date(a.date)).map((el)=>{
                return(
                  <div onClick={()=>onRowClick(el)} style={{borderBottom:"1px solid #92a2c8", marginBottom:"5px", cursor:"pointer", paddingBottom:"5px"}}>
                    <div>{el.message}</div>
                    <div style={{fontSize:"12px", color:"#999999"}}>{moment(el.date).format('YYYY-MM-DD HH:mm')}</div>
                  </div>
                )
              })}          
               <div style={{display:"flex", justifyContent:"center", alignItems:"center", color:"#0091EA"}}>                                 
                  <div style={{cursor:"pointer"}} onClick={readNotification}><IntlMessages id="notification.btnReadAll"/></div>
                </div>                
            </TabPane>
            <TabPane tab={<Badge count={props.items.eventCount} style={{background:"red", opacity:"0.8"}}>{<IntlMessages id="label.event"/>}</Badge>} key="events">
              {props.items.eventItems.sort((a,b)=>new Date(b.date) - new Date(a.date)).map((el)=>{
                  return(
                    <div onClick={()=>el.isDeleted?null: onRowClick(el)} style={{cursor:"pointer",borderBottom:"1px solid #92a2c8", marginBottom:"5px", paddingBottom:"5px"}}>
                      <div>{el.message}</div>
                      <div style={{fontSize:"12px", color:"#999999"}}>{new Date(`${el.date}`).toLocaleString()}</div>
                    </div>
                  )
                })} 
                 <div style={{display:"flex", justifyContent:"center", alignItems:"center", color:"#0091EA"}}>                                 
                  <div style={{cursor:"pointer"}} onClick={readNotification}><IntlMessages id="notification.btnReadAll"/></div>
                </div>
            </TabPane>
            <TabPane tab={<Badge count={props.items.ticketCount} style={{background:"red", opacity:"0.8", minWitdh:"15px !important", height:"15px", lineHeight:"15px"}}>{<IntlMessages id="label.tickets"/>}</Badge>} key="tikcets">
            {props.items.ticketItems.sort((a,b)=>new Date(b.date) - new Date(a.date)).map((el)=>{
                  return(
                    <div onClick={()=>onRowClick(el)} style={{cursor:"pointer",borderBottom:"1px solid #92a2c8", marginBottom:"5px", paddingBottom:"5px"}}>
                      <div>{el.message}</div>
                      <div style={{fontSize:"12px", color:"#999999"}}>{new Date(el.date).toLocaleString()}</div>
                    </div>
                  )
                })} 
                <div style={{display:"flex", justifyContent:"center", alignItems:"center", color:"#0091EA"}}>                                 
                  <div style={{cursor:"pointer"}} onClick={readNotification}><IntlMessages id="notification.btnReadAll"/></div>
                </div>
            </TabPane>
          </Tabs>  
          </Spin>
          
        </div>
    )
}

export default Index;