import React from "react";
import {Button, Checkbox, Form, Input, Spin, DatePicker, Select, Modal } from "antd";
import {Link} from "react-router-dom";
import moment from 'moment';
import {connect} from "react-redux";
import {userSignUp} from "../appRedux/actions/Auth";
import { injectIntl  } from 'react-intl'
import IntlMessages from "util/IntlMessages";
import Helmet from 'react-helmet';
import { Account } from '../services';
import {message} from 'antd';
import PrivacyPolicy from './Terms';


const service = new Account();
const FormItem = Form.Item;
const { Option } = Select;


class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        item:{
          FirstName:"",
          LastName:"",
          Email:"",
          Password :"",
          rePassword:"",
          birthDate:"",
          gender:""
        },
        openPrivacy: false,
        loading:false,
        confirmDirty:false
    };
  }
  onChange=(e)=>{
    var item = this.state.item;
    item[e.target.id] = e.target.value;
    this.setState({item})
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if(!err){
       this.setState({loading: true});       
       var body = this.state.item;
       delete body.rePassword;      
       body.birthDate = moment(values.birthDate);
       body.gender = values.gender;
       service.resgister(body)
       .then(res=>{
         if(res.data.header.responseCode === 0){
          service.login({Username:this.state.item.Email, Password:this.state.item.Password})
          .then(res=>{
            if(res.data.header.responseCode === 0){
              if(res.data.header.metaResponse!==null){
                message.success(this.props.intl.formatMessage({ id: res.data.header.metaResponse }))
              } 
              if(typeof(res.data.data) === "object" && res.data.data.token){
                localStorage.setItem("token", res.data.data.token);
                localStorage.setItem("user", JSON.stringify(res.data.data));
                localStorage.setItem("refreshToken", res.data.data.refreshToken);
                window.location.assign('/');
              }else{
                window.location.replace(`signin/${res.data.data}`);
              }
            }else{
              this.setState({loading: false})
              if(res.data.header.metaResponse!==null){
                message.warning(this.props.intl.formatMessage({ id: res.data.header.metaResponse }))
              } 
            }
          })
         }else{
          if(res.data.header.metaResponse!==null){
            message.warning(this.props.intl.formatMessage({ id: res.data.header.metaResponse }))
          } 
           this.setState({loading:false})
         }
       })
       .catch(err=>{
         this.setState({loading: false});
        });
     }
    });
  };
   validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['rePassword'], {force: true});
    }
    callback();
  }
  checkAccept = (rule, value, callback) => {
    const form = this.props.form;
    if(value){
      callback();
    }else{
      callback(<IntlMessages id="form.signup.accept"/>)
    }
    
  }
   compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value  && value !== form.getFieldValue('Password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  }

  componentDidUpdate() {
    if (this.props.token !== null) {
      this.props.history.push('/');
    }
  }

  render() {
    const {getFieldDecorator} = this.props.form;
    return (
      <div className="gx-app-login-wrap">
      {this.state.openPrivacy && <PrivacyPolicy form={this.props.form} visible={this.state.openPrivacy} setVisible={()=> this.setState({openPrivacy: false})}/>}
        <Helmet>
          <title>twinup | Sign Up</title>
        </Helmet>
        <div className="gx-app-login-container">
        <Spin spinning ={this.state.loading}>

          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
                {/* <img src='https://via.placeholder.com/272x395' alt='Neature'/> */}
              </div>
              <div className="gx-app-logo-wid">
                <h1><IntlMessages id="app.userAuth.signUp"/></h1>
                {/* <p><IntlMessages id="app.userAuth.bySigning"/></p>
                <p><IntlMessages id="app.userAuth.getAccount"/></p> */}
              </div>
              <div className="gx-app-logo">
                <img alt="example" src={require("assets/images/logo.png")}/>
              </div>
            </div>

            <div className="gx-app-login-content">
              <Form onSubmit={this.handleSubmit} className="gx-signup-form gx-form-row0">
                <FormItem>
                  {getFieldDecorator('FirstName', {
                    rules: [{
                      required: true, pattern:"^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$",message: <IntlMessages id="form.signup.firstName.input"/>
                    }],
                  })(
                    <Input placeholder="First Name" onChange={this.onChange}/>
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('LastName', {
                    rules: [{required: true,pattern:"^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$",message: <IntlMessages id="form.signup.lastName.input"/>}],
                  })(
                    <Input placeholder="Last Name" onChange={this.onChange}/>
                  )}
                </FormItem>
                {/* <FormItem>
                  {getFieldDecorator('birthDate', {rules: [{ type: 'object', required: true, message: <IntlMessages id="form.signup.birthDate"/> }]})(<DatePicker format='MM/DD/YYYY' placeholder="Birth date" style={{width:"100%"}}/>)}
                </FormItem> */}
                {/* <FormItem >
                    {getFieldDecorator('gender', {
                      rules: [{
                        required:true ,message: <IntlMessages id="form.signup.gender"/>,
                      }],
                    })(
                      <Select
                        style={{ width: "100%" }}
                        placeholder="Select Gender"
                      >                          
                      <Option value={'Male'}>
                          Male
                      </Option>
                      <Option value={'Female'}>
                          Female
                      </Option>
                      <Option value={'Other'}>
                          Other
                      </Option>
                      </Select>
                    )}
                </FormItem> */}
                <FormItem>
                  {getFieldDecorator('Email', {
                    rules: [{required: true, type:"email", message: <IntlMessages id="form.signup.email.input"/>}],
                  })(
                    <Input placeholder="Email Address" onChange={this.onChange}/>
                  )}
                </FormItem>                 
                <FormItem>
                  {getFieldDecorator('Password', {
                    rules: [{validator: this.validateToNextPassword},{required: true,min:7, message: <IntlMessages id="form.signup.password.input"/>}],
                  })(
                    <Input type="password" placeholder="Password" onChange={this.onChange}/>
                  )}
                </FormItem>               
              <FormItem >
                {getFieldDecorator('rePassword', {
                  rules: [
                    {validator: this.compareToFirstPassword}
                    ,{
                    required: true, message: <IntlMessages id="form.signup.retype.password"/>,
                  }],
                })(
                  <Input type="password" placeholder="Confirm Password" onChange={this.onChange}/>
                )}
              </FormItem>
                <FormItem>
                  {getFieldDecorator('accept', {
                    valuePropName: 'checked',
                    initialValue: false,
                    rules: [
                      {validator: this.checkAccept},
                      ],
                  })(
                    <Checkbox><IntlMessages id="app.userAuth.iAccept"/></Checkbox>
                  )}
                  <span onClick={()=> this.setState({openPrivacy: true})} className="gx-link gx-signup-form-forgot"><IntlMessages
                    id="app.userAuth.termAndCondition"/></span>
                </FormItem>
                <FormItem>
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    <IntlMessages id="app.userAuth.signUp"/>
                  </Button>
                  <span><IntlMessages id="app.userAuth.or"/></span> <Link to="/signin"><IntlMessages
                  id="app.userAuth.signIn"/></Link>
                </FormItem>
              </Form>
            </div>
          
          </div>
        </Spin>
        </div>
      </div>
    );
  }
}

const WrappedSignUpForm = Form.create()(SignUp);

const mapStateToProps = ({auth}) => {
  const {token} = auth;
  return {token}
};

export default injectIntl(connect(mapStateToProps, {userSignUp})(WrappedSignUpForm));
