/*eslint-disable no-undef*/

import React, { useEffect, useState } from 'react';
import { Form, Input, Modal, Spin, Button, Row, Col, message, Select, Upload } from 'antd4';
import { injectIntl } from 'react-intl'
import { PlusOutlined } from '@ant-design/icons';
import { SemanticService, SemanticUnitService, PropertyService } from 'services';
import IntlMessages from "util/IntlMessages";
import UploadFiles from 'components/UploadFiles/UploadFiles';
import { useEntity } from '../../context';

const semanticService = new SemanticService();
const semanticUnitService = new SemanticUnitService();
const propertyService = new PropertyService();

const { Option } = Select;

const formItemLayout = {
    wrapperCol: { span: 24 },
    layout: 'vertical'
};
const responsiveMode = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
    xl: { span: 24 },
    xxl: { span: 24 },
}

function Index({ visible, setVisible, intl, getItems }) {
    //hooks
    const [loading, setLoading] = useState(true);
    const [used, setUsed] = useState(false);

    const deleteItem = () => {
        setLoading(true)
        propertyService.delete(visible.id)
            .then(res => {
                if (res.header.metaResponse !== null) {
                    message[res.header.responseCode === 0 ? "success" : "warning"](intl.formatMessage({ id: res.header.metaResponse }))
                }
                if (res.header.responseCode === 0) {
                    getItems();
                }
                setLoading(false);
                setVisible(false);
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
            })

    }

    
    useEffect(()=>{
        propertyService.checkUsed({
            id:visible.id
        })
        .then(res=>{
            console.log(res.data)
            setUsed(res.data);
            setLoading(false);
        })
        .catch(err=>{
            setLoading(false);
        })
    },[])
    return (

        <Modal
            visible={visible}
            title={ <IntlMessages id="deleteProperty" /> }
            keyboard={false}
            footer={false}
            onCancel={() => setVisible(false)}
        >
            <Spin spinning={loading}>
                <div className='info-delete-property'>
                    {used ? <IntlMessages id="errorPropertyUsed"/>:<IntlMessages id="areYouSure"/>}
                </div>
                <div className='more-actions-delete-property'>
                    <Button type='danger' onClick={()=>deleteItem()}>
                    <IntlMessages id="delete"/>
                    </Button>
                    <Button type="ghost" onClick={()=>setVisible(false)}>
                        <IntlMessages id="close"/>
                    </Button>
                </div>
            </Spin>
        </Modal>

    )
}

export default injectIntl(Index)