import { BASE_URL, HEADERS } from './index';
import axios from 'axios';
import {message} from 'antd';

const api = "user/SemanticUnit"

export default class SemanticUnitService {
    getAll(){
        return axios.get(`${BASE_URL}${api}`,HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));         
    }
    getSemanticUnitMap(){
        return axios.get(`${BASE_URL}user/SemanticUnitMap`,HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));         
    }
    getSemanticUnitNodeMap(id){
        return axios.get(`${BASE_URL}user/SemanticUnit/GetNodeSemanticUnits?nodeId=${id}`,HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));         
    }
    editSemanticUnitMap(data){
        return axios.put(`${BASE_URL}user/SemanticUnitMap`, data, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    addUser(data){
        return axios.put(`${BASE_URL}${api}/AddUsers`, data, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    add(data){
        return axios.post(`${BASE_URL}${api}`, data, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    edit(data){
        return axios.put(`${BASE_URL}${api}`, data, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    delete(id){
        return axios.delete(`${BASE_URL}${api}/${id}`, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    getByFacilityId(id){
        return axios.get(`${BASE_URL}${api}/GetFacilitySemantic?facilityId=${id}`, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    getBySemanticId(id){
        return axios.get(`${BASE_URL}${api}?semanticId=${id}`, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    getSemanticsUnitsByModelNumber = (id, facilityId) =>{
        return axios.get(`${BASE_URL}${api}/GetModelNumberSemanticUnits?modelNumber=${id}&facilityId=${facilityId}`, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
}

