import React, { useState, useEffect } from 'react';
import { DashboardService, EntityService } from 'services';
import { Modal, Button, message, Spin } from 'antd';
import IntlMessages from "util/IntlMessages";
import { injectIntl } from 'react-intl';
import { SketchPicker } from 'react-color';

const DashboardSV = new DashboardService();
const EntitySV = new EntityService();


function Index({ visible, setVisible, getItems, intl }) {
    //state
    const [color, setColor] = useState("#32a881")
    const [loading, setLoading] = useState(false);
    //functions
    const saveColor = () => {
        setLoading(true);
        let codes = visible.codes ? visible.codes : [visible.code];
        EntitySV.updateColor(codes, color.replace("#", "%23"))
            .then(res => {
                if (res && res.header.responseCode === 0) {

                    setVisible(false);
                    getItems();
                }
                if (res.header.metaResponse !== null) {
                    message[res.header.responseCode === 0 ? "success" : "warning"](intl.formatMessage({ id: res.header.metaResponse }))
                }
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
            })
    }
    useEffect(() => {
        if (visible.color) {
            setColor(visible.color);
        }
    }, [])
    return (
        <Modal
            visible={visible}
            title={<IntlMessages id="label.selectColor" />}
            footer={false}
            onCancel={() => setVisible(false)}
            width="350px"
        >
            <Spin spinning={loading}>

                <div className='custom-color-picker' style={{ marginBottom: "15px", display: "flex", justifyContent: "center" }}>

                    <SketchPicker
                        color={color}
                        onChange={(e) => setColor(e.hex)}
                    />

                </div>

                <Button block type="primary" onClick={saveColor}>
                    <IntlMessages id="button.save" />
                </Button>

            </Spin>
        </Modal>
    )
}

export default injectIntl(Index)