import React, { Component } from "react";
import { connect } from "react-redux";
import { Avatar, Popover } from "antd";
import { userSignOut } from "appRedux/actions/Auth";
import { Link } from "react-router-dom";
import { BASE_IMG } from '../../services';
import IntlMessages from "util/IntlMessages";

class UserProfile extends Component {

  render() {
    const { authUser } = this.props;

    const userMenuOptions = (
      <ul className="gx-user-popover">
        <Link to="/profile"><li><IntlMessages id="btn.myAcoount" /></li></Link>
        <li onClick={() => this.props.userSignOut()}><IntlMessages id="btn.signout" />
        </li>
      </ul>
    );
    return (
      <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
        <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
          <div style={{ display: "flex" }}>
            <Avatar src={authUser && authUser.avatar !== "" && (BASE_IMG + authUser.avatar)} style={{ backgroundColor: "#038fde" }}
              className="gx-size-40 gx-pointer gx-mr-3" alt="">
              {authUser && authUser.firstName.charAt(0).toUpperCase()}{authUser && authUser.lastName.charAt(0).toUpperCase()}
            </Avatar>
              <div style={{ flex: 1, lineHeight: "40px", width: "120px", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }} className="gx-avatar-name">{authUser && authUser.firstName + " " + authUser.lastName}

              </div>
              <i style={{display:"flex", justifyContent:"center", alignItems:"center"}}
                className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
            </div>
        </Popover>
      </div>

      // <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      //   <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
      //     <div className="cutomizeProfileLogo" style={{float:"left"}}>{authUser ? authUser.firstName?authUser.firstName.charAt(0):"N":"N"}{authUser ? authUser.lastName?authUser.lastName.charAt(0):"N":"N"}</div>
      //     {/* <Avatar src={require('../../assets/images/profile.png')}
      //             className="gx-size-40 gx-pointer gx-mr-3" alt=""/> */}
      //     <span className="gx-avatar-name">{authUser ? authUser.firstName : "none"}<i
      //       className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/></span>
      //   </Popover>
      // </div>

    )

  }
}

const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return { authUser }
};

export default connect(mapStateToProps, { userSignOut })(UserProfile);
