import React, { useState, useEffect } from 'react';
import { DashboardService, EntityService } from 'services';
import { Modal, Button, message, Spin } from 'antd';
import IntlMessages from "util/IntlMessages";
import { injectIntl } from 'react-intl';


const DashboardSV = new DashboardService();
const EntitySV = new EntityService();


function Index({ visible, setVisible, getItems, intl }) {
    //state
    const [items, setItems] = useState([]);
    const [select, setSelect] = useState(false);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const getIcons = (query) => {
        setLoading(true);
        DashboardSV.getFileIcons(query)
            .then(res => {
                if (res && res.data && res.data.list) {
                    setItems(res.data.list);
                    setTotal(res.data.total)

                }
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
            })
    }
    const saveIcon = () => {
        setLoading(true);
        let codes = visible.codes ? visible.codes : [visible.code];

        EntitySV.updateIcon(codes, select)
            .then(res => {
                if (res && res.header.responseCode === 0) {

                    setVisible(false);
                    getItems();
                }
                if (res.header.metaResponse !== null) {
                    message[res.header.responseCode === 0 ? "success" : "warning"](intl.formatMessage({ id: res.header.metaResponse }))
                }
                setLoading(false);

            })
            .catch(err => {
                console.log(err);
                setLoading(false);
            })
    }
    useEffect(() => {
        setSelect(visible.iconId);
        getIcons({ pageSize: 999, pageNumber: 1, iconType: "sprite" });
    }, [])
    return (
        <Modal
            visible={visible}
            title={<IntlMessages id="label.selectIcon" />}
            footer={false}

            onCancel={() => setVisible(false)}
        >
            <Spin spinning={loading}>

                <div className="list-icons-modal">
                    {items && items.map((item, index) => {
                        return (
                            <div onClick={() => setSelect(item.id)} key={index} className={select === item.id ? "select-icon" : "unselect-icon"}>
                                <img src={item.storageLink} alt="icon" />
                                <div className="name">{item.name}</div>
                            </div>
                        )
                    })}
                </div>
                {total > 20 && <div>
                    <Button block type="link" onClick={() => getIcons({ pageSize: 9999, pageNumber: 1, iconType: "sprite" })} >
                        <IntlMessages id="btn.showAll" />
                    </Button>
                </div>}
                <Button block type="primary" onClick={saveIcon}>
                    <IntlMessages id="button.save" />
                </Button>

            </Spin>
        </Modal>
    )
}

export default injectIntl(Index)