import React, { useState } from "react";
import moment from 'moment';
import IntlMessages from "util/IntlMessages";
import { Spin, Icon, Menu, message, Dropdown, Popconfirm } from "antd";
import { DeleteOutlined, FileOutlined } from '@ant-design/icons';
import { injectIntl, FormattedDisplayName } from 'react-intl';
import { useEntity } from "../../context";
import Item from './Item';
import Delete from './Delete';

function Index({ accessLevel, description, id, name, registerDate, intl,
    valueDate, value, semanticUnitSymbol, semanticId, semanticUnitId, modifyDate,
    userModifierFirstname, userModifierLastname, getItems
}) {
    //hooks
    const { entity } = useEntity();
    //state
    const [openDelete, setOpenDelete] = useState(false);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openItem, setOpenItem] = useState(false);
    //const
    const menu = () => {
        return (
            <Menu>
                <Menu.Item>
                    <Popconfirm
                        title="Are you sure?"
                        okText="Yes"
                        cancelText="No"
                    >
                        <div style={{ width: "100%" }}><DeleteOutlined /> <IntlMessages id="label.delete" /></div>
                    </Popconfirm>

                </Menu.Item>
                <Menu.Item onClick={() => setOpenItem({
                    id,
                    semanticId,
                    semanticUnitId,
                    accessLevel,
                    description,
                    name,
                    value
                })}>
                    <Icon type="edit" />  <IntlMessages id="label.edit" />
                </Menu.Item>
            </Menu>
        )
    }

    //functions

    const hasImage = (type) => {
        const imagesTypes = ['png', 'jpg', 'jpeg'];
        if (imagesTypes.filter(el => type.toUpperCase().search(el.toUpperCase()) > -1).length) {
            return "image";
        }
        return "";
    }

    return (<Spin spinning={loading}>
        {openItem && <Item visible={openItem} setVisible={setOpenItem} getItems={getItems} />}
        {openDelete && <Delete visible={openDelete} setVisible={setOpenDelete} getItems={getItems} />}

        <div className="property-card"
            style={{
                borderTop: "4px solid #4268FF"
            }}>
            <div className="header-card" onClick={() => setOpen(!open)}>
                <div className="information">
                    <div className="values-data">
                        <div className="value">
                            {value}&nbsp;{semanticUnitSymbol && `(${semanticUnitSymbol})`}
                        </div>
                        <div className="date-time">
                            {moment(registerDate).isValid() && moment(registerDate).format('MM/DD/YYYY, HH:mm')}
                        </div>
                    </div>
                    <div className="names">
                        {name}
                    </div>
                </div>
                <div className="more-actions">

                </div>
            </div>
            {open && <div className="content-card">
                <div className="acions-icons">
                    <div className={`acions-icons-item ${!entity.data.isManager && "disabled"}`}
                        onClick={() => setOpenDelete({ id })}
                    ><IntlMessages id="btn.delete" /></div>

                    <div className="divider-more-container">
                        <div className="divider-more" />
                    </div>
                    <div className={`acions-icons-item ${!entity.data.isManager && "disabled"}`}
                        onClick={() => setOpenItem({
                            id,
                            semanticId,
                            semanticUnitId,
                            accessLevel,
                            description,
                            name,
                            value
                        })}
                    ><IntlMessages id="btn.edit" /></div>

                </div>
                {userModifierFirstname && <div className="modify-user">
                    <IntlMessages id="updatedBy" />&nbsp;{userModifierFirstname + " " + userModifierLastname}
                </div>}
                {modifyDate &&
                    <div className="date-time"> {moment(modifyDate).isValid() && moment(modifyDate).format('MM/DD/YYYY, HH:mm')}</div>}
                <div className="description">
                    {description}
                </div>

            </div>}
        </div>
    </Spin>
    )


}


export default injectIntl(Index);