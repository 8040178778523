

import { BASE_URL, HEADERS } from './index';
import axios from 'axios';
import { message } from 'antd';
import moment from 'moment';



const api = "user/Forge/"

export default class ForgeService {
    getFiles(params) {
        return axios.get(`${BASE_URL}${api}GetForgeFiles`, {...HEADERS, params})
            .then(res => {
                return res.data;
            })
            .catch(err => message.error(err.response ? err.response.statusText : "Network Error", 4));
    }
    getSpecifications(params) {
        return axios.get(`${BASE_URL}${api}GetSpecifications`, { params }, HEADERS)
            .then(res => {
                return res.data;
            })
            .catch(err => message.error(err.response ? err.response.statusText : "Network Error", 4));
    }
    download3DFile(id) {
        return axios.get(`${BASE_URL}${api}Download3DFile?id=${id}`, HEADERS)
            .then(res => {
                return res.data;
            })
            .catch(err => message.error(err.response ? err.response.statusText : "Network Error", 4));
    }
    name3DFile(params) {
        return axios.get(`${BASE_URL}${api}Get3DFilesName`, { ...params, ...HEADERS })
            .then(res => {
                return res.data;
            })
            .catch(err => message.error(err.response ? err.response.statusText : "Network Error", 4));
    }

    getFilesActive(id) {
        return axios.get(`${BASE_URL}${api}GetActiveForgeFiles?facilityId=${id}`, HEADERS)
            .then(res => {
                return res.data;
            })
            .catch(err => message.error(err.response ? err.response.statusText : "Network Error", 4));
    }
    playContour(body, config) {
        var a = moment(moment());//now
        var b = moment(body.times[0]);
        let url = a.diff(b, 'days') > 7 ? "PlayContourSnapShot" : "PlayContour";

        return axios.post(`${BASE_URL}${api}${url}`, body, { ...config, HEADERS })
    }
    playContourInsight(body, config) {
        var a = moment(moment());//now
        var b = moment(body.times[0]);
        let url = a.diff(b, 'days') > 7 ? "PlayInsightContourSnapShot" : "PlayInsightContour";

        return axios.post(`${BASE_URL}${api}${url}`, body, { ...config, HEADERS })
    }
    playAlert(body, config) {
        return axios.post(`${BASE_URL}${api}PlayAlerts`, body, { ...config, HEADERS })
    }

    UploadCameraViewThumbnail(body) {
        return axios.post(`${BASE_URL}${api}UploadCameraViewThumbnail`, body)
    }
    setAsDefaultView(viewId, facilityId) {
        return axios.put(`${BASE_URL}${api}CameraViewDefaultUpdate?cameraViewId=${viewId}&facilityId=${facilityId}`, null)
    }
    playAlertInsight(body, config) {
        return axios.post(`${BASE_URL}${api}PlayInsightAlerts`, body, { ...config, HEADERS })
    }
    deleteFile(id) {
        return axios.delete(`${BASE_URL}${api}Remove3DFile?id=${id}`, HEADERS)
            .then(res => {
                return res.data;
            })
            .catch(err => message.error(err.response ? err.response.statusText : "Network Error", 4));
    }
    assignElementAdvance(body) {
        return axios.put(`${BASE_URL}${api}AssignElementAdvance`, body, HEADERS)
            .then(res => {
                return res.data;
            })
            .catch(err => message.error(err.response ? err.response.statusText : "Network Error", 4));
    }
    transferAssignment(body) {
        return axios.post(`${BASE_URL}${api}TransferAssignment`, body, HEADERS)
            .then(res => {
                return res.data;
            })
            .catch(err => message.error(err.response ? err.response.statusText : "Network Error", 4));
    }
    assginToElement(body) {
        return axios.put(`${BASE_URL}${api}AssignElementToDevice`, body, HEADERS)
            .then(res => {
                return res.data;
            })
            .catch(err => message.error(err.response ? err.response.statusText : "Network Error", 4));
    }
    assginToDevice(body) {
        return axios.put(`${BASE_URL}${api}AssignElementToDevice`, body, HEADERS)
            .then(res => {
                return res.data;
            })
            .catch(err => message.error(err.response ? err.response.statusText : "Network Error", 4));
    }
    assginToUser(body) {
        return axios.put(`${BASE_URL}${api}AssignElementToUser`, body, HEADERS)
            .then(res => {
                return res.data;
            })
            .catch(err => message.error(err.response ? err.response.statusText : "Network Error", 4));
    }
    editInfo(body) {
        return axios.put(`${BASE_URL}${api}EditNoteElement`, body, HEADERS)
            .then(res => {
                return res.data;
            })
            .catch(err => message.error(err.response ? err.response.statusText : "Network Error", 4));
    }
    activeModel(id) {
        return axios.put(`${BASE_URL}${api}ActiveDeactive?fileId=${id}`, HEADERS)
            .then(res => {
                return res.data;
            })
            .catch(err => message.error(err.response ? err.response.statusText : "Network Error", 4));
    }
    getAlerts(id) {
        return axios.get(`${BASE_URL}${api}GetSemanticDeviceAlerts?facilityId=${id}`, HEADERS)
            .then(res => {
                return res.data;
            })
            .catch(err => message.error(err.response ? err.response.statusText : "Network Error", 4));
    }
    getAlertsInsights(id) {
        return axios.get(`${BASE_URL}${api}GetInsightAlerts?facilityId=${id}`, HEADERS)
            .then(res => {
                return res.data;
            })
            .catch(err => message.error(err.response ? err.response.statusText : "Network Error", 4));
    }
    getEndNodes(dbId, facilityId, facility3DFileId) {
        return axios.get(`${BASE_URL}${api}GetNodes?dbId=${dbId}&facilityId=${facilityId}&facility3DFileId=${facility3DFileId}`, HEADERS)
            .then(res => {
                return res.data;
            })
            .catch(err => message.error(err.response ? err.response.statusText : "Network Error", 4));
    }
    getInsight(dbId, facilityId, facility3DFileId) {
        return axios.get(`${BASE_URL}${api}GetElementInsights?dbId=${dbId}&facilityId=${facilityId}&facility3DFileId=${facility3DFileId}`, HEADERS)
            .then(res => {
                return res.data;
            })
            .catch(err => message.error(err.response ? err.response.statusText : "Network Error", 4));
    }
    getUsers(dbId, facilityId, facility3DFileId) {
        return axios.get(`${BASE_URL}${api}GetUsers?dbId=${dbId}&facilityId=${facilityId}&facility3DFileId=${facility3DFileId}`, HEADERS)
            .then(res => {
                return res.data;
            })
            .catch(err => message.error(err.response ? err.response.statusText : "Network Error", 4));
    }
    getInfo(dbId, facilityId, facility3DFileId) {
        return axios.get(`${BASE_URL}${api}GetElementInfo?dbId=${dbId}&facilityId=${facilityId}&facility3DFileId=${facility3DFileId}`, HEADERS)
            .then(res => {
                return res.data;
            })
            .catch(err => message.error(err.response ? err.response.statusText : "Network Error", 4));
    }
    deleteUser(body) {
        return axios.delete(`${BASE_URL}${api}RemoveUserElement`, { data: body }, HEADERS)
            .then(res => {
                return res.data;
            })
            .catch(err => message.error(err.response ? err.response.statusText : "Network Error", 4));
    }
    deleteEndNode(body) {
        return axios.delete(`${BASE_URL}${api}RemoveNodeElement`, { data: body }, HEADERS)
            .then(res => {
                return res.data;
            })
            .catch(err => message.error(err.response ? err.response.statusText : "Network Error", 4));
    }
    deletePurge(id, body) {
        return axios.delete(`${BASE_URL}${api}PurgeModel?facilityId=${id}`, { data: body }, HEADERS)
            .then(res => {
                return res.data;
            })
            .catch(err => message.error(err.response ? err.response.statusText : "Network Error", 4));
    }
    getCamera(params) {
        return axios.get(`${BASE_URL}${api}CameraView`,{...HEADERS,params:params})
            .then(res => {
                return res.data;
            })
            .catch(err => message.error(err.response ? err.response.statusText : "Network Error", 4));
    }
    getCameraDefault(facilityId) {
        return axios.get(`${BASE_URL}${api}CameraView?facilityId=${facilityId}&isDefaultFilter=true`, HEADERS)
            .then(res => {
                return res.data;
            })
            .catch(err => message.error(err.response ? err.response.statusText : "Network Error", 4));
    }
    addCamera(body) {
        return axios.post(`${BASE_URL}${api}CameraView`, body, HEADERS)
            .then(res => {
                return res.data;
            })
            .catch(err => message.error(err.response ? err.response.statusText : "Network Error", 4));
    }
    editCamera(body) {
        return axios.put(`${BASE_URL}${api}CameraView`, body, HEADERS)
            .then(res => {
                return res.data;
            })
            .catch(err => message.error(err.response ? err.response.statusText : "Network Error", 4));
    }
    deleteCamera(id) {
        return axios.delete(`${BASE_URL}${api}CameraView?id=${id}`, HEADERS)
            .then(res => {
                return res.data;
            })
            .catch(err => message.error(err.response ? err.response.statusText : "Network Error", 4));
    }
    getContour(body) {
        var a = moment(moment());//now
        var b = moment(body.time);
        let url = a.diff(b, 'days') > 7 ? "ContourSnapShot" : "Contour";

        return axios.get(`${BASE_URL}${api}${url}?time=${body.time}&facilityId=${body.facilityId}&semanticId=${body.semanticId}`, HEADERS)
            .then(res => {
                return res.data;
            })
            .catch(err => message.error(err.response ? err.response.statusText : "Network Error", 4));
    }
    getContourNotTime(body) {
        let url = "Contour";

        return axios.get(`${BASE_URL}${api}${url}?facilityId=${body.facilityId}&semanticId=${body.semanticId}`, HEADERS)
            .then(res => {
                return res.data;
            })
            .catch(err => message.error(err.response ? err.response.statusText : "Network Error", 4));
    }
    getContourInsight(body) {
        var a = moment(moment());//now
        var b = moment(body.time);
        let url = a.diff(b, 'days') > 7 ? "InsightContourSnapShot" : "InsightContour";
        return axios.get(`${BASE_URL}${api}${url}?time=${body.time}&facilityId=${body.facilityId}&insightLibraryId=${body.insightLibraryId}`, HEADERS)
            .then(res => {
                return res.data;
            })
            .catch(err => message.error(err.response ? err.response.statusText : "Network Error", 4));
    }
    getContourInsightNotTime(body) {
        let url = "InsightContour";
        return axios.get(`${BASE_URL}${api}${url}?facilityId=${body.facilityId}&insightLibraryId=${body.insightLibraryId}`, HEADERS)
            .then(res => {
                return res.data;
            })
            .catch(err => message.error(err.response ? err.response.statusText : "Network Error", 4));
    }
    getAlertsByTime(body) {
        return axios.get(`${BASE_URL}${api}GetSemanticDeviceAlerts?filterDateTime=${body.time}&facilityId=${body.facilityId}`, HEADERS)
            .then(res => {
                return res.data;
            })
            .catch(err => message.error(err.response ? err.response.statusText : "Network Error", 4));
    }
    getAlertsInsightByTime(body) {
        return axios.get(`${BASE_URL}${api}GetInsightAlerts?filterDateTime=${body.time}&facilityId=${body.facilityId}`, HEADERS)
            .then(res => {
                return res.data;
            })
            .catch(err => message.error(err.response ? err.response.statusText : "Network Error", 4));
    }
    getEntities(params) {
        return axios.get(`${BASE_URL}${api}GetEntities`, { ...HEADERS, params })
            .then(res => {
                return res.data;
            })
            .catch(err => message.error(err.response ? err.response.statusText : "Network Error", 4));
    }
    assignUserToView(body) {
        return axios.put(`${BASE_URL}${api}CameraViewUserAssign`, body, HEADERS)
            .then(res => {
                return res.data;
            })
            .catch(err => message.error(err.response ? err.response.statusText : "Network Error", 4));
    }
    getUsersView(params) {
        return axios.get(`${BASE_URL}${api}CameraViewUserAssign`, {...HEADERS, params})
            .then(res => {
                return res.data;
            })
            .catch(err => message.error(err.response ? err.response.statusText : "Network Error", 4));
    }
    unAssignMe(params) {
        return axios.delete(`${BASE_URL}${api}CameraViewUnassignMe`, {...HEADERS, params})
            .then(res => {
                return res.data;
            })
            .catch(err => message.error(err.response ? err.response.statusText : "Network Error", 4));
    }
    updateView(body) {
        return axios.put(`${BASE_URL}${api}CameraView`,body,HEADERS)
            .then(res => {
                return res.data;
            })
            .catch(err => message.error(err.response ? err.response.statusText : "Network Error", 4));
    }
    updateViewRename(body) {
        return axios.put(`${BASE_URL}${api}CameraViewRename`,body,HEADERS)
            .then(res => {
                return res.data;
            })
            .catch(err => message.error(err.response ? err.response.statusText : "Network Error", 4));
    }
    DuplicateCameraView(params) {
        return axios.post(`${BASE_URL}${api}DuplicateCameraView`,null,{...HEADERS, params})
            .then(res => {
                return res.data;
            })
            .catch(err => message.error(err.response ? err.response.statusText : "Network Error", 4));
    }
}

