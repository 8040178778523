import React, { useState } from "react";
import moment from 'moment';
import IntlMessages from "util/IntlMessages";
import { Spin, Icon, Menu, message, Dropdown, Popconfirm } from "antd";
import { DeleteOutlined, FileOutlined } from '@ant-design/icons';
import { injectIntl, FormattedDisplayName } from 'react-intl';
import { useEntity } from "../../context";
import Item from './Item';
import { Button } from "antd4";
import { FacilityTag } from 'services';

const serviceTag = new FacilityTag();


function Index({
    attachmentFiles, name, modifyDate, description, id,
    userCreatorLastname, title, userModifierFirstname, userModifierLastname, getItems,
    intl, registerDate, accessLevel, isCreator }) {
    //hooks
    const { entity } = useEntity();
    //state
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openItem, setOpenItem] = useState(false);
    //const
    const menu = () => {
        return (
            <Menu>
                <Menu.Item>
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={(e) => { deleteItem() }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <div style={{ width: "100%" }}><DeleteOutlined /> <IntlMessages id="label.delete" /></div>
                    </Popconfirm>

                </Menu.Item>
                <Menu.Item onClick={() => setOpenItem({
                    id,
                    title,
                    description,
                    accessLevel,
                    attachmentFiles
                })}>
                    <Icon type="edit" />  <IntlMessages id="label.edit" />
                </Menu.Item>
            </Menu>
        )
    }

    //functions
    const deleteItem = (id) => {
        setLoading(true)
        serviceTag.unAssignToEntity(id, entity.data.id)
            .then(res => {
                if (res.header.metaResponse !== null) {
                    message[res.header.responseCode === 0 ? "success" : "warning"](intl.formatMessage({ id: res.header.metaResponse }))
                }
                if (res.header.responseCode === 0) {
                    getItems();
                }
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
            })

    }
    const hasImage = (type) => {
        const imagesTypes = ['png', 'jpg', 'jpeg'];
        if (imagesTypes.filter(el => type.toUpperCase().search(el.toUpperCase()) > -1).length) {
            return "image";
        }
        return "";
    }
    return (<Spin spinning={loading}>
        {openItem && <Item visible={openItem} setVisible={setOpenItem} getItems={getItems} />}
        <div className="file-card"
            style={{
                borderTop: "4px solid #A39972"
            }}>
            <div className="header-card" onClick={() => setOpen(!open)}>

                <div className="tag-name">
                    {name}
                </div>

            </div>
            {open && entity.data.isManager && <div className="header-card">
                <div className="acions-icons">
                <Popconfirm
                    title={<IntlMessages id="label.areYouSure" />}
                    onConfirm={() => deleteItem(id)}
                    onCancel={()=>{}}
                    okText="Yes"
                    cancelText="No"
                >
                    <div className={`acions-icons-item`}
                        style={{textAlign:"center", cursor:"pointer"}}>
                        <IntlMessages id="unAssign" />
                    </div>
                </Popconfirm>
                   
                </div>


            </div>}
        </div>
    </Spin>
    )


}


export default injectIntl(Index);