import Account from './Account';
import FacilityService from './Facility';
import DeviceService from './Device';
import ProfileService from './Profile';
import EndNodeService from './EndNode';
import Occupant from './Occupant';
import CategoryService from './Category';
import TicketService from './Ticket';
import EventService from './Event';
import AlertService from './Alert';
import SemanticService from './Semantic';
import SemanticUnitService from './SemanticUnit';
import SpecificationService from './Specification';
import ForgeService from './Forge';
import DomainService from './DomainExpertise';
import DashboardService from './Dashboard';
import EntityService from './Entity';
import EntityGroupService from './EntityGroup';
import FacilityEntityInsight from './FacilityEntityInsight';
import CommandService from './Command';
import PropertyService from './Property';
import FacilityTag from './FacilityTag';


// const BASE_URL = "https://api.twinitive.com/api/";
// const BASE_IMG = "https://api.twinitive.com/api/user/FileManager?fileName=";

const BASE_URL = "https://api.twinup.ai/api/";
const BASE_IMG = "https://api.twinup.ai/api/user/FileManager?fileName=";

const HEADERS = {
    headers:{
        'content-type': 'application/json',
        "Authorization": "Bearer " + localStorage.getItem('token')
        }
}

export {
    Account,
    DeviceService,
    FacilityService,
    ProfileService,
    EndNodeService,
    BASE_URL,
    HEADERS,
    Occupant,
    CategoryService,
    TicketService,
    EventService,
    AlertService,
    SemanticService,
    SemanticUnitService,
    SpecificationService,
    BASE_IMG,
    ForgeService,
    DomainService,
    DashboardService,
    EntityService,
    EntityGroupService,
    FacilityEntityInsight,
    CommandService,
    PropertyService,
    FacilityTag
}