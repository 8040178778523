import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
  BASE_URL
} from "../../constants/ActionTypes";
import axios from 'util/Api';
import {ProfileService, SemanticUnitService} from 'services';
import {SEMANTICS_UNITS} from 'util/constants';

const semanticUnitService = new SemanticUnitService();
const profileSV = new ProfileService();


export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};


export const updateUser = (data) => {
  return (dispatch) => {
    dispatch({type: USER_DATA, payload: data});
  }
};
export const userSignUp = ({email, password, name}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('auth/register', {
        email: email,
        password: password,
        name: name
      }
    ).then(({data}) => {
      if (data.result) {
        localStorage.setItem("token", JSON.stringify(data.token.access_token));
        axios.defaults.headers.common['access-token'] = "Bearer " + data.token.access_token;
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: USER_TOKEN_SET, payload: data.token.access_token});
        dispatch({type: USER_DATA, payload: data.user});
      } else {
        dispatch({type: FETCH_ERROR, payload: "Network Error"});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
    });
  }
};

export const userSignIn = (data, token) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.defaults.headers.common['access-token'] = "Bearer " + 'token';
    dispatch({type: FETCH_SUCCESS});
    dispatch({type: USER_TOKEN_SET, payload: 'token'});
    
    axios.post('login', {
      Token: token,
      Code: data.verfityCode,
      }
    ).then(({data}) => {
      if (data.result) {
        localStorage.setItem("token", JSON.stringify(data.token.access_token));
        axios.defaults.headers.common['access-token'] = "Bearer " + data.token.access_token;
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: USER_DATA, payload: data.user});
        dispatch({type: USER_TOKEN_SET, payload: data.token.access_token});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
    });
  }
};
const getSemanticUnits = () => {
  semanticUnitService.getSemanticUnitMap()
      .then(res => {
          if(res && res.data ){
            sessionStorage.setItem(SEMANTICS_UNITS, JSON.stringify(res.data));
          }
      })
      .catch(err => console.log(err))        
}

export const getUser = () => {
  return (dispatch) => {    
    dispatch({type: FETCH_START});
    profileSV.get().then(({data}) => {
      if (data) {
        getSemanticUnits();
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: USER_DATA, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
    });
  }
};

export const setUserLocal = () =>{
  return (dispatch) => {
    dispatch({type: USER_DATA, payload: JSON.parse(localStorage.getItem('user'))});
  }
}




export const userSignOut = () => {
  return (dispatch) => {
    profileSV.logout()
    .then(res => {
      if(res && res.header && res.header.responseCode === 0){
        dispatch({type: FETCH_START});
        setTimeout(() => {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          localStorage.removeItem("refreshToken");
          window.location.reload()
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: SIGNOUT_USER_SUCCESS});
        }, 1000);
      }
    })
    .catch(err => console.log(err))
    
  }
};
