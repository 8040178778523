import React, { useEffect, useState } from 'react';
import './index.less';
import EntityComponent from './EntityPanelComponent';
import { EntityProvider } from './context';

function Index(props) {
    //state
    const [select, setSelect] = useState("");
    const [selectTab, setSelectTab] = useState("");
    //hooks
    //effect
    useEffect(() => {
        if (props.match && props.match.params.id) {
            setSelect(props.match.params.id);
            setSelectTab(props.match.params.tab);
        } else if (props.id) {
            setSelect(props.id)
        }
    }, [props])
    return (
        <div>
            <EntityProvider
                twoDFile={props.twoDFile}
                setVisibleForge={props.setVisibleForge}
                tab={selectTab}
                type={props.type ? props.type : "page"}
                id={select}>
                <EntityComponent id={select} />
            </EntityProvider>
        </div>
    )
}


export default Index;