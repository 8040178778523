import React, { useState } from "react";
import moment from 'moment';
import IntlMessages from "util/IntlMessages";
import {Typography} from 'antd4';
import { Spin, Icon, Menu, message, Dropdown, Popconfirm } from "antd";
import { DeleteOutlined, LinkOutlined } from '@ant-design/icons';
import {IoLink} from 'react-icons/io5';
import { injectIntl, FormattedDisplayName } from 'react-intl';
import { useEntity } from "../../context";
import Item from './Link';


const {Paragraph} = Typography;
function Index({
    attachmentFiles, userCreatorFirstname, modifyDate, description, id,
    userCreatorLastname, title, userModifierFirstname, userModifierLastname, getItems,
    intl, registerDate, accessLevel, isCreator, link }) {
    //hooks
    const { entity } = useEntity();
    //state
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openItem, setOpenItem] = useState(false);
    //const
    const menu = () => {
        return (
            <Menu>
                <Menu.Item>
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={(e) => { deleteItem() }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <div style={{ width: "100%" }}><DeleteOutlined /> <IntlMessages id="label.delete" /></div>
                    </Popconfirm>

                </Menu.Item>
                <Menu.Item onClick={() => setOpenItem({
                    id,
                    title,
                    description,
                    accessLevel,
                    link
                })}>
                    <Icon type="edit" />  <IntlMessages id="label.edit" />
                </Menu.Item>
            </Menu>
        )
    }
    const getClickableLink = link => {
        return link.startsWith("http://") || link.startsWith("https://") ?
          link
          : `http://${link}`;
      };
    //functions
    const deleteItem = () => {
        setLoading(true)
        entity.service.deleteFileAttachment(id)
            .then(res => {
                if (res.header.metaResponse !== null) {
                    message[res.header.responseCode === 0 ? "success" : "warning"](intl.formatMessage({ id: res.header.metaResponse }))
                }
                if (res.header.responseCode === 0) {
                    getItems();
                }
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
            })

    }
    return (<Spin spinning={loading}>
        {openItem && <Item visible={openItem} setVisible={setOpenItem} getItems={getItems} />}
        <div className="file-card" style={{
            borderTop:"4px solid #8AD3D8"
        }}>
            <div className="header-card">
                <div className="line-one">
                    <div className="date-time">
                        {moment(registerDate).isValid() && moment(registerDate).format('MM/DD/YYYY, HH:mm')}
                    </div>
                    <div className="type">
                        <IoLink style={{fontSize:"20px"}}/>
                        {/* {attachmentFiles && attachmentFiles[0] && attachmentFiles[0].fileType && attachmentFiles[0].fileType.replace(".", "").toUpperCase()} */}
                    </div>
                </div>
                <div className="creator">
                    {userCreatorFirstname + " " + userCreatorLastname}
                </div>
                <div className="title">
                    {title}
                </div>
                <div className="file-name">
                    {!open && <Paragraph ellipsis copyable>{link}</Paragraph>
}
                </div>
                <div className="acions-icons">
                    <a href={getClickableLink(link)}
                        target="_blank"
                        className="acions-icons-item"

                    ><IntlMessages id="btn.open" /></a>
                    <div className="divider-more-container">
                        <div className="divider-more" />
                    </div>
                    <div className={`acions-icons-item`}
                        onClick={() => setOpen(!open)}
                    ><IntlMessages id="btn.detials" /></div>
                    <div className="divider-more-container">
                        <div className="divider-more" />
                    </div>
                    <div className={`acions-icons-item ${!entity.data.isManager && !isCreator && "disabled"}`}>
                        <Dropdown overlay={menu} trigger={['click']}>
                            <a style={{ color: "unset" }}><IntlMessages id="btn.more" /></a>
                        </Dropdown>
                    </div>
                </div>
            </div>
            {open && <div className="content-card">
                <div className="file-name">
                    <Paragraph  copyable>{link}</Paragraph>
                </div>
                {userModifierFirstname && <div className="modify-user">
                    <IntlMessages id="updatedBy" />&nbsp;{userModifierFirstname + " " + userModifierLastname}
                </div>}
                {modifyDate &&
                    <div className="date-time"> {moment(modifyDate).isValid() && moment(modifyDate).format('MM/DD/YYYY, HH:mm')}</div>}
                <div className="description">
                    {description}
                </div>
            </div>}
        </div>
    </Spin>
    )


}


export default injectIntl(Index);