import React, { useState, useEffect } from 'react';
import {
    Modal, Button, Radio, Steps, Row,
    Col, Form, Input, Icon, Checkbox, message, Select, Spin, Alert
} from 'antd';
import IntlMessages from "util/IntlMessages";
import { EndNodeService, AlertService, SemanticService, DomainService, FacilityEntityInsight, EntityService } from 'services';
import { SortFunction } from 'constants/Functions';
import { injectIntl } from 'react-intl';

const EndNodeSV = new EndNodeService();
const AlertSV = new AlertService();
const SemanticSV = new SemanticService();
const DomainSV = new DomainService();
const InsightSV = new FacilityEntityInsight();
const EntitySV = new EntityService();

const { Option } = Select;
const FormItem = Form.Item;


const { Step } = Steps;
const CheckboxGroup = Checkbox.Group;
const plainOptions = ['Email', 'Highlight'];

function Add(props) {
    const { visible, setVisible, name, label, symbol } = props;
    const [loading, setLoading] = useState(false);
    const [semantics, setSemantics] = useState([]);
    const [insights, setInsights] = useState([]);
    const [endNodes, setEndNodes] = useState([]);
    const [firstAttributes, setFirstAttributes] = useState([]);
    const [seccondAttributes, setSeccondAttributes] = useState([]);
    const [alertType, setAlertType] = useState('insight');
    const [entity, setEntity] = useState(0);
    const [domains, steDomains] = useState([]);
    const [alert, setAlert] = useState({
        "description": "",
        "name": "",
        "logicType": null,
        "accessLevel": "all",
        "isActionHighlightEnabled": false,
        "isActionEmailEnabled": false,
        "isDisabled": false,
        "facilityId": null,
        "deviceEndNodeId": null,
        "deviceEndNodeSeccondId": null,
        "firstValue": null,
        "seccondValue": null,
        "firstAttribute": null,
        "seccondAttribute": null,
        "semanticId": null,
        "domainExpertiseIds": []
    })
    useEffect(() => {
        getEndNode();
        getSemantics();
        getDomains();
        getInsights();
    }, [])
    const [current, setCurrent] = useState(0);
    const getDomains = () => {
        DomainSV.getAll(props.id)
            .then(res => {
                if (res && res.data) {
                    steDomains(res.data)
                }
            })
    }
    const getSemantics = () => {
        SemanticSV.getByFacilityId(props.id)
            .then(res => {
                if (res && res.data) {
                    setSemantics(SortFunction(res.data, 'semanticName'))
                }
            })
    }
    const getInsights = () => {
        EntitySV.getAll(props.id)
            .then(res => {
                if (res && res.data) {
                    setInsights(res.data);
                    // let newArr = [];
                    // res.data.forEach(el => {
                    //     newArr = newArr.concat(el.insights.map(i => { return { value: i.id, label: (el.name + " | " + i.name) } }))
                    // }, newArr)
                    // setInsights(SortFunction(newArr, 'label'))
                }
            })
    }
    const getEndNode = () => {
        EndNodeSV.getEndNodeByFacilityId(props.id)
            .then(res => {
                if (res && res.data) {
                    setEndNodes(SortFunction(res.data, 'name'))
                }
            })
    }
    const changeDevice = (id, value) => {

        EndNodeSV.getAttributeByEndNode(value)
            .then(res => {
                if (res && res.data) {
                    if (id === "deviceEndNodeId") {
                        props.form.setFieldsValue({
                            firstAttribute: null,
                        });
                        setFirstAttributes(SortFunction(res.data, 'name'));
                    } else {
                        props.form.setFieldsValue({
                            seccondAttribute: null,
                        });
                        setSeccondAttributes(SortFunction(res.data, 'name'));
                    }
                }
            })
        onChangeAlert({ id, value })
    }
    const handleSubmitOne = (e) => {

        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                // setAlertType(values['radio-group']);
                setCurrent(1);
            }
        })
    }
    const handleSubmitAccess = (e) => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                setCurrent(4);
            }
        })
    }
    const handleSubmitTwo = (e) => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                onChangeAlert({ id: "logicType", value: values['radio-group2'] })
                setCurrent(2);
            }
        })
    }
    const handleSubmitThree = (e) => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                setCurrent(3);
            }
        })
    }
    const addItem = (e) => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                setLoading(true);
                var body = {
                    semantic: {
                        "description": alert.description,
                        "name": alert.name,
                        "logicType": alert.logicType,
                        "isActionHighlightEnabled": alert.isActionHighlightEnabled,
                        "isActionEmailEnabled": alert.isActionEmailEnabled,
                        "isDisabled": false,
                        "accessLevel": alert.accessLevel,
                        "domainExpertiseIds": alert.domainExpertiseIds.map(Number),
                        "facilityId": parseInt(props.id),
                        "semanticId": parseInt(alert.semanticId),
                        "firstValue": parseFloat(alert.firstValue),
                        "seccondValue": parseFloat(alert.seccondValue),

                    },
                    device:
                    {
                        "description": alert.description,
                        "name": alert.name,
                        "logicType": alert.logicType,
                        "isActionHighlightEnabled": alert.isActionHighlightEnabled,
                        "isActionEmailEnabled": alert.isActionEmailEnabled,
                        "isDisabled": false,
                        "facilityId": parseInt(props.id),
                        "accessLevel": alert.accessLevel,
                        "domainExpertiseIds": alert.domainExpertiseIds.map(Number),
                        "deviceEndNodeId": parseInt(alert.deviceEndNodeId),
                        "deviceEndNodeSeccondId": parseInt(alert.deviceEndNodeSeccondId),
                        "firstValue": parseFloat(alert.firstValue),
                        "seccondValue": parseFloat(alert.seccondValue),
                        "firstAttribute": alert.firstAttribute,
                        "seccondAttribute": alert.seccondAttribute
                    },
                    insight: {
                        "name": alert.name,
                        "description": alert.description,
                        "logicType": alert.logicType,
                        "accessLevel": "all",
                        "domainExpertiseIds": [
                            0
                        ],
                        "isActionHighlightEnabled": alert.isActionHighlightEnabled,
                        "isActionEmailEnabled": alert.isActionEmailEnabled,
                        "isDisabled": false,
                        "facilityEntityInsightLibraryId": parseInt(props.insightId),
                        "facilityEntityInsightLibrarySeccondId": parseInt(alert.facilityEntityInsightLibrarySeccondId),
                        "facilityId": parseInt(props.id),
                        "firstValue": parseFloat(alert.firstValue),
                        "seccondValue": parseFloat(alert.seccondValue)
                    }
                }
                AlertSV.addInsight(body.insight)
                    .then(res => {
                        setLoading(false);
                        if (res.header.metaResponse !== null) {
                            if (res.header.responseCode === 0) {
                                message.success(props.intl.formatMessage({ id: res.header.metaResponse },{ added: res.data.added, total: res.data.total }))
                            } else if (res.header.responseCode === 4) {
                                message.warning(props.intl.formatMessage({ id: res.header.metaResponse }, { data: res.data }))
                            } else {
                                message.warning(props.intl.formatMessage({ id: res.header.metaResponse }))
                            }
                        }

                        props.setVisible(false);
                        props.getItems();
                    })
                    .catch(err => setLoading(false));
            }
        })

    }
    const onChangeAlert = (e) => {
        setAlert({
            ...alert,
            [e.id]: e.value
        })
    }
   
    const renderStep = () => {
        var key = alert.logicType;
        if (alertType === "insight" && (key === "GT" || key === "LT" || key === "EQ" || key === "CP")) {
            return (
                <div>
                    <Form onSubmit={handleSubmitThree}>
                        <Row>
                            <Col span={12}>
                                <FormItem>
                                    {name}&nbsp;|&nbsp;{label}
                                </FormItem>

                                {/* <FormItem>
                           {getFieldDecorator('insightLibraryId', {  
                               initialValue: alert.insightLibraryId,     
                                rules: [{
                                    required:true,
                                    message: <IntlMessages id="form.insightLibraryId.error"/>,
                                    }],                            
                                })(
                                <Select style={{ width: "100%" }} placeholder="Select Insight" onChange={(e)=>onChangeAlert({id:"insightLibraryId", value:e})}>
                                    {
                                        insights.map((insight,index) => {
                                            return(
                                                <Option key={index} value={insight.id}>{insight.name}</Option>
                                            )
                                        })
                                    }
                                </Select>
                                )}
                           </FormItem>                             */}
                                {}
                            </Col>

                            <Col span={4}>
                                <div style={{ textAlign: "center", lineHeight: "50px" }}>
                                    {
                                        alert.logicType === "GT" || alert.logicType === "CP" ?
                                            <Icon type="right" />
                                            :
                                            alert.logicType === "LT" ?
                                                <Icon type="left" />
                                                :
                                                <Icon style={{ transform: "rotate(90deg)" }} type="pause" />
                                    }
                                </div>
                            </Col>
                            {alert.logicType === "CP" ?
                                <Col span={8}>
                                    <FormItem>
                                        {getFieldDecorator('entity', {
                                            initialValue: alert.entity,
                                            rules: [{
                                                required: true,
                                                message: <IntlMessages id="form.insightLibraryId.error" />,
                                            }],
                                        })(
                                            <Select style={{ width: "100%" }} placeholder="Select entity" onChange={(e) => setEntity(e)}>
                                                {
                                                    insights.map((insight, index) => {
                                                        return (
                                                            <Option key={index} value={index}>{insight.name}</Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        )}
                                    </FormItem>
                                    <FormItem>
                                        {getFieldDecorator('facilityEntityInsightLibrarySeccondId', {
                                            initialValue: alert.facilityEntityInsightLibrarySeccondId,
                                            rules: [{
                                                required: true,
                                                message: <IntlMessages id="form.insightLibraryId.error" />,
                                            }],
                                        })(
                                            <Select style={{ width: "100%" }} placeholder="Select Insight" onChange={(e) => onChangeAlert({ id: "facilityEntityInsightLibrarySeccondId", value: e })}>
                                                {
                                                    insights[entity] ? insights[entity].insights.map((insight, index) => {
                                                        return (
                                                            <Option key={index} value={insight.id}>{insight.name}</Option>
                                                        )
                                                    }) :
                                                        <Option></Option>

                                                }
                                            </Select>
                                        )}
                                    </FormItem>

                                </Col>
                                :
                                <Col span={8}>
                                    <FormItem>
                                        {getFieldDecorator('firstValue', {
                                            initialValue: alert.firstValue,
                                            rules: [{
                                                required: true,
                                                pattern: "^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$",
                                                message: <IntlMessages id="form.value.error" />,
                                            }],
                                        })(
                                            <Input type="text" placeholder={`Enter Value ${symbol?`in ${symbol}`:""}`} onChange={(e) => onChangeAlert({ id: "firstValue", value: e.target.value })} />
                                        )}
                                    </FormItem>
                                </Col>
                            }

                        </Row>
                        <FormItem>
                            <Button onClick={() => setCurrent(current - 1)} ><IntlMessages id="button.back" /></Button>&nbsp;&nbsp;
                        <Button htmlType="submit" type="primary"><IntlMessages id="button.next" /></Button>
                        </FormItem>
                    </Form>
                </div>
            )
        }
        if (alertType === "insight" && key === "RG") {
            return (
                <div>
                    <Form onSubmit={handleSubmitThree}>
                        <Row>
                            <Col span={6}>
                                <FormItem>
                                    {getFieldDecorator('firstValue', {
                                        initialValue: alert.firstValue,
                                        rules: [{
                                            required: true,
                                            pattern: "^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$",
                                            message: <IntlMessages id="form.value.error" />,
                                        }],
                                    })(
                                        <Input type="text" placeholder={`Enter Value ${symbol?`in ${symbol}`:""}`} onChange={(e) => onChangeAlert({ id: "firstValue", value: e.target.value })} />
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={2} style={{ textAlign: "center", lineHeight: "50px" }}>
                                <Icon type="right" />
                            </Col>
                            <Col span={6}>
                                <FormItem>
                                    {name}&nbsp;|&nbsp;{label}
                                </FormItem>
                                {/* <FormItem>
                                    {getFieldDecorator('insightLibraryId', {
                                        initialValue: alert.insightLibraryId,
                                        rules: [{
                                            required: true,
                                            message: <IntlMessages id="form.insightLibraryId.error" />,
                                        }],
                                    })(
                                        <Select style={{ width: "100%" }} placeholder="Select Insight" onChange={(e) => onChangeAlert({ id: "insightLibraryId", value: e })}>
                                            {
                                                insights.map((insight, index) => {
                                                    return (
                                                        <Option key={index} value={insight.id}>{insight.name}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    )}
                                </FormItem> */}
                            </Col>
                            <Col span={2} style={{ textAlign: "center", lineHeight: "50px" }}>
                                <Icon type="right" />
                            </Col>
                            <Col span={6}>
                                <FormItem>
                                    {getFieldDecorator('seccondValue', {
                                        initialValue: alert.seccondValue,
                                        rules: [{
                                            required: true,
                                            pattern: "^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$",
                                            message: <IntlMessages id="form.value.error" />,
                                        }],
                                    })(
                                        <Input type="text" placeholder={`Enter Value ${symbol?`in ${symbol}`:""}`} onChange={(e) => onChangeAlert({ id: "seccondValue", value: e.target.value })} />
                                    )}
                                </FormItem>
                            </Col>

                        </Row>
                        <FormItem>
                            <Button onClick={() => setCurrent(current - 1)} ><IntlMessages id="button.back" /></Button>&nbsp;&nbsp;
                        <Button htmlType="submit" type="primary"><IntlMessages id="button.next" /></Button>
                        </FormItem>
                    </Form>
                </div>
            )
        }
    }

    const renderContent = () => {
        switch (current) {
            case 0:
                return (
                    <Row>
                        <Col span={24}>
                            <Form onSubmit={handleSubmitOne}>
                                <FormItem >
                                    {getFieldDecorator('name', {
                                        initialValue: alert.name,
                                        rules: [{
                                            required: true,
                                            message: <IntlMessages id="form.alertName.error" />,
                                        }],
                                    })(
                                        <Input onChange={(e) => onChangeAlert({ id: "name", value: e.target.value })} placeholder="Alert name" />
                                    )}
                                </FormItem>
                                <FormItem >
                                    {getFieldDecorator('description', {
                                        initialValue: alert.description                                        
                                    })(
                                        <Input onChange={(e) => onChangeAlert({ id: "description", value: e.target.value })} placeholder="Notification message (optional)" />
                                    )}
                                </FormItem>
                                {/* <FormItem label={<IntlMessages id="form.alertTypeTitle"/>}>
                                    {getFieldDecorator('radio-group',{
                                        initialValue: alertType,
                                         rules: [{
                                            required:true,
                                            message: <IntlMessages id="form.alertType.error"/>,
                                            }],
                                        })(                                 
                                            <Radio.Group id="type" name="type" defaultValue="" >
                                                <Radio value="device"><IntlMessages id="label.alertForDevice"/></Radio>   
                                                <br/>                                            
                                                <Radio value="semantic"><IntlMessages id="label.alertForSemantic"/></Radio>
                                                <br/>
                                                <Radio value="insight"><IntlMessages id="label.alertForInsight"/></Radio>

                                            </Radio.Group>
                                        )}
                                    </FormItem> */}
                                <FormItem>
                                    <Button type="primary" htmlType="submit"><IntlMessages id="button.next" /></Button>
                                </FormItem>
                            </Form>

                        </Col>
                    </Row>
                )
            case 1:
                return (
                    <Row>
                        <Col span={24}>
                            <Form onSubmit={handleSubmitTwo} id="two">
                                <FormItem label={<IntlMessages id="form.selectLogicTitle" />}>
                                    {getFieldDecorator('radio-group2', {
                                        initialValue: alert.logicType,
                                        rules: [{
                                            required: true,
                                            message: <IntlMessages id="form.selectLogic.error" />,
                                        }],
                                    })(
                                        <Radio.Group name="logic" defaultValue="">
                                            <Radio.Button value="GT"><IntlMessages id="label.greaterThan" /></Radio.Button>
                                            <Radio.Button value="LT"><IntlMessages id="label.lessThan" /></Radio.Button>
                                            <Radio.Button value="EQ"><IntlMessages id="label.equal" /></Radio.Button>
                                            {alertType !== "semantic" ?
                                                <Radio.Button value="CP"><IntlMessages id="label.compare" /></Radio.Button> : null
                                            }
                                            <Radio.Button value="RG"><IntlMessages id="label.range" /></Radio.Button>
                                        </Radio.Group>
                                    )}
                                </FormItem>
                                <FormItem>
                                    <Button onClick={() => setCurrent(0)} ><IntlMessages id="button.back" /></Button>&nbsp;&nbsp;
                                    <Button htmlType="submit" type="primary"><IntlMessages id="button.next" /></Button>
                                </FormItem>
                            </Form>

                        </Col>
                    </Row>
                )
            case 2:
                return (
                    <Row>
                        <Col span={24}>
                            <div>
                                {renderStep()}
                            </div>


                        </Col>
                    </Row>
                )
            case 3:
                return (
                    <Row>
                        <Col span={24}>
                            <Form onSubmit={handleSubmitAccess} id="three">
                                <FormItem >
                                    {getFieldDecorator('accessLevel', {
                                        initialValue: alert.accessLevel?alert.accessLevel:"all",
                                        rules: [{
                                            required: true, message: <IntlMessages id="form.accessLevel.error" />,
                                        }],
                                    })(
                                        <Select
                                            showSearch
                                            style={{ width: "100%" }}
                                            placeholder="Select Access Level"
                                            optionFilterProp="children"
                                            onChange={(v) => onChangeAlert({ id: "accessLevel", value: v })}
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            <Option value="all"><IntlMessages id="label.all" /></Option>
                                            <Option value="managersOnly"><IntlMessages id="label.managerOnly" /></Option>
                                            <Option value="occupantsOnly"><IntlMessages id="label.occupantsOnly" /></Option>
                                            <Option value="managersAndOccupants"><IntlMessages id="label.managersAndOccupants" /></Option>
                                            <Option value="managersAndDomainExperts"><IntlMessages id="label.managersAndDomainExperts" /></Option>

                                        </Select>
                                    )}
                                </FormItem>
                                {
                                    alert.accessLevel === "managersAndDomainExperts" ?
                                        <FormItem >
                                            {getFieldDecorator('domainExpertiseIds', {
                                                rules: [{
                                                    required: true, message: <IntlMessages id="form.domainExpertiseIds.error" />,
                                                }],
                                            })(
                                                <Select
                                                    style={{ width: "100%" }}
                                                    placeholder="Select Domain Expertises"
                                                    optionFilterProp="children"
                                                    onChange={(v) => onChangeAlert({ id: "domainExpertiseIds", value: v })}
                                                    mode="multiple"
                                                >
                                                    {domains.map(domain => {
                                                        return (
                                                            <Option value={domain.id} label={domain.name}>
                                                                {domain.name}
                                                            </Option>
                                                        )
                                                    })


                                                    }
                                                </Select>
                                            )}
                                        </FormItem> : null
                                }
                                <FormItem>
                                    <Button onClick={() => setCurrent(current - 1)} ><IntlMessages id="button.back" /></Button>&nbsp;&nbsp;
                                <Button htmlType="submit" type="primary"><IntlMessages id="button.next" /></Button>
                                </FormItem>
                            </Form>
                        </Col>
                    </Row>
                )
            case 4:
                return (
                    <Row>
                        <Col span={24}>
                            <Form onSubmit={addItem}>
                                <FormItem label={<IntlMessages id="label.selectAction" />}>
                                    {getFieldDecorator('checkbox-group', {

                                    })(
                                        <Checkbox.Group style={{ width: '100%' }}>
                                            <Checkbox value="isActionEmailEnabled" onChange={(e) => onChangeAlert({ id: "isActionEmailEnabled", value: e.target.checked })}><IntlMessages id="label.email" /></Checkbox>
                                            <Checkbox value="isActionHighlightEnabled" onChange={(e) => onChangeAlert({ id: "isActionHighlightEnabled", value: e.target.checked })}><IntlMessages id="label.highlight" /></Checkbox>
                                            <Checkbox disabled value="" onChange={(e) => onChangeAlert({ id: "", value: e.target.checked })}><IntlMessages id="label.textMessages" /></Checkbox>

                                        </Checkbox.Group>,
                                    )}
                                </FormItem>
                                <FormItem>
                                    <Button onClick={() => setCurrent(current - 1)} ><IntlMessages id="button.back" /></Button>&nbsp;&nbsp;
                                    <Button htmlType="submit" type="primary"><IntlMessages id="button.add" /></Button>
                                </FormItem>
                            </Form>

                        </Col>
                    </Row>
                )
            default:
                break;
        }
    }

    const { getFieldDecorator } = props.form;
    return (
        <Modal
            visible={visible}
            title={<IntlMessages id="alert.addTitle" />}
            onCancel={() => setVisible(false)}
            maskClosable={false}
            footer={null}
            width={700}
        >
            <Spin spinning={loading}>
                <Row gutter={24} type="flex" justify="center">
                    <Col span={22}><>
                        <Steps size="small" current={current}>
                            <Step title={<IntlMessages id="label.type" />} />
                            <Step title={<IntlMessages id="label.logic" />} />
                            <Step title={<IntlMessages id="label.values" />} />
                            <Step title={<IntlMessages id="label.access" />} />
                            <Step title={<IntlMessages id="label.action" />} />
                        </Steps>
                        <br />
                        {
                            renderContent()
                        }
                    </>
                    </Col>
                </Row>
            </Spin>
        </Modal>
    )
}
const AddForm = Form.create()(Add);
export default injectIntl(AddForm);
