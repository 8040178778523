import { BASE_URL, HEADERS } from './index';
import axios from 'axios';
import {message} from 'antd';

const api = "user/FacilityAlert"

export default class AlertService {
    getAll(id){
        return axios.get(`${BASE_URL}${api}?facilityId=${id}`,HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));         
    }
    getAllInsight(id, insightId){
        return axios.get(`${BASE_URL}${api}?facilityId=${id}&facilityEntityInsightLibraryId=${insightId}`,HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));         
    }
    getById(id){
        return axios.get(`${BASE_URL}${api}?id=${id}`,HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));         
    }
    addUser(data){
        return axios.put(`${BASE_URL}${api}/AddUsers`, data, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    addSemantic(data){
        return axios.post(`${BASE_URL}${api}/addAlertSemantic`, data, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    addDevice(data){
        return axios.post(`${BASE_URL}${api}/addAlertDevice`, data, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    addInsightBulk(data){
        return axios.post(`${BASE_URL}${api}/AddAlertInsightBulk`, data, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    addInsight(data){
        return axios.post(`${BASE_URL}${api}/AddAlertInsight`, data, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    editInsight(data){
        return axios.put(`${BASE_URL}${api}/EditAlertInsight`, data, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    edit(data){
        return axios.put(`${BASE_URL}${api}/EditAlertDevice`, data, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    editGroup(data){
        return axios.put(`${BASE_URL}${api}/EditAlertSemantic`, data, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    editInsightGroup(data){
        return axios.put(`${BASE_URL}${api}/EditAlertInsightBulk`, data, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    editSemantic(data){
        return axios.put(`${BASE_URL}${api}/EditSemantic`, data, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
   
    editStatus(id){
        return axios.put(`${BASE_URL}${api}/EnableDisable?id=${id}`, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }    
    delete(id){
        return axios.delete(`${BASE_URL}${api}`, {
         data:  id 
        }, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    getUsers(id){
        return axios.get(`${BASE_URL}${api}/GetUsers/?EventId=${id}`, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    getAttributes(id){
        return axios.get(`${BASE_URL}user/FacilityDeviceEndNodeValue/GetAttributes/deviceId?deviceId=${id}`, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    getLog(id){
        return axios.get(`${BASE_URL}${api}/AlertLog?id=${id}`,HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4)); 
    }
}

