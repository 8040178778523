import React, { useEffect, useState } from 'react';
import { Collapse, Spin, Row, Col, Button } from 'antd4';

import IntlMessages from 'util/IntlMessages';
import { useEntity } from '../../context';
import AttributeCard from './AttributeCard';
import axios from 'util/Api';
import { PlusOutlined } from '@ant-design/icons';
import { responsiveMode, responsiveModeFull } from '../../responsiveMode';
import AddAttribute from '../../../FacilityPage/Profile/Entity/EntityTab/AddEndNode';

function Index(props) {
    //hooks
    const { entity } = useEntity();
    //state
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [addAttribute, setAddAttribute] = useState(false);
    const getItems = () => {
        setLoading(true);
        if (localStorage.getItem("user")) {
            entity.service.getSpecificationLastValue(entity.data.code)
                .then(res => {
                    if (res.data) {
                        setItems(res.data)
                    }
                    setLoading(false);
                })
                .catch(err => setLoading(false))
        } else {
            axios.get('SpecificationLastValue', {
                params: {
                    code: entity.data.code
                }
            })
                .then(res => {
                    if (res.data) {
                        setItems(res.data.data)
                    }
                    setLoading(false);
                })
                .catch(err => setLoading(false))
        }


    }
    const unAssign = (ids) => {
        setLoading(true);
        let body = {
            "facilityEntityId": entity.data.id,
            "specificationIds": ids
        }
        entity.service.deleteSpecification(body)
            .then(res => {
                // if (res.header.metaResponse !== null) {
                //     message[res.header.responseCode === 0 ? "success" : "warning"](props.intl.formatMessage({ id: res.header.metaResponse }))
                // }
                if (res && res.header && res.header.responseCode === 0) {
                    getItems();
                }
                setLoading(false)

            })
            .catch(err => {
                console.log(err)
                setLoading(false)
            })
    }
    useEffect(() => {
        if (entity.data && entity.data.code) getItems();
    }, [entity.data])
    return (
        <div className='live-date-attribute'>
            {addAttribute && <AddAttribute
                visible={addAttribute}
                setVisible={setAddAttribute}
                getItems={getItems}
                facilityId={entity.data.facilityId}
                facilityEntityId={entity.data.id}
            />}
            <Spin spinning={loading}>
                <Row gutter={[15, 15]} wrap={false}>
                    {entity && entity.data && entity.data.isManager && <Col span={24}>
                        <Button
                            style={{width:"180px"}}
                            icon={<PlusOutlined />}
                            type="primary" onClick={() => setAddAttribute(true)}>
                            &nbsp;
                            <IntlMessages id="label.addAttribute" />
                        </Button>
                    </Col>}
                    {items && items.map((item, index) => (
                        <Col key={index}  {...responsiveMode(entity.type)}>
                            <AttributeCard {...item} unAssign={unAssign} />
                        </Col>
                    ))}
                </Row>
            </Spin>
        </div>
    )
}


export default Index;