/*eslint-disable no-undef*/

import React, { useEffect, useState } from 'react';
import { Form, Input, Modal, Spin, Button, Row, Col, message, Select, Upload } from 'antd4';
import { injectIntl } from 'react-intl'
import { PlusOutlined } from '@ant-design/icons';
import { EntityService, FacilityTag, DomainService } from 'services';
import IntlMessages from "util/IntlMessages";
import UploadFiles from 'components/UploadFiles/UploadFiles';
import { useEntity } from '../../context';
import AddTag from '../../../FacilityPage/Profile/Entity/Tags/Item';


const serviceTags = new FacilityTag();

const { Option } = Select;

const formItemLayout = {
    wrapperCol: { span: 24 },
    layout: 'vertical'
};
const responsiveMode = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
    xl: { span: 24 },
    xxl: { span: 24 },
}

function Index({ visible, setVisible, intl, getItems }) {
    //hooks
    const { entity } = useEntity();
    //state
    const [error, setError] = useState(false);
    const [addTag, setAddTag] = useState(false);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [tags, setTags] = useState([]);
    const [selectTag, setSelectTag] = useState([]);
    const onFinish = values => {
        console.log(values, "values")

        setLoading(true);
        serviceTags.assignToEntity({
            "facilityId": entity.data.facilityId,
            "entityId": entity.data.id,
            "tagIds": values.tagIds

        })
            .then(res => {
                if (res) {
                    setVisible(false);
                    getItems();
                }
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
            })




    }
    const getTags = (str) => {
        serviceTags.getList({
            "facilityId": entity.data.facilityId,
            "searchTerm": str || "",
            "isAscDate": true,
            "isAscName": false,
            "size": 10,
            "page": 1
        })
            .then(res => {
                if (res.data) {
                    setTags(res.data.list.map(el => ({ label: el.name, value: el.id })))
                }
                setLoading(false);
            })
            .catch(err => {
                setLoading(false)
            })
    }
    useEffect(() => {
        getTags("");
        if (visible.id) {
            form.setFieldsValue({
                ...visible,
                fileAttachements: visible.attachmentFiles.map(el => ({
                    ...el,
                    src: el.cloudStorageLink,
                    data: { type: el.fileType, name: el.originalName }
                }))

            });
        } else if (!entity.data.isManager) {
            form.setFieldsValue({
                accessLevel: "managerOccupant"
            });
        } else {
            form.setFieldsValue({
                accessLevel: "managerOnly"
            });
        }

    }, [visible]);

    return (

        <Modal
            visible={visible}
            title={<IntlMessages id="assign" />}
            keyboard={false}
            footer={false}
            onCancel={() => setVisible(false)}
        >
            <Spin spinning={loading}>
                {addTag && <AddTag visible={addTag} setVisible={setAddTag} facilityId={entity.data.facilityId}
                    getItems={getTags} />}
                <Form className="new-from"
                    name="edit-coordinate"
                    form={form} {...formItemLayout}
                    onFinish={onFinish}>

                    {(entity.data.isManager && selectTag.length > 0) ? <div style={{
                        height: "30px"
                    }}>
                        <IntlMessages id="selectTags" />:&nbsp;
                        <a style={{ color: "#b9b9b9", textDecoration: "underline", cursor: "no-drop" }}><IntlMessages id="addNewTagToEntity" /></a>
                    </div> :
                        <div style={{
                            height: "30px"
                        }}>
                            <IntlMessages id="selectTagsOr" />
                            <a
                                onClick={() => setAddTag(true)}
                                style={{ color: "#038fde", textDecoration: "underline" }}><IntlMessages id="defineANewTag" /></a>
                        </div>}
                    <Form.Item
                        name="tagIds"
                        rules={[{ required: true, message: intl.formatMessage({ id: "form.required" }) }]}

                    >
                        <Select
                            mode="multiple"
                            onSearch={(e) => getTags(e)}
                            onChange={(e) => setSelectTag(e)}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            dropdownRender={menu => (
                                <div>
                                    {menu}
                                </div>
                            )}
                            placeholder="Select tags">
                            {tags.map(tag => {
                                return (
                                    <Option key={tag.value} value={tag.value}>{tag.label}</Option>
                                )
                            }

                            )}
                        </Select>
                    </Form.Item>

                    <Button
                        onClick={form.submit}
                        type="primary" block>
                        {intl.formatMessage({ id: "button.save" })}
                    </Button>
                </Form>
            </Spin>
        </Modal>

    )
}

export default injectIntl(Index)