import React, { useState } from "react";
import moment from 'moment';
import IntlMessages from "util/IntlMessages";
import { Spin, Icon, Menu, message, Avatar, Popconfirm } from "antd";
import { DeleteOutlined, FileOutlined } from '@ant-design/icons';
import { injectIntl, FormattedDisplayName } from 'react-intl';
import { useEntity } from "../../context";
import Item from './Item';
import Delete from './Delete';
import { BASE_IMG } from 'services';

function Index({ lastName, roleType, firstName, email, id, deleteItem,
    valueDate, avatar, semanticUnitSymbol, semanticUnitId, modifyDate,
    userModifierFirstname, userModifierLastname, getItems
}) {
    //hooks
    const { entity } = useEntity();
    //state
    const [openDelete, setOpenDelete] = useState(false);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openItem, setOpenItem] = useState(false);
    //const
    const menu = () => {
        return (
            <Menu>
                <Menu.Item>
                    <Popconfirm
                        title="Are you sure?"
                        okText="Yes"
                        cancelText="No"
                    >
                        <div style={{ width: "100%" }}><DeleteOutlined /> <IntlMessages id="label.delete" /></div>
                    </Popconfirm>

                </Menu.Item>
                <Menu.Item onClick={() => setOpenItem({

                })}>
                    <Icon type="edit" />  <IntlMessages id="label.edit" />
                </Menu.Item>
            </Menu>
        )
    }

    //functions
    const usAssign = () => {
        deleteItem([id]);
    }
    const hasImage = (type) => {
        const imagesTypes = ['png', 'jpg', 'jpeg'];
        if (imagesTypes.filter(el => type.toUpperCase().search(el.toUpperCase()) > -1).length) {
            return "image";
        }
        return "";
    }

    return (<Spin spinning={loading}>
        {openItem && <Item visible={openItem} setVisible={setOpenItem} getItems={getItems} />}
        {openDelete && <Delete visible={openDelete} setVisible={setOpenDelete} getItems={getItems} />}

        <div className="property-card"
            style={{
                borderTop: "4px solid #008000"
            }}>
            <div className="header-card" onClick={() => setOpen(!open)}>
                <div className="information">
                    <div className="values-data">
                        <div className="value">

                        </div>
                        <div style={{ color: roleType === "Manager" ? "green" : "#038fde", fontWeight: 500 }}>
                            {roleType}
                        </div>
                    </div>
                    <div className="names" style={{ display: "flex" }}>
                        <Avatar src={BASE_IMG + avatar} style={{ backgroundColor: "#038fde", float: "left" }}
                            className="gx-size-40 gx-pointer gx-mr-3" alt="">
                            {firstName && firstName.charAt(0).toUpperCase()}{lastName && lastName.charAt(0).toUpperCase()}
                        </Avatar>
                        <div>
                            {firstName}&nbsp;{lastName}<br />
                            <div className="device-name">{email}</div>
                        </div>

                    </div>
                </div>
            </div>

            {open && <div className="content-card" style={{ paddingBottom: 0 }}>
                <div className="acions-icons" style={{ marginBottom: 0, borderBottom: "none" }}>
                    <div className={`acions-icons-item`}

                    >
                        <a style={{ color: "unset" }} href={`mailto:${email}`} target="_blank">
                            <IntlMessages id="btn.contact" />
                        </a>
                    </div>

                    {entity.data && entity.data.isManager && <>
                        <div className="divider-more-container">
                            <div className="divider-more" />
                        </div>
                        <div className={`acions-icons-item`}
                            onClick={() => usAssign()}
                        ><IntlMessages id="Unassign" /></div>
                    </>}


                </div>


            </div>}
        </div>
    </Spin>
    )


}


export default injectIntl(Index);