/*eslint-disable no-undef*/

import React, { useEffect, useState } from 'react';
import { Form, Input, Modal, Spin, Button, Row, Col, Divider, Select } from 'antd4';
import { injectIntl } from 'react-intl'
import { PlusOutlined } from '@ant-design/icons';
import { EntityService, FacilityEntityInsight, DomainService } from 'services';
import IntlMessages from "util/IntlMessages";

const DomainSV = new DomainService();
const service = new EntityService();

const { Option } = Select;

const formItemLayout = {
    wrapperCol: { span: 24 },
    layout: 'vertical'
};
const responsiveMode = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
    xl: { span: 24 },
    xxl: { span: 24 },
}

function Index({ visible, setVisible, intl, facilityId, twoDFile, getItem }) {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [entities, setEntities] = useState([]);
    const onFinish = values => {
        setLoading(true);
        service.update2dLocation({
            "facilityEntityId": visible.id,
            "fileId": parseInt(twoDFile),
            "xy": JSON.stringify({
                x: values.x,
                y: values.y
            })
        })
            .then(res => {
                console.log(parseInt(visible.id), {
                    x: values.x,
                    y: values.y,
                    z: 0
                }, "data")                
                Twinup3D.moveSprite(parseInt(visible.id), {
                    x: values.x,
                    y: values.y,
                    z: 0
                });
                setVisible(false);
                setLoading(false);
                getItem();


            })
            .catch(err => {
                setLoading(false)
            })

    }


    const changeSprite = () => {
        setLoading(true);
        let el = document.getElementsByClassName("ant-modal-root");
        let el2 = document.getElementById("show-info-dbid");
        el2.style.visibility = "hidden";
        el[0].style.visibility = "hidden";
        document.addEventListener('CLICK_POINT', (evt) => changeSpriteAfterClick(evt));
        document.dispatchEvent(new CustomEvent('Viewer_Loader', {detail:{loading:true}}));
        Twinup3D.monitorClickPoint();
        document.addEventListener('keyup', (event) => {
            if (event.key === "Escape") {
                //do something
                console.log("tetx", el2, el)
                event.preventDefault();
                event.stopPropagation();
                event.stopImmediatePropagation();
                if(el2 && el &&el[0]){
                    el2.style.visibility = "unset";
                    el[0].style.visibility = "unset";
                }                
                document.removeEventListener('keyup', ()=>{});
            }
        });
        setTimeout(() => {
            setLoading(false);
        }, 1500)
    }
    const changeSpriteAfterClick = async (evt) => {
        if (evt && evt._args && evt._args.matrix) {
            form.setFieldsValue({
                x: evt._args.matrix.x,
                y: evt._args.matrix.y
            })
            let el = document.getElementsByClassName("ant-modal-root");
            if(el && el[0]){
                el[0].style.visibility = "unset";
            }
            
            let el2 = document.getElementById("show-info-dbid");
            if(el2){
                el2.style.visibility = "unset";

            }
            Twinup3D.unmonitorClickPoint();
            document.dispatchEvent(new CustomEvent('Viewer_Loader', {detail:{loading:false}}));
            document.removeEventListener('CLICK_POINT', (evt) => changeSpriteAfterClick(evt));


        }
    }
   
    useEffect(() => {
        if (visible.xy) {
            form.setFieldsValue({
                x: visible.xy.x,
                y: visible.xy.y
            })
        }
    }, [visible])
    return (

        <Modal
            className='add-sprite'
            visible={visible}
            title={<IntlMessages id="label.sprite" />}
            keyboard={false}
            footer={false}
            onCancel={() => setVisible(false)}
        >
            <Spin spinning={loading}>
                <Form className="new-from"
                    name="edit-coordinate"
                    form={form} {...formItemLayout}
                    onFinish={onFinish}>
                    <Row gutter={[5, 0]}>
                        <Col {...responsiveMode}>
                            <div
                                onClick={() => changeSprite()}
                                style={{
                                    height: "42px", color: "#038fde", lineHeight: "42px",
                                    cursor: "pointer"
                                }}>
                                <IntlMessages id="label.placeInThe2dViewer" />
                            </div>
                            <Form.Item
                                name="x"
                                rules={[{ required: true, message: intl.formatMessage({ id: "form.required" }) }]}

                            >
                                <Input addonBefore="X" />
                            </Form.Item>
                            <Form.Item
                                name="y"
                                rules={[{ required: true, message: intl.formatMessage({ id: "form.required" }) }]}

                            >
                                <Input addonBefore="Y" />
                            </Form.Item>

                        </Col>
                        <Button type="primary" block form="edit-coordinate" key="submit" htmlType="submit">
                            {intl.formatMessage({ id: "button.save" })}
                        </Button>
                    </Row>
                </Form>
            </Spin>
        </Modal>

    )
}

export default injectIntl(Index)