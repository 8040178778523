/*eslint-disable no-undef*/

import React, { useEffect, useState } from 'react';
import { Form, Input, Modal, Spin, Button, Row, Col, message, Select, Upload } from 'antd4';
import { injectIntl } from 'react-intl'
import { PlusOutlined } from '@ant-design/icons';
import { SemanticService, SemanticUnitService, PropertyService } from 'services';
import IntlMessages from "util/IntlMessages";
import UploadFiles from 'components/UploadFiles/UploadFiles';
import { useEntity } from '../../context';

const semanticService = new SemanticService();
const semanticUnitService = new SemanticUnitService();
const propertyService = new PropertyService();

const { Option } = Select;

const formItemLayout = {
    wrapperCol: { span: 24 },
    layout: 'vertical'
};
const responsiveMode = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
    xl: { span: 24 },
    xxl: { span: 24 },
}

function Index({ visible, setVisible, intl, getItems }) {
    //hooks
    const { entity } = useEntity();
    //state
    const [error, setError] = useState(false);

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const [semantics, setSemantics] = useState([]);
    const [semanticsUnits, setSemanticUnits] = useState([]);

    const onFinish = values => {
        if (visible.id) {
            setLoading(true);
            propertyService.edit({
                ...values,
                "facilityEntityCode": entity.data.code,
                id: visible.id
            })
                .then(res => {

                    if (res.header.metaResponse !== null) {
                        message[res.header.responseCode === 0 ? "success" : "warning"](intl.formatMessage({ id: res.header.metaResponse }))
                    }
                    if (res.header.responseCode === 0) {
                        setVisible(false);
                        getItems();
                    }
                    setLoading(false)

                })
                .catch(err => {
                    console.log(err, "rr")
                    setLoading(false);
                })

        } else {
            setLoading(true);
            propertyService.add({
                ...values,
                "facilityEntityCode": entity.data.code,
            })
                .then(res => {
                    if (res) {
                        setVisible(false);
                        getItems();
                    }
                    setLoading(false);
                })
                .catch(err => {
                    setLoading(false);
                })

        }


    }
    const getSemantics = () => {
        semanticService.getAll()
            .then(res => {
                if (res && res.data) {
                    setSemantics(res.data.map(el => { return { label: el.semanticName, value: el.semanticId } }))
                }
            })
            .catch(err => {

            })
    }
    const getSemanticUnit = (id) => {
        semanticUnitService.getBySemanticId(id)
            .then(res => {
                if (res && res.data) {
                    setSemanticUnits(res.data.map(el => { return { label: el.semanticUnitName, value: el.semanticUnitId } }))
                }
            })
            .catch(err => {

            })
    }
    useEffect(() => {
        getSemantics();
        if (visible.id) {
            form.setFieldsValue({
                ...visible
            });
            if (visible.semanticId) {
                getSemanticUnit(visible.semanticId);
            }
        }else{
            form.setFieldsValue({
                accessLevel:"Public"
            });
        }

    }, [visible])
    return (

        <Modal
            visible={visible}
            title={visible.id ? <IntlMessages id="editPropery" /> : <IntlMessages id="addNewProperty" />}
            keyboard={false}
            footer={false}
            onCancel={() => setVisible(false)}
        >
            <Spin spinning={loading}>
                <Form className="new-from"
                    name="edit-coordinate"
                    form={form} {...formItemLayout}
                    onFinish={onFinish}>

                    <Form.Item
                        name="name"
                        rules={[{ required: true, message: intl.formatMessage({ id: "form.required" }) }]}

                    >
                        <Input placeholder="Property name" />
                    </Form.Item>
                    <Form.Item
                        name="description"
                    // rules={[{ required: true, message: intl.formatMessage({ id: "form.required" }) }]}

                    >
                        <Input placeholder="Description" />
                    </Form.Item>
                    <Form.Item
                        name="value"
                        rules={[{ required: true, message: intl.formatMessage({ id: "form.required" }) }]}

                    >
                        <Input placeholder="Value" />
                    </Form.Item>
                    <Form.Item

                        name="semanticId"
                        rules={[{ required: true, message: intl.formatMessage({ id: "form.required" }) }]}

                    >
                        <Select
                            disabled={visible.id}
                            showSearch
                            options={semantics}
                            style={{ width: "100%", marginTop: "15px" }}
                            placeholder={"Select semantic"}
                            optionFilterProp="label"
                            onSelect={(e) => getSemanticUnit(e)}
                            filterOption={(input, option) =>
                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            } />
                    </Form.Item>
                    <Form.Item
                        name="semanticUnitId"
                        rules={[{ required: true, message: intl.formatMessage({ id: "form.required" }) }]}

                    >
                        <Select
                            showSearch                            
                            options={semanticsUnits}
                            style={{ width: "100%", marginTop: "15px", marginBottom: "15px" }}
                            placeholder={"Select semantic unit"}
                            optionFilterProp="label"
                            onSelect={(e) => getSemanticUnit(e)}
                            filterOption={(input, option) =>
                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            } />
                    </Form.Item>
                    <Form.Item
                        name="accessLevel"
                        rules={[{ required: true, message: intl.formatMessage({ id: "form.required" }) }]}

                    >
                        <Select
                            disabled={!entity.data.isManager}
                            defaultValue="public"
                            placeholder="Access level">
                            <Option value="Public">
                                <IntlMessages id="public" />
                            </Option>
                            <Option value="ManagerOccupant">
                                <IntlMessages id="managerOccupant" />
                            </Option>
                            <Option value="ManagerOnly">
                                <IntlMessages id="managerOnly" />
                            </Option>
                        </Select>
                    </Form.Item>

                    <Button
                        onClick={form.submit}
                        type="primary" block>
                        {intl.formatMessage({ id: "button.save" })}
                    </Button>
                </Form>
            </Spin>
        </Modal>

    )
}

export default injectIntl(Index)