import React, {Component} from "react";
import {connect} from "react-redux";
import {Menu, Icon} from "antd";
import {Link} from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";

import Auxiliary from "util/Auxiliary";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";

const MenuItemGroup = Menu.ItemGroup;
const SubMenu = Menu.SubMenu;

class SidebarContent extends Component {

  getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  render() {
    const {themeType, navStyle, pathname} = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];
    return (<Auxiliary>

        <SidebarLogo/>
        <div className="gx-sidebar-content" >
          <div className={`gx-sidebar-notifications ${this.getNoHeaderClass(navStyle)}`}>
            <UserProfile/>
            {/* <AppsNavigation/> */}
          </div>
          <div className="gx-lacyout-sider-scrollbar">
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              // selectedKeys={[selectedKeys]}
              theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
              mode="inline">
              
              <Menu.Item key="dashboard">
                <Link to="/dashboard"><i className="icon icon-widgets"/>
                  <IntlMessages id="sidebar.dashboard"/></Link>
              </Menu.Item>
              <Menu.Item key={`facility`}>
                <Link to="/facility"><i className="icon icon-company"/>
                  <IntlMessages id="sidebar.facility"/></Link>
              </Menu.Item>
              <Menu.Item key={`devices`}>
                <Link to="/devices">
                  <span><Icon className="icon" type="deployment-unit"/></span>
                  <IntlMessages id="sidebar.devices"/></Link>
              </Menu.Item>          
              <Menu.Item key="calendar">
                <Link to="/calendar"><i className="icon icon-calendar-new"/>
                  <IntlMessages id="sidebar.calendar"/></Link>
              </Menu.Item>
              <Menu.Item key="ticket">
                <Link to="/ticket"><i className="icon icon-notification-new"/>
                  <IntlMessages id="sidebar.ticket"/></Link>
              </Menu.Item>
              <Menu.Item key={`notifications`}>
                <Link to="/notifications"><i className="icon icon-notification"/>
                  <IntlMessages id="sidebar.notifications"/></Link>
              </Menu.Item>              

            </Menu>
          </div>
        </div>
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({settings}) => {
  const {navStyle, themeType, locale, pathname} = settings;
  return {navStyle, themeType, locale, pathname}
};
export default connect(mapStateToProps)(SidebarContent);

