import React, { useEffect, useState } from 'react';
import { Form, Input, Modal, Spin, Button, Row, Col, Divider, Select, message } from 'antd4';
import { injectIntl } from 'react-intl'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { EntityGroupService, EntityService, FacilityTag } from 'services';


const formItemLayout = {
    wrapperCol: { span: 24 },
    layout: 'vertical'
};
const responsiveMode = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
    xl: { span: 24 },
    xxl: { span: 24 },
}

const serviceTag = new FacilityTag();
const services = new EntityGroupService();
const servicesEntity = new EntityService();

function Index({ visible, setVisible, getItems, intl, facilityId }) {
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);
    const [groups, setGroups] = useState([])
    const [groupName, setGroupName] = useState('');
    const onFinish = values => {
        if(visible.id === undefined){
            values.facilityId = facilityId;
        }
        if(visible.id){
            values.newName =  values.name;
            values.name = undefined;
        }
        setLoading(true);
        serviceTag[visible.id ? "edit" : "add"](values)
            .then(res => {
                if (res.header && res.header.responseCode === 0) {
                    getItems();
                    setVisible(false);
                }
                if (res.header.metaResponse !== null) {
                    message[res.header.responseCode === 0 ? "success" : "warning"](intl.formatMessage({ id: res.header.metaResponse }))
                }
                setLoading(false);
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
            })
    }
   
    const addGroup = () => {
        if (groupName !== "") {

            services.add({ name: groupName, facilityId: facilityId })
                .then(res => {
                    if (res.data && res.header.responseCode == 0) {
                        getGroups();
                    }
                })
                .catch(err => console.log(err))
        }
    }
    const getGroups = () => {
        services.getAll(facilityId)
            .then(res => {
                if (res.data && res.header.responseCode == 0) {
                    setGroups(res.data);
                }
            })
            .catch(err => console.log(err))
    }
    const deleteGroup = (id) => {
        setLoading(true);
        services.delete(id)
            .then(res => {
                if (res.header && res.header.responseCode === 0) {
                    getGroups()
                }
                setLoading(false);
            })
            .catch(err => setLoading(false))
    }
    useEffect(() => {
        if (visible.id) {
            form.setFieldsValue(visible);
        } else if (visible.disabledGroup) {
            form.setFieldsValue(visible);
        }
        // eslint-disable-next-line
    }, [])
    return (
        <Modal
            title={intl.formatMessage({ id:visible.id?"renameTag": "button.addTag" })}
            visible={visible}
            footer={false}
            onCancel={() => setVisible(false)}
        >
            <Spin spinning={loading}>
                <Form className="new-from" name="add" form={form} {...formItemLayout} onFinish={onFinish}>
                    <Form.Item name="id" style={{ display: "none" }} />
                    <Form.Item name="note" style={{ display: "none" }} />
                    <Row>
                        <Col {...responsiveMode}>
                            <Form.Item
                                name="name"
                                rules={[{ required: true, message: intl.formatMessage({ id: "form.required" }) }]}
                            >
                                <Input placeholder={intl.formatMessage({ id: "label.name" })} />
                            </Form.Item>
                        </Col>
                       
                        <Form.Item>
                            <Button form="add" block type="primary" key="submit" htmlType="submit">
                                {intl.formatMessage({ id: "button.save" })}
                            </Button>
                        </Form.Item>
                    </Row>
                </Form>
            </Spin>
        </Modal>
    )
}

export default injectIntl(Index)