import React, { useState, useEffect } from 'react';
import { Modal, Button, Input, Select, Spin, Form, Row, Col, message, Radio, Switch, Icon } from 'antd';
import IntlMessages from "util/IntlMessages";
import { SemanticService, SemanticUnitService, SpecificationService, DomainService } from 'services';
import { withRouter } from "react-router"
import { injectIntl } from 'react-intl';
import {useEntity} from '../../context';

const accessLevel = ["all", "managersOnly", "occupantsOnly", "managersAndDomainExperts"];
// const privacyLevel = [ "public", "private" ];

const semanticSV = new SemanticService();
const semanticUnitSV = new SemanticUnitService();
const SpecificationSV = new SpecificationService();
const DomainSV = new DomainService();

const FormItem = Form.Item;
const { Option } = Select;

function Edit(props) {
  //hooks
  const {entity} = useEntity();
  console.log(props, "props")
  const { visible, setVisible } = props;
  const [item, setItem] = useState({
    "ids": visible.ids,
    "accessLevel": visible.accessLevel,
    "privacyLevel": visible.privacyLevel,
    "a": visible.a,
    "b": visible.b,
    "c": visible.c,
    "d": visible.d,
    "aliasName": visible.aliasName,
    "variableLable": visible.variableLable,
    "priorityLevel": visible.priorityLevel,
    "semanticId": visible.semanticId,
    "semanticUnitId": visible.semanticUnitId,
    "domainExpertiseIds": visible.getDomainExpertiseModels.map(el => { return el.id })
  });
  const [calibration, setCalibration] = useState(false);
  const [openAddSemantics, setOpenAddSemantics] = useState(false);
  const [semantics, setSemantics] = useState([]);
  const [semanticUnits, setSemanticUnits] = useState([]);
  const [loadingSemanticUnit, setLoadingSemanticUnit] = useState(false);
  const [domains, setDomains] = useState([]);
  const [loading, setLoading] = useState(false);
  const { getFieldDecorator } = props.form;
  const AllItems = props.visible.index === null;
  const onChange = (e) => {
    if (e.target.id === "semanticId") {
      setItem({
        ...item,
        [e.target.id]: e.target.value,
        semanticUnitId: null
      })
    } else {
      setItem({
        ...item,
        [e.target.id]: e.target.value,
      })
    }
  }
  const addItem = (e) => {
    e.preventDefault();
    let body = { ...item }
    delete body.variableLable;
    if ((visible.semanticId === null && (visible.deviceType !== "Manual" && visible.deviceType !== "BACnet"))) {
      body.semanticId = 1;
      body.semanticUnitId = 1;
    }
    body.a = parseFloat(body.a);
    body.b = parseFloat(body.b);
    body.d = parseFloat(body.d);
    body.c = parseFloat(body.c);
    props.form.validateFields((err, values) => {
      if (!err) {
        setLoading(true);
        SpecificationSV.edit(body)
          .then(res => {
            if (res.header.metaResponse !== null) {
              message[res.header.responseCode === 0 ? "success" : "warning"](props.intl.formatMessage({ id: res.header.metaResponse }))

            }
            setLoading(false);
            setVisible(false)

            props.getItems();
          })
          .catch(err => {
            console.log(err, "err")
            setLoading(false)
          });
      }
    })

  }
  const getSemantic = () => {
    semanticSV.getAll()
      .then(res => {
        if (res && res.data) {
          setSemantics(res.data)
        }
      });
  }
  const getDomains = () => {
    DomainSV.getAll(entity.data.facilityId)
      .then(res => {
        if (res && res.data) {
          setDomains(res.data);
        }
      })
  }
  const getSemanticUnit = (id) => {
    setLoadingSemanticUnit(true);
    semanticUnitSV.getBySemanticId(id)
      .then(res => {
        if (res && res.header.responseCode === 0) {
          setSemanticUnits(res.data);
          let indexFind = res.data.findIndex(el => el.isBase);
          if (indexFind > -1 && item.semanticUnitId === null) {
            props.form.setFieldsValue({ semanticUnitId: res.data[indexFind].semanticUnitId })
            setItem({
              ...item,
              semanticUnitId: res.data[indexFind].semanticUnitId
            })
          }

        }
        setLoadingSemanticUnit(false);
      })
      .catch(err => {
        setLoadingSemanticUnit(false);
      })
  }

  useEffect(() => {
    getSemanticUnit(item.semanticId);
  }, [item.semanticId]);
  useEffect(() => {
    getDomains();
    getSemantic();
    getSemanticUnit(visible.semanticId);
  }, [])
  return (
    <Modal
      title={<IntlMessages id="edit.specification.title" />}
      visible={visible}
      footer={false}
      onCancel={() => setVisible(false)}
    >
      <Spin spinning={loading}>
        <Row gutter={24} type="flex" justify="center">
          <Col span={22}>
            <Form onSubmit={addItem} className="gx-signin-form gx-form-row0" >
              {!AllItems && <><FormItem>
                {getFieldDecorator('variableLable', {
                  initialValue: item.variableLable,
                })(
                  <Input placeholder="Name" disabled />
                )}
              </FormItem>
                <FormItem>
                  {getFieldDecorator('aliasName', {
                    initialValue: item.aliasName
                  })(
                    <Input placeholder="Alias name" onChange={onChange} />
                  )}
                </FormItem></>}
              {(visible.semanticId === null && (visible.deviceType !== "Manual" && visible.deviceType !== "BACnet")) ? <div /> : <><FormItem >
                {getFieldDecorator('semanticId', {
                  initialValue: item.semanticId,
                  rules: [{
                    required: true, message: <IntlMessages id="form.addSpecification.semanticId" />,
                  }],
                })(
                  <Select
                    showSearch
                    disabled={visible.deviceType !== "Manual" && visible.deviceType !== "BACnet"}
                    style={{ width: "100%" }}
                    placeholder="Semantic"
                    optionFilterProp="children"
                    onChange={(v) => {
                      onChange({ target: { id: "semanticId", value: v } })
                    }}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        {/* <div
                                      style={{ padding: '4px 8px', cursor: 'pointer'}}
                                      onMouseDown={e => e.preventDefault()}
                                      onClick={()=>setOpenAddSemantics(true)}
                                      >
                                      <Divider/>
                                      <IntlMessages id="add.Item"/>
                                  </div> */}
                      </div>
                    )}
                  >
                    {semantics.map(semantic => {
                      return (
                        <Option key={semantic.semanticId} value={semantic.semanticId}>{semantic.semanticName}</Option>
                      )
                    }

                    )}
                  </Select>
                )}
              </FormItem>
                <FormItem >
                  {getFieldDecorator('semanticUnitId', {
                    initialValue: item.semanticUnitId,
                    rules: [{
                      required: true, message: <IntlMessages id="form.addSpecification.semanticUnitId" />,
                    }],
                  })(
                    <Select
                      disabled={(visible.deviceType !== "Manual" && visible.deviceType !== "BACnet") || semanticUnits.length === 0}
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Semantic UnitId"
                      optionFilterProp="children"
                      onChange={(v) => onChange({ target: { id: "semanticUnitId", value: v } })}
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {semanticUnits.map(semantic => {
                        return (
                          <Option key={semantic.semanticUnitId} value={semantic.semanticUnitId}>{semantic.semanticUnitName}</Option>
                        )
                      }

                      )}
                    </Select>
                  )}
                </FormItem>
              </>}
              <FormItem >
                {getFieldDecorator('accessLevel', {
                  initialValue: item.accessLevel,
                  rules: [{
                    required: true, message: <IntlMessages id="form.addSpecification.accessLevel" />,
                  }],
                })(
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Access Level"
                    optionFilterProp="children"
                    onChange={(v) => onChange({ target: { id: "accessLevel", value: v } })}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value="All"><IntlMessages id="label.all" /></Option>
                    <Option value="ManagersOnly"><IntlMessages id="label.managerOnly" /></Option>
                    <Option value="OccupantsOnly"><IntlMessages id="label.occupantsOnly" /></Option>
                    <Option value="managersAndOccupants"><IntlMessages id="label.managersAndOccupants" /></Option>
                    <Option value="ManagersAndDomainExperts"><IntlMessages id="label.managersAndDomainExperts" /></Option>

                  </Select>
                )}
              </FormItem>
              {
                item.accessLevel === "ManagersAndDomainExperts" ?
                  <FormItem >
                    {getFieldDecorator('domainExpertiseIds', {
                      initialValue: item.domainExpertiseIds,
                      rules: [{
                        required: true, message: <IntlMessages id="form.domainExpertiseIds.error" />,
                      }],
                    })(
                      <Select
                        style={{ width: "100%" }}
                        placeholder="Domain Expertises"
                        optionFilterProp="children"
                        onChange={(v) => onChange({ target: { id: "domainExpertiseIds", value: v } })}
                        mode="multiple"
                      >
                        {domains.map(domain => {
                          return (
                            <Option value={domain.id} label={domain.name}>
                              {domain.name}
                            </Option>
                          )
                        })


                        }
                      </Select>
                    )}
                  </FormItem> : null
              }
              <FormItem>
                {getFieldDecorator('priorityLevel',{
                  initialValue: item.priorityLevel,
                })
                  (
                    <>
                    <IntlMessages id="label.priorityLevel" />:<br/>
                    <Radio.Group 
                      onChange={(e)=>onChange({ target: { id: "priorityLevel", value: e.target.value } })}
                      defaultValue={item.priorityLevel}>
                      <Radio.Button value="Low">
                      <IntlMessages id="label.low" />
                      </Radio.Button>
                      <Radio.Button value="Normal"><IntlMessages id="label.normal" /></Radio.Button>
                      <Radio.Button value="High"><IntlMessages id="label.high" /></Radio.Button>
                    </Radio.Group>
                    </> )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('privacyLevel')(<span><IntlMessages id="label.syncToCloud" />&nbsp;<Switch defaultChecked={item.privacyLevel === 'Public'} onChange={(e) => {
                  onChange({ target: { id: 'privacyLevel', value: e ? "Public" : "Private" } })
                }} /></span>)}
              </FormItem>
              <div onClick={() => setCalibration(!calibration)} style={{ padding: "10px 0px", cursor: "pointer" }}>
                {calibration ? <Icon type="down" /> : <Icon type="right" />}
                &nbsp;&nbsp;<IntlMessages id="label.calibration" /></div>
              {
                calibration ?
                  <Row>
                    <Col xl={6} lg={6} md={6} xs={6} sm={6}>
                      <FormItem>
                        {getFieldDecorator('c', {
                          initialValue: item.c,
                          rules: [{
                            required: true, message: <IntlMessages id="form.addSpecification.a" />,
                          }],
                        })(
                          <Input addonAfter="X" placeholder="C" onChange={onChange} />
                        )}
                      </FormItem>
                    </Col>
                    <Col>
                      <div style={{ lineHeight: "40px", fontSize: "20px" }}>+</div>
                    </Col>
                    <Col xl={6} lg={6} md={6} xs={6} sm={6}>
                      <FormItem>
                        {getFieldDecorator('d', {
                          initialValue: item.d,
                          rules: [{
                            required: true, message: <IntlMessages id="form.addSpecification.b" />,
                          }],
                        })(
                          <Input placeholder="D" onChange={onChange} />
                        )}
                      </FormItem>
                    </Col>
                    <Col>
                      <div style={{ lineHeight: "40px", fontSize: "20px" }}>{"=>" + item.c + "X" + "+" + item.d}</div>
                    </Col>

                  </Row> :
                  null
              }

              <FormItem>
                <Button type="primary" block className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="edit.button" />
                </Button>
              </FormItem>
            </Form>
          </Col>
        </Row>
      </Spin>

    </Modal>
  )
}

const AddForm = Form.create()(Edit);

export default injectIntl(withRouter(AddForm));
