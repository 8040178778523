import { BASE_URL, HEADERS } from './index';
import axios from 'axios';
import {message} from 'antd';

const api = "user/FacilityDomainExpertise"

export default class DomainExpertise{
    getAll(id){
        return axios.get(`${BASE_URL}${api}?facilityId=${id}`,HEADERS)
        .then(res => {
            if(res.data.header.responseCode === 0){
                return res.data;
            }
            else{
                message.warning(res.data.header.message, 4);
            }
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 2));         
    }
    add(data){
        return axios.post(`${BASE_URL}${api}`, data, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 2));
    }
    edit(data){
        return axios.put(`${BASE_URL}${api}`, data, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }

}

