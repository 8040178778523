import React, {Component} from "react";
import {connect} from "react-redux";
import {Avatar, Popover} from "antd";
import {userSignOut} from "appRedux/actions/Auth";
import {Link} from "react-router-dom";
import { BASE_IMG } from '../../services';

class UserInfo extends Component {

  render() {
    const {authUser} = this.props;
    const userMenuOptions = (
      <ul className="gx-user-popover">
        <Link to="/profile">
          <li>My Account</li>
        </Link>
        <li onClick={() => this.props.userSignOut()}>Sign Out
        </li>
      </ul>
    );

    return (
      <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions}
               trigger="click">
        <Avatar src={BASE_IMG+authUser.avatar} style={{backgroundColor:"#038fde"}}
                  className="gx-size-30 gx-pointer gx-mr-3" alt="">
                     {authUser.firstName.charAt(0).toUpperCase()+authUser.lastName.charAt(0).toUpperCase()}
                  </Avatar>
      </Popover>
    )

  }
}
const mapStateToProps = ({auth}) => {
  const {authUser} = auth;
  return {authUser}
};

export default connect(mapStateToProps, {userSignOut})(UserInfo);
