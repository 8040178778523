import React, { useState } from "react";
import moment from 'moment';
import { EndNodeService, SpecificationService, SemanticService, SemanticUnitService } from 'services';
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis } from "recharts";
import IntlMessages from "util/IntlMessages";
import { Spin, Icon, Menu, message, Dropdown, Popconfirm } from "antd";
import {CloseCircleOutlined} from '@ant-design/icons';
import BACnetProperty from '../../../FacilityPage/Profile/Devices/DeviceBACnet/Attributes/BacnetProperty';
import AddSemantice from './AddSemantic';
import { injectIntl, FormattedDisplayName } from 'react-intl';
import AmtChart from '../../../FacilityPage/Profile/3DModel/amChart';
import WriteToAttribute from '../../../FacilityPage/Profile/Devices/WriteToAttribute';
import { useEntity } from "../../context";

const EndNodeSV = new EndNodeService();
const SpecificationSV = new SpecificationService();

function Index({ intl, deviceName, attributeName, dateTime, variableLable, name, unAssign,
    nodeName, deviceType, specificationId,
    bacnetObjectTypeAliasName, attributeAddress, facilityId,
    id, isWritable, aliasName, semanticUnitSymbol, value, nodeId }) {
    //hooks
    const { entity } = useEntity();
    //state
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [itemsChart, setItemsChart] = useState(undefined);
    const [openProperty, setOpenProperty] = useState(false);
    const [semanticShow, setSemanticShow] = useState(false);
    const [openChart, setOpenChart] = useState(false);
    const [openWriteToAttribute, setOpenWriteToAttribute] = useState(false);
    //const
    const menu = () => {
        return (
            <Menu>
                <Menu.Item onClick={() => handlePinToDashboard({})}>
                    <Icon type="pushpin" /> <IntlMessages id="label.pinToDashboard" />
                </Menu.Item>
                {entity.data.isManager && <Menu.Item>
                <Popconfirm
                        title="Are you sure?"
                        onConfirm={(e) => { unAssign([specificationId]) }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <div style={{ width: "100%" }}><CloseCircleOutlined /> <IntlMessages id="label.unAssign" /></div>
                    </Popconfirm>
                
                </Menu.Item>}
                {entity.data.isManager && <Menu.Item
                    disabled={deviceType !== "BACnet" || !entity.data.isManager}
                    onClick={() => setOpenProperty({ bacnetObjectTypeAliasName: bacnetObjectTypeAliasName, attributeAddress: attributeAddress })}>
                    <Icon type="info-circle" />  <IntlMessages id="label.bacnetPorperty" />
                </Menu.Item>}
                {entity.data.isManager && <Menu.Item onClick={() => openEditSpecification({})}>
                    <Icon type="edit" />  <IntlMessages id="label.specification" />
                </Menu.Item>}
            </Menu>
        )
    }
    const CustomTooltip = ({ active, payload, label }) => {
        if (active) {
            return (
                <div className="custom-tooltip" style={{ backgroundColor: "rgba(255,255,255,0.7)", border: "1px solid #999999", borderRadius: "5px", padding: "5px", height: "65px" }}>
                    <p className="intro" style={{ color: "#fa8817", opacity: 1 }}>{payload ? payload[0].payload.value : null}</p>
                    <p className="label" style={{ color: "#10316b", opacity: 1 }}>{payload ? new Date(payload[0].payload.dateTime).toLocaleString() : null}</p>
                </div>
            );
        }

        return null;
    };
    //functions
    const handlePinToDashboard = () => {
        setLoading(true);
        let body = {
            facilityDeviceEndNodeId: nodeId,
            lable: attributeName
        }
        EndNodeSV.pinToDashboard(body)
            .then(res => {
                if (res.header.metaResponse !== null) {
                    message[res.header.responseCode === 0 ? "success" : "warning"](intl.formatMessage({ id: res.header.metaResponse }))
                }
                setLoading(false);
            })
            .catch(err => setLoading(false))
    }
    const getItemsChart = () => {
        setLoading(true);
        let bodyString = "timeRange=h6&granularityDuration=15&isContinuous=true&queryType=lastvalue";
        EndNodeSV.getAttributeValueAdvance(nodeId, attributeName, bodyString, "AmazingSnapshotGet")
            .then((res) => {
                if (res.data && res.data.list) {
                    setItemsChart(res.data.list.map((newDtaEl) => {
                        return {
                            dateTime: newDtaEl.endDate,
                            value: newDtaEl.outputValue
                        }
                    }))
                }

                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            })

    }

    const openEditSpecification = (item) => {
        SpecificationSV.getOne(nodeId, attributeName)
            .then(res => {
                if (res && res.data) {
                    setSemanticShow({
                        index: 0,
                        "ids": [id],
                        "facilityDeviceEndNodeId": res.data.facilityDeviceEndNodeId,
                        "variableLable": res.data.variableLable,
                        "deviceType": res.data.deviceType,
                        "accessLevel": res.data.accessLevel,
                        "privacyLevel": res.data.privacyLevel,
                        "aliasName": res.data.aliasName,
                        "variableLable": res.data.variableLable,
                        "priorityLevel": res.data.priorityLevel,
                        "facilityId": facilityId,
                        "a": res.data.a,
                        "b": res.data.b,
                        "c": res.data.c,
                        "d": res.data.d,
                        "deviceType": res.data.deviceType,
                        "semanticId": res.data.semanticId,
                        "semanticUnitId": res.data.semanticUnitId,
                        "semanticName": res.data.semanticName,
                        "semanticUnitName": res.data.semanticUnitName,
                        "getDomainExpertiseModels": res.data.getDomainExpertiseModels
                    }
                    );
                } else if (res && res.header && res.header.responseCode === 2) {
                    setSemanticShow(
                        {
                            index: 0,
                            "facilityDeviceEndNodeId": nodeId,
                            "aliasName": aliasName,
                            "variableLable": variableLable,
                            "accessLevel": null,
                            "privacyLevel": null,
                            "a": 0,
                            "b": 0,
                            "c": 1,
                            "d": 0,
                            "semanticId": null,
                            "semanticUnitId": null,
                            getDomainExpertiseModels: []
                        }
                    );
                }
            })
    }
    const openItem = async () => {
        if (entity.data.isFacilityMember) {
            if (!open) {
                await getItemsChart();
            }
            await setOpen(!open);
        }


    }
    return (
        <div className="attribute-card">
            {openProperty && <BACnetProperty visible={openProperty} setVisible={setOpenProperty} />}
            {openChart && <AmtChart visible={openChart} setVisible={setOpenChart} />}
            {openWriteToAttribute && <WriteToAttribute visible={openWriteToAttribute} setVisible={setOpenWriteToAttribute} get={() => { }} />}

            {semanticShow && <AddSemantice
                getItems={() => { }}
                endNodeId={nodeId}
                visible={semanticShow} setVisible={setSemanticShow} />}

            <div className="header-card" onClick={openItem}>
                <div className="information">
                    <div className="values-data">
                        <div className="value">
                            {value}&nbsp;{semanticUnitSymbol}
                        </div>
                        <div className="date-time">
                            {moment(dateTime).isValid() && moment(dateTime).format('MM/DD/YYYY, HH:mm')}
                        </div>
                    </div>
                    <div className="names">
                        {aliasName || attributeName}<br />
                        <div className="device-name">{nodeName}</div>
                    </div>
                </div>
                <div className="more-actions">

                </div>
            </div>
            {open && <div className="content-card">
                <div className="acions-icons">
                    <div className="acions-icons-item"
                        onClick={() => setOpenChart({
                            name,
                            attributeName,
                            nodeId,
                            deviceName,
                            nodeName,
                            aliasName,
                            semanticUnitSymbol

                        })}
                    ><IntlMessages id="btn.chart" /></div>
                    <div className="divider-more-container">
                        <div className="divider-more" />
                    </div>
                    <div className={`acions-icons-item ${!isWritable && "disabled"}`}
                        onClick={() => setOpenWriteToAttribute(specificationId)}
                    ><IntlMessages id="btn.command" /></div>
                    <div className="divider-more-container">
                        <div className="divider-more" />
                    </div>
                    <div className="acions-icons-item">
                        <Dropdown overlay={menu} trigger={['click']}>
                            <a style={{ color: "unset" }}><IntlMessages id="btn.more" /></a>
                        </Dropdown>
                    </div>
                </div>
                <Spin spinning={loading}>
                    <div className="last-chart">
                        {itemsChart ? itemsChart.length > 0 ? <ResponsiveContainer width="100%" height={100}>
                            <AreaChart data={itemsChart}
                                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                                <Tooltip content={<CustomTooltip />} />
                                <XAxis dataKey='value' allowDecimals />
                                <defs>
                                    <linearGradient id="color15" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#38AAE5" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#F5FCFD" stopOpacity={0.8} />
                                    </linearGradient>
                                </defs>
                                <Area dataKey='value' strokeWidth={2} stackId="2" stroke='#10316B' fill="url(#color15)"
                                    fillOpacity={1} />
                            </AreaChart>
                        </ResponsiveContainer> :
                            <div className="no-data-in-chart-dashboard" style={{ textAlign: "center" }}>
                                <IntlMessages id="label.dashboard.noData.attribute.cahart" />
                            </div> : <div className="no-data-in-chart-dashboard" />}
                    </div>
                </Spin>
            </div>}
        </div>
    )
}


export default injectIntl(Index);