import React, { useEffect, useState } from 'react';
import { Collapse, Spin, Row, Col } from 'antd4';

import IntlMessages from 'util/IntlMessages';
import { useEntity } from '../../context';
import InsightCard from './InsightCard';
import axios from 'util/Api';
import {responsiveMode} from '../../responsiveMode';

function Index(props) {
    //hooks
    const { entity } = useEntity();
    //state
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const getItems = () => {
        setLoading(true);
        if(localStorage.getItem("user")){
            entity.service.getEntityInsight(entity.data.code)
            .then(res => {
                if (res.data) {
                    setItems(res.data)
                }
                setLoading(false);
            })
            .catch(err => setLoading(false))
        }else{
            axios.get('GetEntityInsight',{
                params:{
                    code: entity.data.code
                }
            })
            .then(res => {
                if (res.data) {
                    setItems(res.data.data)
                }
                setLoading(false);
            })
            .catch(err => setLoading(false))
        }
        
        
    }
    useEffect(() => {
        if (entity.data && entity.data.code) getItems();
    }, [entity.data])
    return (

        <div className='live-date-attribute'>
            <Spin spinning={loading}>
                <Row gutter={[15, 15]}>
                    {items && items.map((item, index) => (
                        <Col key={index}  {...responsiveMode(entity.type)} >
                            <InsightCard getItems={getItems} {...item}/>
                        </Col>
                    ))}
                </Row>
            </Spin>
        </div>
    )
}


export default Index;