import React from "react";
import ReactDOM from "react-dom";

import { Button, Checkbox, Spin, Form, Input, message, Modal } from "antd";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { userSignIn } from "../appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";
import ReCAPTCHA from "react-google-recaptcha";
import Helmet from 'react-helmet';
import { injectIntl } from 'react-intl'
import { Account } from '../services';


const service = new Account();

const FormItem = Form.Item;


class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: {
        Username: "",
        Password: "",
        verfityCode: ""
      },
      login: false,
      loading: false
    };
  }

  onChange = (e) => {
    var item = this.state.item;
    item[e.target.id] = e.target.value;
    this.setState({ item });
  }

  loginChange = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ loading: true })
        service.login({ Username: this.state.item.Username, Password: this.state.item.Password })
          .then(res => {
            if (res.data.header.responseCode === 0) {
              if (res.data.header.metaResponse !== null) {
                message.success(this.props.intl.formatMessage({ id: res.data.header.metaResponse }))

              }

              if (typeof (res.data.data) === "object" && res.data.data.token) {
                localStorage.setItem("token", res.data.data.token);
                localStorage.setItem("refreshToken", res.data.data.refreshToken);
                localStorage.setItem("user", JSON.stringify(res.data.data));
                if (sessionStorage.getItem('BackUrl')) {
                  window.location.replace(sessionStorage.getItem('BackUrl'));
                } else {
                  window.location.replace('/');
                }
              } else {
                this.setState({ login: res.data.data, loading: false });
              }
            } else {
              this.setState({ loading: false })
              if (res.data.header.metaResponse !== null) {
                message.warning(this.props.intl.formatMessage({ id: res.data.header.metaResponse }))
              }
            }
          })
          .catch(err => {
            console.log(err)
            this.setState({ loading: false })
          })
      }
    });

  }
  componentWillReceiveProps() {
    if (this.props.match.params.token) {
      this.setState({ login: this.props.match.params.token, loading: false });
    }
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        service.activeCode({ Token: this.state.login, code: parseInt(this.state.item.verfityCode) })
          .then(res => {
            if (res.data.header.responseCode === 0) {
              if (res.data.header.metaResponse !== null) {
                message.error(this.props.intl.formatMessage({ id: res.data.header.metaResponse }))
              }
              localStorage.setItem("token", res.data.data.token);
              localStorage.setItem("refreshToken", res.data.data.refreshToken);
              localStorage.setItem("user", JSON.stringify(res.data.data));
              if (sessionStorage.getItem('BackUrl')) {
                window.location.replace(sessionStorage.getItem('BackUrl'));
              } else {
                window.location.replace('/');
              }
            } else {
              this.setState({ loading: false })
              if (res.data.header.metaResponse !== null) {
                message.warning(this.props.intl.formatMessage({ id: res.data.header.metaResponse }))
              }
            }
          })
          .catch(err => {
            console.log(err)
            this.setState({ loading: false });
          });

      }
    });
  };

  componentDidUpdate() {
    if (this.props.token !== null) {
      this.props.history.push('/');
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="gx-app-login-wrap">
        <Helmet>
          <title>twinup | Sign In</title>
        </Helmet>
        <div className="gx-app-login-container">
          <Spin spinning={this.state.loading}>

            <div className="gx-app-login-main-content">
              <div className="gx-app-logo-content">
                <div className="gx-app-logo-content-bg">
                  {/* <img src="https://via.placeholder.com/272x395" alt='Neature'/> */}
                </div>
                {
                  this.state.login ?
                    <div className="gx-app-logo-wid">
                      <h1><IntlMessages id="app.verifyCode" /></h1>
                    </div>
                    :
                    <div className="gx-app-logo-wid">
                      <h1><IntlMessages id="app.userAuth.signIn" /></h1>

                      {/* <p><IntlMessages id="app.userAuth.bySigning"/></p> */}
                      {/* <p><IntlMessages id="app.userAuth.getAccount"/></p> */}
                    </div>
                }

                <div className="gx-app-logo">
                  <img alt="example" src={require("assets/images/logo.png")} />
                </div>
              </div>
              <div className="gx-app-login-content">
                {
                  this.state.login ?
                    <Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0">
                      <FormItem>
                        <div style={{ lineHeight: "23px" }}>
                          <IntlMessages id="form.verifyCode.title" />
                        </div>
                        {getFieldDecorator('verfityCode', {
                          rules: [{
                            required: true, len: 6, message: <IntlMessages id="form.verifyCode.input" />,
                          }],
                        })(
                          <Input placeholder="Enter verification code" onChange={this.onChange} />
                        )}

                      </FormItem>
                      <FormItem>
                        <Button type="primary" className="gx-mb-0" htmlType="submit">
                          <IntlMessages id="app.userAuth.login" />
                        </Button>
                      </FormItem>
                    </Form>
                    :
                    <Form onSubmit={this.loginChange} className="gx-signin-form gx-form-row0">
                      <FormItem>
                        {getFieldDecorator('Username', {
                          rules: [{
                            required: true, type: 'email', message: <IntlMessages id="form.signin.username.input" />,
                          }],
                        })(
                          <Input placeholder="Email Address" onChange={this.onChange} />
                        )}
                      </FormItem>
                      <FormItem>
                        {getFieldDecorator('Password', {
                          rules: [{ required: true, min: 7, message: <IntlMessages id="form.signin.password.input" /> }],
                        })(
                          <Input type="password" placeholder="Password" onChange={this.onChange} />
                        )}
                      </FormItem>
                      {/* <FormItem>
                    {getFieldDecorator('remember', {
                      valuePropName: 'checked',
                      initialValue: true,
                    })(
                      <Checkbox><IntlMessages id="appModule.iAccept"/></Checkbox>
                    )}
                    <span className="gx-signup-form-forgot gx-link"><IntlMessages
                      id="appModule.termAndCondition"/></span>
                  </FormItem> */}
                      {/* <FormItem>
                    <ReCAPTCHA
                      sitekey="6LcVaLMUAAAAAM64b2bOkTNG1gf3N-1qdpZTiLME"
                      onChange={this.onChange}
                    />
                  </FormItem>                 */}

                      <FormItem>
                        <Button type="primary" className="gx-mb-0" htmlType="submit">
                          <IntlMessages id="app.userAuth.signIn" />
                        </Button>
                        <span><IntlMessages id="app.userAuth.or" /></span> &nbsp;&nbsp;
                    <Link to="/signup"><IntlMessages
                          id="app.userAuth.signUp" /></Link>
                      </FormItem>
                      <Link to="/resetpassword">
                        <IntlMessages id="app.userAuth.forgetPassword" />
                      </Link>
                    </Form>
                }

              </div>
            </div>
          </Spin>
        </div>
      </div>

    );
  }
}

const WrappedNormalLoginForm = Form.create()(SignIn);

const mapStateToProps = ({ auth }) => {
  const { token } = auth;
  return { token }
};

export default injectIntl(connect(mapStateToProps, { userSignIn })(WrappedNormalLoginForm));
