import { BASE_URL, HEADERS } from './index';
import axios from 'axios';
import {message} from 'antd';

const api = "user/FacilityCategory"

const qs = require('querystring')

export default class CategoryService {
    getByFacilityId(facilityId){
        return axios.get(`${BASE_URL}${api}?facilityId=${facilityId}`,HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));         
    }
    getById(id){
        return axios.get(`${BASE_URL}${api}?id=${id}`,HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));         
    }
    add(data){
        return axios.post(`${BASE_URL}${api}`, data, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    edit(data){
        return axios.put(`${BASE_URL}${api}`, data, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    delete(id){
        return axios.delete(`${BASE_URL}${api}/${id}`, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    getTimeZone(){
        return axios.get(`${BASE_URL}user/TimeZone`,HEADERS)
          .then(res => {
              if(res.data.header.responseCode === 0){
                  return res.data
              }
              else{
                  message.warning(res.data.header.message, 4);
              }
          })          
          .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));

    }
    getMessage(pageNumber){
        return axios.get(`${BASE_URL}${api}DeviceMessage?pageSize=100&pageNumber=${pageNumber.toString()}`,HEADERS)
          .then(res => {
              if(res.data.header.responseCode === 0){
                  return res.data
              }
              else{
                  message.warning(res.data.header.message, 4);
              }
          })          
          .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));

    }
}

