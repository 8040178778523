import React, { useEffect, useState } from 'react';
import { Form, Input, Modal, Spin, Button, Row, Col, Divider, Select } from 'antd4';
import { injectIntl } from 'react-intl'
import { PlusOutlined } from '@ant-design/icons';
import { EntityService, FacilityEntityInsight, DomainService } from 'services';
import IntlMessages from "util/IntlMessages";

const DomainSV = new DomainService();
const service  = new FacilityEntityInsight();

const { Option } = Select;

const formItemLayout = {
    wrapperCol: { span: 24 },
    layout: 'vertical'
};
const responsiveMode = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
    xl: { span: 24 },
    xxl: { span: 24 },
}

function Index({ visible, setVisible, intl }) {
    const [form] = Form.useForm();
    const [domains, setDomains] = useState([]);
    const [accessLevel, setAccessLevel] = useState(visible.accessLevel)
    const [loading, setLoading] = useState(false);
    const onFinish = values => {
        setLoading(true);
        service.editAccessLevel(
            {
                "id": visible.id,
                "accessLevel":values.accessLevel,
                "domainExpertiseIds": values.domainExpertiseIds
              }
        )
        .then((res)=>{
            if(res){
                setLoading(false);
                setVisible(false)
            }
        })
        .catch((err)=>{
            setLoading(false);
        })
    }

    const onChange = (e) =>{
        if(e.id === "accessLevel"){
            setAccessLevel(e.value)
        }
    }
    const getDomains = () => {
        DomainSV.getAll(visible.facilityId)
          .then(res => {
            if (res && res.data) {
              setDomains(res.data);
            }
          })
      }
    useEffect(() => {
        getDomains();
        if (visible) {
            form.setFieldsValue({
                "accessLevel":visible.accessLevel,
                "domainExpertiseIds": visible.domainExpertiseIds.map((el)=>(el.id))
            });
        }
        // eslint-disable-next-line
    }, [])
    return (
        <Modal
            visible={visible}
            title={<IntlMessages id="inisght.accessLevel" />}

            footer={false}
            onCancel={() => setVisible(false)}
        >
            <Spin spinning={loading}>
                <Form className="new-from" name="add" form={form} {...formItemLayout} onFinish={onFinish}>
                    <Row gutter={[5, 0]}>
                        <Col {...responsiveMode}>                            
                            <Form.Item
                                name="accessLevel"
                                rules={[{ required: true, message: intl.formatMessage({ id: "form.required" }) }]}

                            >
                                <Select
                                   style={{ width: "100%" }}
                                   placeholder="Access Level"
                                   optionFilterProp="children"
                                   onChange={(v) => setAccessLevel(v)}
                                   >
                                      <Option value="All"><IntlMessages id="label.all" /></Option>
                                        <Option value="ManagersOnly"><IntlMessages id="label.managerOnly" /></Option>
                                        <Option value="OccupantsOnly"><IntlMessages id="label.occupantsOnly" /></Option>
                                        <Option value="managersAndOccupants"><IntlMessages id="label.managersAndOccupants" /></Option>
                                        <Option value="ManagersAndDomainExperts"><IntlMessages id="label.managersAndDomainExperts" /></Option>

                                       </Select>
                            </Form.Item>                          
                            {accessLevel === "ManagersAndDomainExperts" && <Form.Item
                                name="domainExpertiseIds"
                                rules={[{ required: true, message: intl.formatMessage({ id: "form.required" }) }]}

                            >
                                <Select
                                   style={{ width: "100%" }}
                                   placeholder="Domain Expertises"
                                   optionFilterProp="children"
                                   onChange={(v) => onChange({ target: { id: "domainExpertiseIds", value: v } })}
                                   mode="multiple"
                                   >
                                        {domains.map(domain => {
                                                    return (
                                                        <Option value={domain.id} label={domain.name}>
                                                            {domain.name}
                                                        </Option>
                                                    )
                                                })


                                                }
                                       </Select>
                            </Form.Item>}
                        </Col>
                        <Form.Item>
                            <Button type="primary" block form="add" key="submit" htmlType="submit">
                                {intl.formatMessage({ id: "button.save" })}
                            </Button>
                        </Form.Item>
                    </Row>
                </Form>
            </Spin>
        </Modal>
    )
}

export default injectIntl(Index)