import { BASE_URL, HEADERS } from './index';
import axios from 'axios';
import { message } from 'antd';

const api = "user/FacilityEntityProperty"

const qs = require('querystring')

export default class Property {
    getList(body){
        return axios.post(`${BASE_URL}${api}/GetList`, body,HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4)); 
    }
    checkUsed(body){
        return axios.get(`${BASE_URL}${api}/CheckPropertyUsed`, {
            params:body
        },HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4)); 
    }
    getFilteredBySemantic(body){
        return axios.get(`${BASE_URL}${api}/GetFilteredBySemantic`, {
            params:body
        },HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4)); 
    }
    add(body){
        return axios.post(`${BASE_URL}${api}`, body,HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4)); 
    }
    edit(body){
        return axios.put(`${BASE_URL}${api}`, body,HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4)); 
    }
    delete(id){
        return axios.delete(`${BASE_URL}${api}/${id}`,HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4)); 
    }

}

