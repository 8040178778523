import {ADD_ALERT_ACTION, ADD_END_NODE, ADD_DEVICE, ADD_BUTTON_SHOW, GET_FACILITY} from "../../constants/ActionTypes";

const INIT_STATE = {
  addDeviceShow: false,
  addAlertActionShow: false,
  addEndNodeShow: false,
  addButtonShow: false,
  facility:false

};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_DEVICE: {
      return {
        ...state,
        addDeviceShow: !state.addDeviceShow,
        addAlertActionShow: false,
        addEndNodeShow: false
      }
    }
    case ADD_END_NODE: {
      return {
        ...state,
        addDeviceShow: false,
        addAlertActionShow: false,
        addEndNodeShow: !state.addEndNodeShow
      }
    }
    case ADD_ALERT_ACTION: {
      return {
        ...state,
        addDeviceShow: false,
        addAlertActionShow: !state.addAlertActionShow,
        addEndNodeShow: false
      }
    }

    case ADD_BUTTON_SHOW: {
      return {
        ...state,
        addButtonShow: action.payload
      }
    }
    case GET_FACILITY: {
      return {
        ...state,
        facility: action.payload
      }
    }

    default:
      return state;
  }
}
