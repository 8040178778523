import React, { useEffect, useState } from 'react';
import { Form, Input, Modal, Spin, Button, Row, Col, Divider, Select } from 'antd4';
import { injectIntl } from 'react-intl'
import { PlusOutlined } from '@ant-design/icons';
import { EntityService, FacilityEntityInsight, DomainService } from 'services';
import IntlMessages from "util/IntlMessages";
import { useEntity } from '../../context';

const DomainSV = new DomainService();
const service = new FacilityEntityInsight();

const { Option } = Select;

const formItemLayout = {
    wrapperCol: { span: 24 },
    layout: 'vertical'
};
const responsiveMode = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
    xl: { span: 24 },
    xxl: { span: 24 },
}

function Index({ visible, setVisible, intl, getItem }) {
    const { entity } = useEntity();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const onFinish = values => {
        setLoading(true);
        entity.service.updateCoordinate(
            {
                "facilityId": entity.data.facilityId,
                "items": [
                    {
                        "code": entity.data.code,
                        "xyz": JSON.stringify(visible.xyz),
                        "dx": visible.dx,
                        "dy": visible.dy,
                        "dz": visible.dz,
                        "errorLocation": parseFloat(values.errorLocation),
                        "errorDiretion": visible.errorDiretion
                    }
                ]
            }
        )
            .then(res => {
                setVisible(false);
                getItem();
            })
            .catch(err => {
                setLoading(false)
            })
    }



    useEffect(() => {
        form.setFieldsValue({
            errorLocation: visible.errorLocation
        })
    }, [visible])
    return (
        <Modal
            visible={visible}
            title={<IntlMessages id="label.editCoordinatesErrorRadius" />}

            footer={false}
            onCancel={() => setVisible(false)}
        >
            <Spin spinning={loading}>
                <Form

                    className="new-from"
                    name="edit-editCoordinatesErrorRadius"
                    form={form}
                    onFinishFailed={(e) => console.log(e, "err")}

                    {...formItemLayout} onFinish={onFinish}>
                    <Row gutter={[5, 0]}>
                        <Col {...responsiveMode}>

                            <Form.Item
                                name="errorLocation"
                                rules={[{ required: true, message: intl.formatMessage({ id: "form.required" }) }]}

                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Button
                            type="primary" block form="edit-editCoordinatesErrorRadius" key="submit" htmlType="submit">
                            {intl.formatMessage({ id: "button.save" })}
                        </Button>
                    </Row>
                </Form>
            </Spin>
        </Modal>
    )
}

export default injectIntl(Index)