/*eslint-disable no-undef*/

import React, { useEffect, useState } from "react";
import IntlMessages from 'util/IntlMessages';
import { Button, Popconfirm, Spin } from 'antd4';
import { useEntity } from "../../context";
import { GrEdit, GrUpdate } from 'react-icons/gr'
import AddSprite from './AddSprite';


function Index() {
    const { entity, changeType } = useEntity();
    //state
    const [item, setItem] = useState();
    const [loading, setLoading] = useState(false);
    const [openEditCoordinate, setOpenEditCoordinate] = useState(false);
    //functions
    const getItem = () => {
        setLoading(true);
        entity.service.getLocation2D({
            facilityId: entity.data.facilityId,
            fileId: entity.twoDFile,
            entityCode: entity.data.code
        })
            .then(res => {
                let newItem = res.data[0];
                try {
                    newItem.xyz = JSON.parse(newItem.xy);
                }
                catch {
                    newItem.xyz = { x: null, y: null, z: null };
                }
                setLoading(false);
                setItem(newItem);

            })
            .catch(err => {
                setLoading(false);
            })
    }
    const removeSprite = ()=>{
        setLoading(true);
        entity.service.deleteTwoDLocation({
            entityCode:entity.data.code,
            fileId: entity.twoDFile
        })
        .then(res=>{
            window.location.reload();
            setLoading(false);
        })
        .catch(err=>{
            setLoading(false);
        })

    }
    //effect
    useEffect(() => {
        if (entity.data && entity.data.code) getItem();
    }, [entity.data]);
    return (
        <div className="location-tab">
            {openEditCoordinate && <AddSprite
                getItem={getItem}
                twoDFile={entity.twoDFile}
                visible={openEditCoordinate}
                setVisible={setOpenEditCoordinate} />}


            <Spin spinning={loading}>
                <Popconfirm
                    title={<IntlMessages id="label.areYouSure" />}
                    onConfirm={() => removeSprite()}
                    onCancel={()=>{}}
                    okText="Yes"
                    cancelText="No"
                >
                    <div className="remove-sprite-btn">
                        <IntlMessages id="label.removeSprite" />
                    </div>
                </Popconfirm>

                <div className="coordinates">
                    {item && item.xyz && <div className="coordinates-item">
                        <div style={{ display: "flex", columnGap: "10px" }}>
                            <IntlMessages id="label.coordinates" /><div
                                onClick={() => setOpenEditCoordinate({
                                    id: entity.data.id,
                                    xy: item.xyz
                                })}
                                className="edit-able"><GrEdit /></div>

                        </div>
                        <div className="part-two">X={item && item.xyz && parseFloat(item.xyz.x).toFixed(2)}</div>
                        <div className="part-two">Y={item && item.xyz && parseFloat(item.xyz.y).toFixed(2)}</div>

                    </div>}

                </div>
            </Spin>

        </div>
    )
}

export default Index;