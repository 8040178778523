
import React, { useEffect } from 'react';
import IntlMessages from "util/IntlMessages";
import { Spin } from 'antd';
import { Link } from "react-router-dom";
import Tabs from './Tabs';
import { useEntity } from '../context';
import { AiOutlineHome } from 'react-icons/ai';

const Index = () => {
    //hooks
    const { entity, getInfo } = useEntity();
    return (
        <div className='entity-panel'>
            <Spin spinning={entity.loading}>
                <div className='entity-panel-header'>
                    {entity.type === "page" && <Link to="/dashboard"><div className='logo-entity'>
                        <AiOutlineHome />
                        <img alt="" src={require("assets/images/logo.png")} width="120px" />
                    </div>
                    </Link>}
                    <div className='left-header'>
                        {entity.type === "page" ? <div className='entity-name'>
                            {entity.data && entity.data.name}
                        </div> :
                            <Link
                                target="_blank"
                                rel="noopener noreferrer"
                                to={`/entitypanel/livedata/${entity.data && entity.data.code}`}>
                                <div className='entity-name-click'>
                                    {entity.data && entity.data.name}
                                </div>
                            </Link>
                            // <a
                            //     target='_blank'
                            //     href={`www.goog.com/entitypanel/livedata/${entity?.data?.code}`}
                            //     className='entity-name'>
                            //     {entity.data && entity.data.name}
                            // </a>
                        }
                        <div className='facilty-name'>
                            {entity.data && entity.data.facilityName}
                        </div>
                    </div>
                </div>
                <Tabs />
            </Spin>
        </div>
    );
};

export default Index;
