import { BASE_URL, HEADERS } from './index';
import axios from 'axios';
import {message} from 'antd';


const api = "user/FacilityDeviceEndNode"
const apiValues = "user/FacilityDeviceEndNodeValue/"

export default class EndNodeService {
    getAll(){
        return axios.get(`${BASE_URL}${api}/GetFiltered`,HEADERS)
        .then(res => {
            if(res.data.header.responseCode === 0){
                return res.data;
            }
            else{
                message.warning(res.data.header.message, 4);
            }
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 2));         
    }
    getById(endNodeId, deviceId, facilityId){
        return axios.get(`${BASE_URL}${api}/GetFiltered?facilityId=${facilityId}&facilityDeviceEndNodeId=${endNodeId}&facilityDeviceId=${deviceId}`,HEADERS)
        .then(res => {
            if(res.data.header.responseCode === 0){
                return res.data;
            }
            else{
                message.warning(res.data.header.message, 4);
            }
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 2));         
    }
    getAllEndNodes (id){
        return axios.get(`${BASE_URL}${api}/GetAllFiltered?facilityId=${id}`,HEADERS)
        .then(res => {
            if(res.data.header.responseCode === 0){
                return res.data;
            }
            else{
                message.warning(res.data.header.message, 4);
            }
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 2));
    }
    getEndNodeByFacilityId(id){
        return axios.get(`${BASE_URL}${api}?facilityId=${id}`,HEADERS)
        .then(res => {
            if(res.data.header.responseCode === 0){
                return res.data;
            }
            else{
                message.warning(res.data.header.message, 4);
            }
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 2));         
    }
    getModels(id){
        return axios.get(`${BASE_URL}${api}/GetModelNumbers?facilityId=${id}`,HEADERS)
        .then(res => {
            if(res.data.header.responseCode === 0){
                return res.data;
            }
            else{
                message.warning(res.data.header.message, 4);
            }
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 2));         
    }
    getDeviceId(id,facilityId){
        return axios.get(`${BASE_URL}${api}/GetFiltered?facilityDeviceId=${id}&facilityId=${facilityId}`,HEADERS)
        .then(res => {
            if(res.data.header.responseCode === 0){
                return res.data;
            }
            else{
                message.warning(res.data.header.message, 4);
            }
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 2));         
    }
    getDeviceIdAll(id,facilityId){
        return axios.get(`${BASE_URL}${api}?facilityDeviceId=${id}&facilityId=${facilityId}`,HEADERS)
        .then(res => {
            if(res.data.header.responseCode === 0){
                return res.data;
            }
            else{
                message.warning(res.data.header.message, 4);
            }
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 2));         
    }
    add(data){
        return axios.post(`${BASE_URL}${api}`, data, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 2));
    }
    edit(data){
        return axios.put(`${BASE_URL}${api}`, data, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    changeDevice(data){
        return axios.put(`${BASE_URL}${api}/ChangeDevice`, data, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    changeAllDevice(data){
        return axios.put(`${BASE_URL}${api}/ChangeAllDevice`, data, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    delete(id){
        return axios.delete(`${BASE_URL}${api}/${id}`, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    unPin(body){
        return axios.delete(`${BASE_URL}${api}/UnPinToDashboard?nodeId=${body.nodeId}&lable=${body.lable}`, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    getEndNodeLibrary(type){
        return axios.get(`${BASE_URL}user/EndNodeLibrary?libraryType=${type}`,HEADERS)
        .then(res => {
            if(res.data.header.responseCode === 0){
                return res.data;
            }
            else{
                message.warning(res.data.header.message, 4);
            }
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 2));   
    }
    addValue(data){
        return axios.post(`${BASE_URL}${api}/AddValue`, data, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 2));
    }
    pinToDashboard(data){
        return axios.post(`${BASE_URL}${api}/PinAttributeToDashboard`, data, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 2));
    }
    getLastValue(id){
        return axios.get(`${BASE_URL}${apiValues}GetFilteredLastValues/nodeId?nodeId=${id}`, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 2));
    }
    getLastValueByLabel(id, label){
        return axios.get(`${BASE_URL}${apiValues}GetFilteredLastValues/nodeId?nodeId=${id}&label=${label}`, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 2));
    }
    getLastValueAll(id){
        return axios.get(`${BASE_URL}${apiValues}GetLastValues/nodeId?nodeId=${id}`, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 2));
    }
    
    getAttributeValue(id, label, value){
        return axios.get(`${BASE_URL}${apiValues}GetFilteredAttributeAllValues?pageNumber=1&pageSize=${value?value : 30}&isAsc=false&nodeId=${id}&label=${label}`, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 2));
    }
    getAttributeValueModal(id, label, value){
        return axios.get(`${BASE_URL}${apiValues}GetFilteredAttributeAllValues?pageNumber=1&pageSize=${value?value : 10}&isAsc=false&nodeId=${id}&label=${label}`, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 2));
    }
    getSpeicificationValues(data){
        return axios.post(`${BASE_URL}${apiValues}GetFilteredSpecificationValues`, data, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 2));
    }
    getAttributeValueAdvance(endNodeId, lable, bodyString, url){
        return axios.get(`${BASE_URL}${apiValues}${url}?endNodeId=${endNodeId}&lable=${lable}&${bodyString}`, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 2));
    }    
    getAttributeByEndNode(id){
        return axios.get(`${BASE_URL}${apiValues}GetAttributes/endNodeId?endNodeId=${id}`, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 2));
    }
    getEndNodeBySemantic(facilityId, id, entityId){
        return axios.get(`${BASE_URL}${api}/GetFilteredBySemantic?facilityId=${facilityId}&SemanticId=${id}&entityId=${entityId}`, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 2));
    }


}

