import React, { useEffect, useState } from "react";
import IntlMessages from 'util/IntlMessages';
import { PlusOutlined, RiseOutlined, FallOutlined, FilterOutlined, SearchOutlined, DeleteOutlined, BgColorsOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { Button, Row, Col, Input, Checkbox, Spin, Select, Pagination, Container } from "antd4";
import { Popover } from 'antd';
import { useEntity } from '../../context';
import Item from './Item';
import { responsiveMode, responsiveModeTag, responsiveModePagination, responsiveModeSort, responsiveModeAdd, responsiveModeSearch } from '../../responsiveMode';
import Card from './TagCard';
import axios from 'util/Api';
import { RiEqualizerLine } from 'react-icons/ri';
import { EntityService, FacilityTag, DomainService } from 'services';

const { Option } = Select;
const serviceTags = new FacilityTag();

function Index() {
    //hook
    const { entity } = useEntity();
    //state
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false)
    const [users, setUsers] = useState(false);
    const [selectUsers, setSelectUsers] = useState([]);
    const [items, setItems] = useState([]);
    const [sort, setSort] = useState("isAscDateTrue");
    const [openItem, setOpenItem] = useState(false);
    const [query, setQuery] = useState({
        "entityCode": entity.data.code,
        "searchTerm": "",
        "isAscDate": true,
        "isAscName": false,
        "size": 20,
        "page": 1
    });
    const [total, setTotal] = useState(0);
    const onChangeAuther = (e) => {
        setSelectUsers(e);
    }
    //dropdown
    
    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };
    const getItems = (params) => {
        setLoading(true);
        serviceTags.getAssignList({
            ...params
        })
            .then(res => {
                if (res.data) {
                    console.log(res.data)
                    setItems(res.data.list)
                    setTotal(res.data.total)
                    setQuery({ ...params, page: 1 })
                }
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
            })


    }
    const changeSort = (e) => {
        setSort(e)
        switch (e) {
            case "isAscDateTrue":
                getItems({ ...query, isAscDate: true, isAscName: undefined })
                break;
            case "isAscDateFalse":
                getItems({ ...query, isAscDate: false, isAscName: undefined })
                break;
            case "isAscNameTrue":
                getItems({ ...query, isAscDate: undefined, isAscName: true })
                break;
            case "isAscNameFalse":
                getItems({ ...query, isAscDate: undefined, isAscName: false })

                break;
            default:
                break;
        }
    }
    //effect

    useEffect(() => {
        if (entity.data && entity.data.code) {

            getItems({
                "entityCode": entity.data.code,
                "searchTerm": "",
                "isAscDate": true,
                "isAscName": false,
                "size": 20,
                "page": 1
            })
        }
    }, [entity])
    return (
        <div className="attachment-tabs">
            {openItem && <Item visible={openItem} setVisible={setOpenItem} getItems={() => getItems(query)} />}
            <Row gutter={[15, 5]}>
                {entity.data && (entity.data.isManager) && <Col  {...responsiveModeAdd(entity.type)}>
                    <Button
                        style={{ width: "180px" }}
                        onClick={() => setOpenItem(true)}
                        icon={<PlusOutlined />}
                        type="primary"
                    >
                        &nbsp;<IntlMessages id="assign" />
                    </Button>
                </Col>}
                <Col
                    {...responsiveModeSearch(entity.type)}
                >
                    <div className="input-search">
                        <Input
                            placeholder="Search in tags"
                            allowClear={true}
                            onChange={(e) => { getItems({ ...query, searchTerm: e.target.value }) }}
                            suffix={<SearchOutlined />} />
                    </div>
                </Col>

                <Col {...responsiveModeSort(entity.type)}>
                    <div className="filter-styles">
                        <Select defaultValue={sort}
                            onChange={(e) => changeSort(e)}
                        >
                            <Option value={"isAscDateTrue"}>
                                <RiseOutlined />&nbsp; <IntlMessages id="isAscDate" />
                            </Option>
                            <Option value={"isAscDateFalse"}>
                                <FallOutlined /> &nbsp;<IntlMessages id="isDescDate" />
                            </Option>
                            <Option value={"isAscNameTrue"}>
                                <RiseOutlined />&nbsp;<IntlMessages id="isAscName" />
                            </Option>
                            <Option value={"isAscNameFalse"}>
                                <FallOutlined /> &nbsp;<IntlMessages id="isDescName" />
                            </Option>
                        </Select>
                    </div>


                </Col>
                {/* <Col {...responsiveModeSort(entity.type)}>
                    <div className="filter-styles">
                        <Select defaultValue={query.isAscTitle}
                            onChange={(e) => getItems({ ...query, isAscTitle: e })}
                        >
                            <Option value={true}>
                                <IntlMessages id="isAscTitle" />
                            </Option>
                            <Option value={false}>
                                <IntlMessages id="isDescTitle" />
                            </Option>
                        </Select>
                    </div>


                </Col> */}
                <Col
                    justifyContent="flex-end"
                    {...responsiveModePagination(entity.type)}
                >
                    <div className="pagination-styles">
                        {total} Tags
                        {total > 20 && <Pagination
                            size="small"
                            onChange={(e) => getItems({ ...query, page: e })}
                            total={total}
                            simple
                            pageSize={20}
                            defaultCurrent={1}
                        />}
                    </div>

                </Col>
            </Row>

            <Spin spinning={loading}>
                <Row gutter={[15, 15]} wrap={false}>
                    {items && items.map((item, index) => (
                        <Col key={index}  {...responsiveModeTag(entity.type)}>
                            <Card {...item} getItems={() => getItems(query)} />
                        </Col>
                    ))}
                </Row>
            </Spin>

        </div>
    )
}

export default Index;