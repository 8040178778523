import { BASE_URL, HEADERS } from './index';
import axios from 'axios';
import {message} from 'antd';

const api = "user/FacilityEntityInsight";

export default class FacilityService {
    getInsightLibraries(id){
        return axios.get(`${BASE_URL}${api}/GetInsightLibraries?facilityId=${id}`,HEADERS)
        .then(res => {
            return res.data
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));         
    }
    getInsightRequirements(id){
        return axios.get(`${BASE_URL}${api}/GetInsightRequirements?entityInsightId=${id}`,HEADERS)
        .then(res => {
            return res.data
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));         
    }
    insightActivation(data){
        return axios.put(`${BASE_URL}${api}/InsightActivation`, data, HEADERS)
        .then(res => {
            return res.data
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    resetInisghts(data){
        return axios.put(`${BASE_URL}${api}/ResetInisghts`, data, HEADERS)
        .then(res => {
            return res.data
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    editInsightRequirement(data){
        return axios.put(`${BASE_URL}${api}/EditInsightRequirement`, data, HEADERS)
        .then(res => {
            return res.data
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    facilityEntityInsightValue(entityInsightLibraryId, bodyString, url){
        return axios.get(`${BASE_URL}user/FacilityEntityInsightValue/${url}?entityInsightLibraryId=${entityInsightLibraryId}&${bodyString}`, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 2));
    }  
    editAccessLevel(body){
        return axios.put(`${BASE_URL}user/FacilityEntityInsight/EditAccessLevel`,body, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 2));
    }  
    delete(id){
        return axios.delete(`${BASE_URL}user/FacilityEntityInsight/${id}`,null, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 2));
    }
}

