import React from 'react';
import QrCode from 'components/Qrcode';

function Index(props) {
    console.log(props, "item index->props")
    return (
        <div id={props.id}
            style={{ display: "none"
        }}
        >
            {props.items !== undefined && props.items.map((item, index) => {
                return (
                    <div style={{ height: "100%" }} key={index}>
                            <div style={{
                                fontWeight: 600, fontSize: "48px", textAlign: "center", 
                                // maxWidth: "480px",
                                // overflowWrap: "anywhere"
                            }}>
                                {item && item.name || ""}
                            </div>
                            <div style={{  fontSize: "28px",textAlign: "center", marginBottom: "10px" }}>
                                {item && item.locationType || ""}
                            </div>

                        <div style={{ display: "grid", justifyContent: "center", marginBottom: "10px" }}>
                            <div style={{ width: "480px", height: "480px" }}>
                                <QrCode item={item} size={"480px"} />
                            </div>
                        </div>

                        <div style={{ textAlign: "center" }}>
                            <img
                                width="480px"
                                height="122px"
                                alt="logo"
                                src={require("../../../../../assets/images/logo-qr-code.png")} />
                        </div>

                    </div>
                )
            }


            )}
        </div>
    )

}

export default Index;