import { BASE_URL } from './index';
import axios from 'axios';
import {message} from 'antd';

const qs = require('querystring')

export default class Account {
    login(data){
        return axios.post(`${BASE_URL}requestlogin`,data,{
          headers:{
            'content-type': 'application/json',
            }
        })
          .then(res => res);
    }
    activeCode(data){
        return axios.post(`${BASE_URL}login`, data,{
          headers:{
            "Content-Type": "application/json",
            }
        })
          .then(res => res) 
          .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    resgister(data){
        return axios.post(`${BASE_URL}register`,data,{
          headers:{
            "Content-Type": "application/json",
            }
        })
          .then(res => res) ;
    }
    forgetPassword(data){
      return axios.post(`${BASE_URL}forgetpassword`,data,{
        headers:{
          "Content-Type": "application/json",
          }
      })
      .then(res => {
            return res.data;
      })   
      .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 2));  
  }
  checkResetPassword(userid, token){
    return axios.get(`${BASE_URL}resetpassword/${userid}/${token}`,{
      headers:{
        "Content-Type": "application/json",
        }
    })
    .then(res => {
          return res.data;  
    })   
    .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 2));  
  }
   
}
