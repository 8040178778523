export const responsiveMode =(type)=> type === "page"?{
    xs: { span: 24 },
    sm: { span: 12 },
    md: { span: 8 },
    lg: { span: 8 },
    xl: { span: 6 },
    xxl: { span: 6 },
}:{
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
    xl: { span: 24 },
    xxl: { span: 24 },
}

export const responsiveModeAdd =(type)=> type === "page"?{
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
    xl: { span: 24 },
    xxl: { span: 24 },
}:{
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
    xl: { span: 24 },
    xxl: { span: 24 },
}
export const responsiveModeAddUser =(type)=> type === "page"?{
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 8 },
    xl: { span: 6 },
    xxl: { span: 4 },
}:{
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 8 },
    xl: { span: 6 },
    xxl: { span: 4 },
}
export const responsiveModeSearch =(type)=> type === "page"?{
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
    xl: { span: 12 },
    xxl: { span: 12 },
}:{
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
    xl: { span: 24 },
    xxl: { span: 24 },
}
export const responsiveModeAuthor =(type)=> type === "page"?{
    xs: { span: 12 },
    sm: { span: 12 },
    md: { span: 6 },
    lg: { span: 6 },
    xl: { span: 3 },
    xxl: { span: 3 },
}:{
    xs: { span: 12 },
    sm: { span: 12 },
    md: { span: 12 },
    lg: { span: 12 },
    xl: { span: 12 },
    xxl: { span: 12 },
}
export const responsiveModeSort =(type)=> type === "page"?{
    xs: { span: 12 },
    sm: { span: 12 },
    md: { span: 6 },
    lg: { span: 6 },
    xl: { span: 3 },
    xxl: { span: 3 },
}:{
    xs: { span: 12 },
    sm: { span: 12 },
    md: { span: 12 },
    lg: { span: 12 },
    xl: { span: 12 },
    xxl: { span: 12 },
}
export const responsiveModePagination =(type)=> type === "page"?{
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 12},
    xl: { span: 6  },
    xxl: { span: 6  },
}:{
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
    xl: { span: 24 },
    xxl: { span: 24 },
}

export const responsiveModeTag =(type)=> type === "page"?{
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 8},
    xl: { span: 4  },
    xxl: { span: 4  },
}:{
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
    xl: { span: 24 },
    xxl: { span: 24 },
}