import React, { useState } from 'react';
import { Modal, Row, Col, Table } from 'antd4';
import { injectIntl } from 'react-intl'
import {EditOutlined} from '@ant-design/icons';
import EditSetting from "./EditSetting";
import { FacilityEntityInsight } from 'services';
import { useEffect } from 'react';

const InsightSV = new FacilityEntityInsight();

function Index({ intl, visible, setVisible }) {
    const [openEdit, setOpenEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const dataSource = [
        {
            key: '1',
            name: 'KOP',
            source: "Device",
            value: 'H > Room',
        },
        {
            key: '2',
            name: 'KSL',
            source: "Manual(10)",
            value: '10',
        },
        {
            key: '2',
            name: 'Pwa',
            source: "Insight",
            value: 'one',
        },
    ];

    const columns = [
        {
            title: intl.formatMessage({ id: "label.parameterName" }),
            dataIndex: 'name',
            key: 'name',
            render:(text, record)=><div>{record.semanticName}&nbsp;|&nbsp;{record.semanticUnitName}</div>  
        },
        {
            title: intl.formatMessage({ id: "label.source" }),
            dataIndex: 'inputType',
            key: 'inputType',
        },
        {
            title: intl.formatMessage({ id: "label.value" }),
            dataIndex: 'value',
            key: 'value',
            render:(text, record)=><div>{record.inputType === "ManualData"?record.staticData : record.inputType === "Property"? (record.propertyName+" | "+record.propertyValue) : (record.facilityDeviceEndNodeName+" | "+(record.specificationAliasName || record.specificationAttributeName))}</div>  
        },
        {
            title: intl.formatMessage({ id: "label.action" }),
            dataIndex: 'key',
            key: 'key',
            render:(text, record)=><EditOutlined onClick={()=> setOpenEdit(record)}/>
        },
    ];
    const getItems = () =>{
        setLoading(true);
        InsightSV.getInsightRequirements(visible)
        .then(res => {
            if(res && res.data){
                setItems(res.data);
            }
            setLoading(false);
        })
        .catch(err => setLoading(false))
    }
    useEffect(()=>{
        getItems();
    },[])
    return (
        <Modal
            title={intl.formatMessage({ id: "label.title.insightSetting" })}
            visible={visible}
            className="ant-modal-cu"
            footer={false}
            onCancel={() => setVisible(false)}
        >
            {openEdit && <EditSetting getItems={getItems} visible={openEdit} setVisible={setOpenEdit}/>}

            <Table loading={loading} scroll={{ x: 'auto'}} dataSource={items} columns={columns} pagination={false} />
        </Modal>
    )
}

export default injectIntl(Index);