import React from 'react';
import { LoadingOutlined, SyncOutlined, LogoutOutlined, LoginOutlined, CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons'


const renderIcon = ({type}) => {
    switch (type) {
        case "Pending":
            return <LoadingOutlined />;
        case "Sending":
            return <LogoutOutlined style={{ color: "gray" }} />;
        case "Sent":
            return <LogoutOutlined/>;
        case "Delivered":
            return <LoginOutlined />;
        case "Processing":
            return <SyncOutlined spin={true} />;
        case "Done":
            return <CheckCircleOutlined style={{ color: "green" }} />;
        case "Failed":
            return <CloseCircleOutlined style={{ color: "red" }} />;
        case "Invalid":
            return <ExclamationCircleOutlined />;
        default:
            return <ExclamationCircleOutlined />;
            break;
    }
}

export default renderIcon;