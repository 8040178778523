import { BASE_URL, HEADERS } from './index';
import axios from 'axios';
import {message} from 'antd';

const api = "user/FacilityTag";
const apiEntity  = "user/FacilityEntityTagAssign"

export default class FacilityTagService {
    getList(body){
        return axios.post(`${BASE_URL}${api}/GetList`,body,HEADERS)
        .then(res => {
            return res.data
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));         
    }   
 
    getAssignList(body){
        return axios.post(`${BASE_URL}${apiEntity}/GetList`,body,HEADERS)
        .then(res => {
            return res.data
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));         
    } 
    assignToEntity(body){
        return axios.put(`${BASE_URL}${apiEntity}`,body,HEADERS)
        .then(res => {
            return res.data
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));         
    } 
    bulkAssignToEntity(body){
        return axios.put(`${BASE_URL}${apiEntity}/BulkAction`,body,HEADERS)
        .then(res => {
            return res.data
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));         
    } 
    unAssignToEntity(id, entityId){
        return axios.delete(`${BASE_URL}${apiEntity}/${id}?entityId=${entityId}`,HEADERS)
        .then(res => {
            return res.data
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));         
    } 
    add(data){
        return axios.post(`${BASE_URL}${api}`, null,{
            HEADERS,
            params:{...data}
        } )
        .then(res => {
            return res.data
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    edit(data){
        return axios.put(`${BASE_URL}${api}`, null,{
            HEADERS,
            params:{...data}
        } )
        .then(res => {
            return res.data
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    delete(id){
        return axios.delete(`${BASE_URL}${api}/${id}`, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
}

