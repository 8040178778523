import React, { useState, useEffect } from 'react';
import './style.less';
import IntlMessages from "util/IntlMessages";
import { Col, Row, Spin } from 'antd4';
import { withRouter } from 'react-router-dom'
import axios from 'axios';
import { BASE_URL } from 'services';
import moment from 'moment';
import { AiOutlineTwitter, AiFillYoutube } from 'react-icons/ai';
import { FaLinkedinIn } from 'react-icons/fa';
import {Link} from 'react-router-dom'

function Index(props) {


    const [item, setItem] = useState(false);
    const [loading, setLoading] = useState(false);


    const getItem = () => {
        const urlParams = new URLSearchParams(props.location.search);
        let userId = urlParams.get('userid');
        let id = urlParams.get('id');
        setLoading(true);
        axios.get(`${BASE_URL}facilityalertlog?id=${id}&userId=${userId}`, {
            headers: {
                'content-type': 'application/json',
            }
        })
            .then(res => {
                if (res && res.data && res.data.data) {
                    setItem(res.data.data)
                }
                setLoading(false);
            })
            .catch(err => {
                console.log(err)
            })

    }

    useEffect(() => {
        getItem();
    }, [])
    return <div className="alertlog">
            <div className="ag-body">
                <Link to="/dashboard">
                    <div className="ag-header">
                        <img src={require('../../assets/images/logo.png')} alt="logo" />
                    </div>
                </Link>
                
                <div className="ag-title">
                    <IntlMessages id="label.alert" />
                    <div className="ag-small-title">
                        <IntlMessages id="text.theFollowingAlertIsTriggered" />
                    </div>
                </div>
                <div className="ag-content">
                    <div className="ag-content-title"><IntlMessages id="label.alertDetails" /></div>
                    <div className="items">
                        <div className="item">
                            <div className="title"><IntlMessages id="label.facilityName" /></div>
                            <div className="value">{item.facilityName}</div>
                        </div>
                        <hr />
                        <div className="item">
                            <div className="title"><IntlMessages id="label.alertName" /></div>
                            <div className="value">{item.name}</div>
                        </div>
                        <hr />
                        <div className="item">
                            <div className="title"><IntlMessages id="label.date" /></div>
                            <div className="value">{item.registerDate && moment(item.registerDate).format('MM/DD/YYYY HH:mm')}</div>
                        </div>
                        <hr />
                        <div className="item">
                            <div className="title"><IntlMessages id="label.logic" /></div>
                            <div className="value">{item.logicDescription}</div>
                        </div>
                        <hr />
                        <div className="item">
                            <div className="title"><IntlMessages id="label.alertNotification" /></div>
                            <div className="value">{item.description}</div>
                        </div>
                        <hr />
                        <div className="item">
                            <div className="title"><IntlMessages id="label.triggerFirstValue" /></div>
                            <div className="value">{item.triggerFirstValue}</div>
                        </div>
                        <hr />
                        <div className="item">
                            <div className="title"><IntlMessages id="label.lastValueFirst" /></div>
                            <div className="value">{item.firstValue}</div>
                        </div>
                        <hr />
                        <div className="item">
                            <div className="title"><IntlMessages id="label.triggerSeccondValue" /></div>
                            <div className="value">{item.triggerSeccondValue}</div>
                        </div>
                        <hr />
                        <div className="item">
                            <div className="title"><IntlMessages id="label.lastValueSecond" /></div>
                            <div className="value">{item.seccondValue}</div>
                        </div>
                        <hr />
                        <div className="item">
                            <div className="title"><IntlMessages id="label.entities" /></div>
                            <div className="value">{item.entities && item.entities.map((el)=>{
            return(<div>{el}</div>)
          })}</div>
                        </div>
                    </div>
                </div>
                <div className="ag-footer">
                    <Row>
                        <Col sm={24} xs={24} lg={18}>
                            <div>
                                <div>©2019-20 dataArrows Inc. All rights reserved.</div>
                                <div>319 Forsythe Cir, Seekonk, MA 02771, USA</div>
                                <div className="ag-link">
                                    <a target="_blank" href="https://www.dataarrows.com">dataArrows Inc.</a>
                                    &nbsp;&nbsp;&nbsp;
                                    <a target="_blank" href="https://www.dataarrows.com/contact-us">Contact us</a>
                                </div>
                            </div>
                        </Col>
                        <Col sm={24} xs={24} lg={6}>
                            <div className="ag-social-media">
                                <a target="_blank" href="https://www.linkedin.com/company/dataarrows/"><FaLinkedinIn /></a>
                                &nbsp;&nbsp;
                                <a target="_blank" href="https://twitter.com/dataarrows"><AiOutlineTwitter /></a>
                                &nbsp;&nbsp;
                                <a target="_blank" href="https://www.youtube.com/channel/UCs4UH5zbaemkL5x55hnixwQ"><AiFillYoutube /></a>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    
}


export default withRouter(Index);