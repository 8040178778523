import { BASE_URL, HEADERS } from './index';
import axios from 'axios';
import {message} from 'antd';

const api = "user/Specification"

export default class SpecificationService {
    getByFacility(id){
        return axios.get(`${BASE_URL}${api}/Get/facilityId?facilityId=${id}`,HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));         
    }
    getByModelNumber(data){
        return axios.post(`${BASE_URL}${api}/GetSpecification`, data, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 2));
    }
    getLables(facilityId, modelNumber){
        return axios.get(`${BASE_URL}${api}/GetLables?facilityId=${facilityId}&modelNumber=${modelNumber}`,HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));         
    }
     getOne(endNodeId, label){
        return axios.get(`${BASE_URL}${api}/GetOne?endNodeId=${endNodeId}&lable=${label}`,HEADERS)
        .then(res => {
            return res.data;        
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));         
    }
    getEndNodeId(endNodeId){
        return axios.get(`${BASE_URL}${api}/GetNodeSpecification?endNodeId=${endNodeId}`,HEADERS)
        .then(res => {
            return res.data;        
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));         
    }
    addUser(data){
        return axios.put(`${BASE_URL}${api}/AddUsers`, data, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    add(data){
        return axios.post(`${BASE_URL}${api}/BulkAddSpecification`, data, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    addSP(data){
        return axios.post(`${BASE_URL}${api}`, data, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    edit(data){
        return axios.put(`${BASE_URL}${api}`, data, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    delete(id){
        return axios.delete(`${BASE_URL}${api}/${id}`, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    getByFacilityId(id){
        return axios.get(`${BASE_URL}${api}/GetFacilitySemantic?facilityId=${id}`, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
    bulkUpdateByMatch(id, data, matcher, matcherType){
        return axios.put(`${BASE_URL}${api}/BulkUpdate?facilityId=${id}&matcher=${matcher}&matcherType=${matcherType}`, data, HEADERS)
        .then(res => {
            return res.data;
        })          
        .catch(err =>message.error(err.response?err.response.statusText:"Network Error", 4));
    }
}

