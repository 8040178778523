import React, { useEffect, useState } from "react";
import IntlMessages from 'util/IntlMessages';
import { PlusOutlined, RiseOutlined, FallOutlined, FilterOutlined, SearchOutlined, DeleteOutlined, BgColorsOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { Button, Row, Col, Input, Checkbox, Spin, Select, Popconfirm, Container } from "antd4";
import { Popover } from 'antd';
import { useEntity } from '../../context';
import Item from './Item';
import { responsiveMode, responsiveModeAuthor, responsiveModePagination, responsiveModeAddUser, responsiveModeAdd, responsiveModeSearch } from '../../responsiveMode';
import Card from './ItemCard';
import axios from 'util/Api';
import { PropertyService } from 'services';
import { SortFunction, SortFunctionOutKey, fuseSearch } from 'constants/Functions';

const { Option } = Select;

const propertyService = new PropertyService();

function Index() {

    //hook
    const { entity } = useEntity();
    //state
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false)
    const [selectRole, setSelectRole] = useState(["Manager", "Occupant"]);
    const [data, setData] = useState([]);
    const [sort, setSort] = useState("isAscDateTrue");
    const [files, setFiles] = useState([]);
    const [items, setItems] = useState([]);
    const [openItem, setOpenItem] = useState(false);
    const [strSearch, setStrSearch] = useState("");

    const onChangeAuther = (e) => {
        setSelectRole(e)

    }
    const changeFilter = () => {
        let newItems = [...data];
        newItems = newItems.filter(el => selectRole.indexOf(el.facility3DFileOriginalName) > -1);
        setDataSearch(newItems, strSearch);
    }

    //dropdown
    //functions
    const setDataSearch = (data, newQuery) => {

        setLoading(true);
        data = fuseSearch(data, ['name', 'facility3DFileOriginalName'], newQuery);
        setItems(data)
        setLoading(false);
        setStrSearch(newQuery);
    }

    const getItems = () => {
        setLoading(true);
        const groupBy = (items, key) => items.reduce(
            (result, item) => ({
                ...result,
                [item[key]]: [
                    ...(result[item[key]] || []),
                    item,
                ],
            }),
            {},
        );
        entity.service.getElement(entity.data.id, 999, 1)
            .then(res => {
                if (res.data) {
                    setData(res.data);
                    setItems(res.data);
                    setFiles(Object.keys(groupBy(res.data, "facility3DFileOriginalName")));
                }
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
            })


    }

    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };
    const deleteItem = () => {
        setLoading(true);

        entity.service.deleteAllElementEntity(entity.data.id)
            .then(res => {
                // if (res.header.metaResponse !== null) {
                //     message[res.header.responseCode === 0 ? "success" : "warning"](props.intl.formatMessage({ id: res.header.metaResponse }))
                // }
                if (res && res.header && res.header.responseCode === 0) {
                    getItems();
                }
                setLoading(false)

            })
            .catch(err => {
                console.log(err)
                setLoading(false)
            })
    }

    //effect

    useEffect(() => {
        if (entity.data && entity.data.code) {
            getItems({
                "facilityEntityCode": entity.data.code,
                "searchTerm": "",
                "isAscDate": true,
                "size": 10,
                "page": 1
            })
        }
    }, [entity]);
    return (
        <div className="attachment-tabs">
            {openItem && <Item
                facilityEntityId={entity.data.id}
                facilityId={entity.data.facilityId}
                visible={openItem}
                setVisible={setOpenItem}
                getItems={() => getItems()} />}
            <Row gutter={[15, 15]}>


                {entity.data && entity.data.isManager && <Col  >
                    <Popconfirm title="Are you sure？" onConfirm={() => deleteItem()} okText={"Yes"}
                        cancelText="No"><div style={{ textAlign: "center", cursor: "pointer", color: "red" }}>

                            <Button

                                type="danger"
                            >
                                &nbsp;<IntlMessages id="label.allUnAssign" />
                            </Button>


                        </div></Popconfirm>



                </Col>}
                <Col
                    {...responsiveModeSearch(entity.type)}
                >
                    <div className="input-search">
                        <Input
                            placeholder="Search in elements"
                            allowClear={true}
                            onChange={(e) => { setDataSearch(data, e.target.value) }}
                            suffix={<SearchOutlined />} />
                    </div>
                </Col>

                <Col {...responsiveMode(entity.type)}>
                    <Popover
                        onVisibleChange={handleOpenChange}
                        visible={open}
                        content={<>
                            <div>
                                <Checkbox.Group
                                    // options={users}
                                    defaultValue={selectRole}
                                    onChange={onChangeAuther}
                                >
                                    <Row gutter={[5, 5]}>
                                        {files.map((file, index) => {
                                            return (
                                                <Col span={24} key={index}>
                                                    <Checkbox style={{width:"100%", display:"flex",
                                                alignItems:"center"}} value={file}>{file}</Checkbox>
                                                </Col>
                                            )
                                        })}


                                    </Row>
                                </Checkbox.Group>
                            </div>
                            <div style={{ display: "flex", justifyContent: "flex-start", columnGap: "10px", marginTop:"10px" }}>
                                <Button type="primary" size="small"
                                    onClick={() => { changeFilter(); handleOpenChange(false) }}
                                ><IntlMessages id="apply" /></Button>
                                <Button size="small"
                                    onClick={() => handleOpenChange(false)}
                                ><IntlMessages id="close" /></Button>
                            </div>

                        </>} placement="bottom" trigger="click">
                        <Button
                            // className="center-btn"
                            icon={<FilterOutlined />}
                            block onClick={() => handleOpenChange(true)}>
                            &nbsp; <IntlMessages id="filterByModel" /></Button>
                    </Popover>


                </Col>
                <Col
                    justifyContent="flex-end"
                    {...responsiveModePagination(entity.type)}
                >
                    <div className="pagination-styles">
                        {items.length} <IntlMessages id="elements" />
                    </div>

                </Col>
            </Row>

            <Spin spinning={loading}>
                <Row gutter={[15, 15]} wrap={false}>
                    {items && items.map((item, index) => (
                        <Col key={index}  {...responsiveMode(entity.type)}>
                            <Card
                                deleteItem={deleteItem}
                                {...item} getItems={() => getItems()} />
                        </Col>
                    ))}
                </Row>
            </Spin>

        </div>
    )
}

export default Index;